import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_2 = {
  key: 0,
  class: "text-muted mt-2 mb-2"
}
const _hoisted_3 = { class: "m-0" }
const _hoisted_4 = { class: "table valign-top table-striped" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "m-0" }
const _hoisted_7 = { class: "table valign-top table-striped" }
const _hoisted_8 = { key: 1 }

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import {
    DataProviderEntitySearchActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/DataProviderEntitySearchActionDescription";
import {
    getEntityActionRequest,
    getSearchModesData, SEARCH_MODE_LAST_SAVED_EVERYWHERE
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/EntityActionHelper";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import {
    DataProviderEntitySearchActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/search/DataProviderEntitySearchActionParamInterface";
import {computed, ModelRef} from "vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import MyFilter from "~/cabinet/vue/interface/filter/MyFilter.vue";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import AddFieldDropdownButton
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/AddFieldDropdownButton.vue";
import DataProviderEntityFieldRow
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/search/DataProviderEntityFieldRow.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {
    DataProviderEntityFieldInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionParamInterface";
import ObjectHelper from "~/ts/library/ObjectHelper";
import DataProviderEntityGlobalVariableRow
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/search/DataProviderEntityGlobalVariableRow.vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderEntitySearchActionBlockForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<DataProviderEntitySearchActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let model: ModelRef<DataProviderEntitySearchActionParamInterface> = _useModel<getActionDescriptionModelType<DataProviderEntitySearchActionDescription>>(__props, "modelValue");

let frontendParams = props.actionDescription.frontendParams;

let {otherSearchTypes} = getSearchModesData(props);


let request = computed(() => getEntityActionRequest(props, {}));

let isSearchTypeConfigured = computed(() => {
    return !!ObjectHelper.arrayFromDictionary(model.value.searchTypes).find(item => item.enabled);
})

function toggleSearchType(id: string, value: boolean) {
    if (!model.value.searchTypes[id]) {
        model.value.searchTypes[id] = {
            enabled: false,
            value: ""
        };
    }
    model.value.searchTypes[id].enabled = value;
}

let contentVariablesForField = computed<ContentVariableInterface[]>(() => {
    let result: ContentVariableInterface[] = [];
    if (request.value?.data) {
        for (let field of request.value.data.fields) {
            if (field.isFilterable) {
                result.push({
                    id: field.id,
                    descr: field.descr
                });
            }
        }
    }
    return result;
});

let onlineChatClientFieldsAvailableForAdd = computed<DataProviderEntityFieldInterface[]>(() => {
    return (frontendParams.onlineChat?.fields ?? [])
        .filter(field => {
            return !model.value.onlineChatClient.fields.list.find(item => item.fieldName == field.name);
        })
        .map(field => {
            return {
                id: field.name,
                descr: field.descr,

            }
        }) as DataProviderEntityFieldInterface[];
});

function addField(field: DataProviderEntityFieldInterface) {
    model.value.onlineChatClient.fields.list.push({
        fieldName: field.id,
        value: ""
    });
}

function addGlobalVariable() {
    model.value.globalVariables.list.push({
        descr: "",
        value: ""
    });
}


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(ReactiveRequestLoader, { request: _unref(request) }, {
    default: _withCtx(() => [
      (_unref(request).data)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: "el-row-root"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_card, null, {
                    default: _withCtx(() => [
                      _createVNode(ElCardHeader, { title: "Настройка поиска" }),
                      _createVNode(ElCardSection, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_switch, {
                            "model-value": _unref(model).searchTypes[_unref(SEARCH_MODE_LAST_SAVED_EVERYWHERE)]?.enabled ?? false,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (toggleSearchType(_unref(SEARCH_MODE_LAST_SAVED_EVERYWHERE), $event))),
                            class: "mr-2"
                          }, null, 8, ["model-value"]),
                          _cache[6] || (_cache[6] = _createTextVNode(" Последний сохранённый с помощью выгрузки из нашей системы "))
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(otherSearchTypes), (searchType) => {
                        return (_openBlock(), _createBlock(ElCardSection, {
                          key: searchType.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_switch, {
                              "model-value": _unref(model).searchTypes[searchType.id]?.enabled ?? false,
                              "onUpdate:modelValue": ($event: any) => (toggleSearchType(searchType.id, $event)),
                              class: "mr-2"
                            }, null, 8, ["model-value", "onUpdate:modelValue"]),
                            _createTextVNode(" " + _toDisplayString(searchType.descr) + " ", 1),
                            (_unref(model).searchTypes[searchType.id]?.enabled)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                  _createVNode(TextInputWithVariables, {
                                    modelValue: _unref(model).searchTypes[searchType.id].value,
                                    "onUpdate:modelValue": ($event: any) => ((_unref(model).searchTypes[searchType.id].value) = $event),
                                    multiline: false,
                                    "content-variables": _unref(props).contentVariables,
                                    placeholder: "Укажите значение для поиска"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "content-variables"])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      (_unref(isSearchTypeConfigured))
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_unref(request).data.filters)
                              ? (_openBlock(), _createBlock(ElCardSection, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(HelpBlock, null, {
                                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                                        _createElementVNode("p", null, " Вы можете добавить фильтры, которым должна соответствовать искомая сущность. ", -1)
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(MyInputList, {
                                      modelValue: _unref(model).searchFilters,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).searchFilters) = $event)),
                                      "remove-last-available": "",
                                      sortable: false,
                                      "new-item-value-getter": () => ({}),
                                      "add-button-text": "Добавить фильтры"
                                    }, {
                                      before: _withCtx(({index}) => [
                                        (index > 0)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "или"))
                                          : _createCommentVNode("", true)
                                      ]),
                                      default: _withCtx(({index}) => [
                                        _createVNode(MyFilter, {
                                          modelValue: _unref(model).searchFilters[index],
                                          "onUpdate:modelValue": ($event: any) => ((_unref(model).searchFilters[index]) = $event),
                                          "filters-data": _unref(request).data.filters,
                                          placeholder: "Укажите фильтры, которым должна соответствовать искомая сущность"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "filters-data"])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  (_unref(isSearchTypeConfigured))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_unref(frontendParams).onlineChat)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_unref(frontendParams).onlineChat.fields.length > 0)
                                ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(ElCardSection, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h5", _hoisted_3, [
                                            _createVNode(_component_el_switch, {
                                              modelValue: _unref(model).onlineChatClient.fields.enabled,
                                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).onlineChatClient.fields.enabled) = $event)),
                                              class: "mr-2"
                                            }, null, 8, ["modelValue"]),
                                            _cache[8] || (_cache[8] = _createTextVNode(" Менять значения полей у клиента "))
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(ElCardSection, null, {
                                        default: _withCtx(() => [
                                          _createVNode(HelpBlock, null, {
                                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                                              _createTextVNode(" Найденная сущность будет автоматически сохранена в информации о клиенте. ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      (_unref(model).onlineChatClient.fields.enabled)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createVNode(ElCardSectionWithTable, null, {
                                              default: _withCtx(() => [
                                                _createElementVNode("table", _hoisted_4, [
                                                  _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
                                                    _createElementVNode("tr", null, [
                                                      _createElementVNode("th", { style: {"width":"13em"} }, "Поле"),
                                                      _createElementVNode("th", { style: {"min-width":"12em"} }, "Значение")
                                                    ])
                                                  ], -1)),
                                                  _createElementVNode("tbody", null, [
                                                    (_unref(model).onlineChatClient.fields.list.length)
                                                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(model).onlineChatClient.fields.list, (item, key) => {
                                                          return (_openBlock(), _createBlock(DataProviderEntityFieldRow, {
                                                            key: item.fieldName,
                                                            field: _unref(frontendParams).onlineChat.fields.find(field => field.name == item.fieldName),
                                                            modelValue: _unref(model).onlineChatClient.fields.list[key],
                                                            "onUpdate:modelValue": ($event: any) => ((_unref(model).onlineChatClient.fields.list[key]) = $event),
                                                            "content-variables": _unref(contentVariablesForField),
                                                            onRemove: ($event: any) => (_unref(model).onlineChatClient.fields.list.splice(key, 1))
                                                          }, null, 8, ["field", "modelValue", "onUpdate:modelValue", "content-variables", "onRemove"]))
                                                        }), 128))
                                                      : (_openBlock(), _createElementBlock("tr", _hoisted_5, _cache[10] || (_cache[10] = [
                                                          _createElementVNode("td", {
                                                            class: "text-center",
                                                            colspan: "2"
                                                          }, [
                                                            _createTextVNode(" Пока что не добавлено ни одного поля. Нажмите на кнопку "),
                                                            _createElementVNode("span", { class: "font-weight-bold" }, "Добавить поле")
                                                          ], -1)
                                                        ])))
                                                  ])
                                                ])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(ElCardSection, null, {
                                              default: _withCtx(() => [
                                                _createVNode(AddFieldDropdownButton, {
                                                  "fields-available-for-add": _unref(onlineChatClientFieldsAvailableForAdd),
                                                  onAdd: _cache[3] || (_cache[3] = ($event: any) => (addField($event)))
                                                }, null, 8, ["fields-available-for-add"])
                                              ]),
                                              _: 1
                                            })
                                          ], 64))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true),
                        (_unref(props).isInScheme)
                          ? (_openBlock(), _createBlock(_component_el_card, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(ElCardSection, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("h5", _hoisted_6, [
                                      _createVNode(_component_el_switch, {
                                        modelValue: _unref(model).globalVariables.enabled,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).globalVariables.enabled) = $event)),
                                        class: "mr-2"
                                      }, null, 8, ["modelValue"]),
                                      _cache[12] || (_cache[12] = _createTextVNode(" Сохранять глобальные переменные "))
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(ElCardSection, null, {
                                  default: _withCtx(() => [
                                    _createVNode(HelpBlock, null, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode(" Сохранять данные из найденной сущности в глобальных переменных ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                (_unref(model).globalVariables.enabled)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(ElCardSectionWithTable, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("table", _hoisted_7, [
                                            _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
                                              _createElementVNode("tr", null, [
                                                _createElementVNode("th", { style: {"width":"50%"} }, "Название переменной"),
                                                _createElementVNode("th", { style: {"width":"50%"} }, "Значение")
                                              ])
                                            ], -1)),
                                            _createElementVNode("tbody", null, [
                                              (_unref(model).globalVariables.list.length)
                                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(model).globalVariables.list, (item, key) => {
                                                    return (_openBlock(), _createBlock(DataProviderEntityGlobalVariableRow, {
                                                      key: key,
                                                      modelValue: _unref(model).globalVariables.list[key],
                                                      "onUpdate:modelValue": ($event: any) => ((_unref(model).globalVariables.list[key]) = $event),
                                                      "content-variables": _unref(contentVariablesForField),
                                                      onRemove: ($event: any) => (_unref(model).globalVariables.list.splice(key, 1))
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "content-variables", "onRemove"]))
                                                  }), 128))
                                                : (_openBlock(), _createElementBlock("tr", _hoisted_8, _cache[14] || (_cache[14] = [
                                                    _createElementVNode("td", {
                                                      class: "text-center",
                                                      colspan: "2"
                                                    }, [
                                                      _createTextVNode(" Пока что не добавлено ни одной глобальной переменной. Нажмите на кнопку "),
                                                      _createElementVNode("span", { class: "font-weight-bold" }, "Добавить переменную")
                                                    ], -1)
                                                  ])))
                                            ])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(ElCardSection, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_button, {
                                            onClick: _cache[5] || (_cache[5] = ($event: any) => (addGlobalVariable()))
                                          }, {
                                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                                              _createTextVNode("Добавить переменную")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})