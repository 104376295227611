import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex-fill pl-2" }

import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import Platform from "~/cabinet/ts/data/integrations/Platform";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import BigIconFlexButton from "~/cabinet/vue/interface/button/BigIconFlexButton.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import {computed, ref} from "vue";
import AdditionalAuthConfigDialog from "~/cabinet/vue/client/integrations/AdditionalAuthConfigDialog.vue";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";


const __default__ = {
    name: "AuthCard"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    auth: {},
    platform: {},
    platformTypeId: {},
    descr: {},
    avatar: {},
    autoSelect: { type: Boolean }
  },
  emits: ["select", "remove", "reload"],
  setup(__props: any, { emit: __emit }) {

const IntegrationAuthDialog = useRawAsyncComponent(() => import(/* webpackChunkName: "integration-auth" */ '~/cabinet/vue/client/integrations/IntegrationAuthDialog.vue'));


let props = __props;

let emits = __emit;

let computedAvatar = computed(() => {
    return props.auth ? props.auth.avatar : props.avatar;
});

let computedDescr = computed(() => {
    return props.auth ? props.auth.descr : props.descr;
});

let additionalAuthConfigDialogRef = ref<{
    visible: boolean
}>();

async function openAdditionalAuthConfigFormDialog() {
    additionalAuthConfigDialogRef.value = {
        visible: true
    };
}

let editAuthDialog = ref<{
    visible: boolean,
    auth: Auth
}>();

function openEditAuthDialog() {
    editAuthDialog.value = {
        visible: true,
        auth: props.auth.clone()
    };
}

if (props.autoSelect) {
    emits("select");
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "mt-4" }, {
    default: _withCtx(() => [
      _createVNode(ElCardHeader, { "button-col-span": 6 }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              (_unref(computedAvatar))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _unref(computedAvatar),
                    class: "avatar"
                  }, null, 8, _hoisted_2))
                : (_openBlock(), _createBlock(FontAwesomeIcon, {
                    key: 1,
                    icon: _ctx.platform.icon,
                    style: _normalizeStyle(((typeof _ctx.platform.icon == 'string') || !_ctx.platform.icon.imgIcon) ? {background:  _ctx.platform.color } : {fontSize: '1.5em', lineHeight: '1em'}),
                    class: "icon"
                  }, null, 8, ["icon", "style"]))
            ]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(computedDescr) ? _unref(computedDescr) : 'Без названия'), 1)
          ])
        ]),
        default: _withCtx(() => [
          (_ctx.auth)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                plain: "",
                type: "danger",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('remove')))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Удалить ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(ElCardSection, null, {
        default: _withCtx(() => [
          _createVNode(BigIconFlexButtonWrapper, null, {
            default: _withCtx(() => [
              _createVNode(BigIconFlexButton, {
                descr: _ctx.auth ? 'Выбрать интеграцию' : 'Создать интеграцию',
                icon: "hand-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('select')))
              }, null, 8, ["descr"]),
              (_ctx.auth)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(BigIconFlexButton, {
                      descr: "Редактировать подключение",
                      icon: "pencil",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (openEditAuthDialog()))
                    }),
                    (_ctx.auth.additionalConfigForm)
                      ? (_openBlock(), _createBlock(BigIconFlexButton, {
                          key: 0,
                          descr: "Дополнительные настройки",
                          icon: "cog",
                          onClick: openAdditionalAuthConfigFormDialog
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_unref(additionalAuthConfigDialogRef) && _unref(additionalAuthConfigDialogRef).visible)
            ? (_openBlock(), _createBlock(AdditionalAuthConfigDialog, {
                key: 0,
                visible: _unref(additionalAuthConfigDialogRef).visible,
                "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_unref(additionalAuthConfigDialogRef).visible) = $event)),
                "auth-id": _unref(props).auth.id
              }, null, 8, ["visible", "auth-id"]))
            : _createCommentVNode("", true),
          (_unref(editAuthDialog) && _unref(editAuthDialog).visible)
            ? (_openBlock(), _createBlock(_unref(IntegrationAuthDialog), {
                key: 1,
                platform: _unref(props).platform,
                platforms: [_unref(props).platform],
                "platform-type-id": _unref(props).platformTypeId,
                visible: _unref(editAuthDialog).visible,
                "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_unref(editAuthDialog).visible) = $event)),
                value: _unref(editAuthDialog).auth,
                onSave: _cache[5] || (_cache[5] = ($event: any) => (_unref(emits)('reload')))
              }, null, 8, ["platform", "platforms", "platform-type-id", "visible", "value"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})