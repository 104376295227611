import {App, defineComponent} from "vue";
import "./InitPrismJs";

import {
    ElAlert,
    ElAutocomplete,
    ElBacktop,
    ElBadge,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElButton,
    ElButtonGroup,
    ElCalendar,
    ElCard,
    ElCarousel,
    ElCarouselItem,
    ElCascader,
    ElCascaderPanel,
    ElCheckbox,
    ElCheckboxGroup,
    ElCol,
    ElCollapse,
    ElCollapseItem,
    ElCollapseTransition,
    ElColorPicker,
    ElDatePicker,
    ElDialog,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElLoading,
    ElOption,
    ElOptionGroup,
    ElPagination,
    ElPopover,
    ElProgress,
    ElRadio,
    ElRadioButton,
    ElRadioGroup,
    ElRow,
    ElScrollbar,
    ElSelect,
    ElSlider,
    ElStep,
    ElSteps,
    ElSwitch,
    ElTable,
    ElTableColumn,
    ElTabPane,
    ElTabs,
    ElTag,
    ElTimeline,
    ElTimelineItem,
    ElTimePicker,
    ElTooltip,
    ElTree,
    ElUpload,
} from "element-plus";


//import lang from 'build-locale';
//import locale from 'element-ui/lib/locale/index.js'
import LcabWindowHelper from "~/ts/library/LcabWindowHelper";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import maxSize from 'popper-max-size-modifier';
import 'dayjs/locale/ru';
import dayjs from "dayjs";

dayjs.locale('ru')

const applyMaxSize = {
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn(p: any) {
        let state = p.state;

        /*
        if (state.placement !== state.options.placement) {
            p.instance.setOptions({
                placement: state.placement
            })
        }*/
        // The `maxSize` modifier provides this data
        const {width, height} = state.modifiersData.maxSize;

        state.styles.popper = {
            ...state.styles.popper,
            '--my-popover-max-width': `${width}px`,
            '--my-popover-max-height': `${height}px`
        };
    }
};

const applyMaxSizeForSelect = {
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn(p: any) {
        let state = p.state;

        let placement = "bottom";
        if (state.rects.reference.y > window.innerHeight / 2) {
            placement = 'top-start';
        }
        if (placement != state.options.placement) {
            p.instance.setOptions({
                placement
            });
        }
        /*
        if (state.placement !== state.options.placement) {
            p.instance.setOptions({
                placement: state.placement
            })
        }*/
        // The `maxSize` modifier provides this data
        const {width, height} = state.modifiersData.maxSize;

        state.styles.popper = {
            ...state.styles.popper,
            '--my-popover-max-width': `${width}px`,
            '--my-popover-max-height': `${height}px`
        };
    }
};

let defaultPopperOptions = {
    //placement: "auto",
    modifiers: [
        maxSize,
        applyMaxSize
    ]
};

let selectDefaultPopperOptions = {
    modifiers: [
        maxSize,
        applyMaxSizeForSelect
    ]
}


//import Verte from '~/core-ui/vue/ui/verte/components/Verte.vue';

// register component globally


// configure language
//locale.use(lang);


export const LoadingService = ElLoading.service;

let MyForm = defineComponent({
    extends: ElForm,
    props: {
        labelPosition: {
            type: String,
            default: () => {
                return LcabWindowHelper.isMobile() ? "top" : "left";
            }
        }
    },
    setup(props, ctx) {
        return ElForm.setup(props, ctx);
    },
});


/*
let MyColorPicker = defineComponent({
    extends: ElColorPicker,
    name: "MyColorPicker",
    created(): void {
        this.$on("active-change", (color: string) => this.$emit("update:modelValue", color));
    },
    setup(props, ctx) {
        return ElColorPicker.setup(props, ctx);
    },
});*/


let ExtendedDialog = defineComponent({
    extends: ElDialog,
    props: {
        closeOnClickModal: {
            type: Boolean,
            default: false
        },
        closeOnPressEscape: {
            type: Boolean,
            default: false
        },
        modalAppendToBody: {
            type: Boolean,
            default: true
        },
        appendToBody: {
            type: Boolean,
            default: true
        }
    },
    setup(props, ctx) {
        return ElDialog.setup(props, ctx);
    },
} as any);

let extendedPopover = defineComponent({
    extends: ElPopover,
    props: {
        width: {
            default: "auto"
        },
        trigger: {
            default: "click"
        },
        popperOptions: {
            default: defaultPopperOptions
        }
    },
    setup(props, ctx) {
        return ElPopover.setup(props, ctx);
    }
});

let MyUpload = defineComponent({
    extends: ElUpload,
    props: {
        headers: {
            type: Object,
            default: () => ({
                'X-Csrf-Token': CsrfToken.get()
            })
        }
    },
    setup(props, ctx) {
        return ElUpload.setup(props, ctx);
    },
    /*
    methods: {
        handleClick: () => {
            console.log("FOOOOO");
        }
    },
    mounted() {
        if (Device.ios()) {
            let $el = this.$el;
            $el.addEventListener("click", function (e: MouseEvent) {
                if (!(e.target as HTMLElement).matches("input[type=file]")) {
                    let input: HTMLElement = $el.querySelector("input[type=file]");
                    if (input) {
                        input.focus();
                        input.click();
                    }
                }
            });
        }
    }*/
});

/*
let MyInput = defineComponent({
    extends: ElInput,
    methods: {
        resizeTextarea() {
            if (this.autosize) {
                if (this.autosize.maxRows) {
                    if (!this.autosizeRunOnce) {
                        this.constructor.super.options.methods.resizeTextarea.call(this);
                        this.autosizeRunOnce = true;
                    }
                    if (this.$refs.textarea) {
                        this.$nextTick(() => {
                            let textarea = this.$refs.textarea;
                            if (textarea) {
                                let parentElement = textarea.parentElement;
                                if (parentElement) {
                                    parentElement.style.height = textarea.style.height - 2;
                                }
                                textarea.style.height = '0px';
                                textarea.style.height = `${textarea.scrollHeight + 3}px`;
                                textarea.style.maxHeight = `${this.autosize.maxRows * 1.7}em`;
                                if (parentElement) {
                                    parentElement.style.height = '';
                                }
                            }
                        })

                    }
                }
            }
        },
    }
})*/


export default class InitElemeUI {
    public static make(app: App) {
        app
            //.component(Verte.name, Verte)
            .component("el-cascader", ElCascader)
            .component("el-cascader-panel", ElCascaderPanel)
            .component("el-button", defineComponent({
                extends: ElButton,
                props: {
                    type: {
                        type: String
                    }
                },
                setup: (props, ctx) => {
                    return ElButton.setup(props, ctx);
                }
            }))
            .component("el-button-group", ElButtonGroup)
            .component("el-input", ElInput)
            .component("el-input-number", ElInputNumber)
            .component('el-autocomplete', ElAutocomplete)
            .component('el-scrollbar', ElScrollbar)
            .component("el-form", MyForm)
            .component("el-form-item", defineComponent({
                extends: ElFormItem,
                props: {
                    for: {
                        type: String,
                        default: 'disabled-for'
                    }
                },
                setup: (props, ctx) => {
                    return ElFormItem.setup(props, ctx);
                }

            }))
            .component("el-row", ElRow)
            .component("el-col", ElCol)
            .component("el-card", ElCard)
            .component("el-tree", ElTree)
            .component("el-select", defineComponent({
                extends: ElSelect,
                props: {

                    fallbackPlacements: {
                        default: ['top-start']
                    },
                    popperOptions: {
                        default: selectDefaultPopperOptions
                    },
                    fitInputWidth: {
                        default: true
                    },
                    reserveKeyword: {
                        default: false
                    }
                },
                setup: (props, ctx) => {
                    return ElSelect.setup(props, ctx);
                }
            }))
            .component("el-option", defineComponent({
                extends: ElOption,
                props: {
                    value: {}
                },
                setup: (props, ctx) => {
                    return ElOption.setup(props, ctx);
                }
            }))
            .component("el-option-group", ElOptionGroup)
            .component("el-tag", defineComponent({
                extends: ElTag,
                props: {
                    type: {
                        type: String
                    }
                },
                setup: (props, ctx) => {
                    return ElTag.setup(props, ctx);
                }
            }))
            .component("el-dropdown", defineComponent({
                extends: ElDropdown,
                props: {
                    popperOptions: {
                        default: defaultPopperOptions
                    }
                },
                setup: (props, ctx) => {
                    return ElDropdown.setup(props, ctx);
                }
            }))
            .component("el-dropdown-menu", ElDropdownMenu)
            .component("el-dropdown-item", ElDropdownItem)
            .component("el-table", ElTable)
            .component("el-table-column", ElTableColumn)
            .component("el-collapse", ElCollapse)
            .component("el-collapse-item", ElCollapseItem)
            .component("el-tabs", ElTabs)
            .component("el-tab-pane", ElTabPane)
            .component("el-date-picker", ElDatePicker)
            .component("el-calendar", ElCalendar)
            .component("el-pagination", ElPagination)
            .component("el-badge", defineComponent({
                extends: ElBadge,
                props: {
                    showZero: {
                        default: false
                    }
                },
                setup: (props, ctx) => {
                    return ElBadge.setup(props, ctx);
                }
            }))
            .component("el-dialog", ExtendedDialog)
            .component("el-switch", ElSwitch)
            .component("el-upload", MyUpload)
            .component("el-tooltip", defineComponent({
                extends: ElTooltip,
                props: {
                    popperOptions: {
                        default: defaultPopperOptions
                    }
                },
                setup: (props, ctx) => {
                    return ElTooltip.setup(props, ctx);
                }
            }))
            .component("el-alert", ElAlert)
            .component("el-slider", ElSlider)
            .component("el-popover", extendedPopover)
            .component("el-radio", ElRadio)
            .component("el-radio-button", ElRadioButton)
            .component("el-radio-group", ElRadioGroup)
            .component("el-checkbox", ElCheckbox)
            .component("el-checkbox-group", ElCheckboxGroup)
            .component("el-time-picker", ElTimePicker)
            .component("el-timeline", ElTimeline)
            .component("el-timeline-item", ElTimelineItem)
            .component("el-color-picker", ElColorPicker)
            .component("el-progress", ElProgress)
            .component("el-backtop", ElBacktop)
            .component("el-steps", ElSteps)
            .component("el-step", ElStep)
            .component("el-breadcrumb", ElBreadcrumb)
            .component("el-breadcrumb-item", ElBreadcrumbItem)
            .component("el-carousel", ElCarousel)
            .component("el-carousel-item", ElCarouselItem)


            .component("my-color-picker", ElColorPicker)
            .component("el-collapse-transition", ElCollapseTransition);
        app.use(ElLoading);
    }

}


document.addEventListener("submit", e => {
    let target = e.target as HTMLElement;
    if (target.classList && target.classList.contains("el-form")) {
        e.preventDefault();
    }
});