import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {computed} from 'vue';
import {ICON_IMG, ICON_LIGHT, ICON_REGULAR, ICON_SOLID, IconStyle} from "~/chat/ts/data/ISiteParams";
import {icon} from "~/ts/vuePlugins/IconPlugin";
import {Icon} from "~/cabinet/vue/interface/icon/IconInterface";


const __default__ = {
    name: "FontAwesomeIcon"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    icon: {},
    color: {},
    spin: { type: Boolean },
    light: { type: Boolean },
    bold: { type: Boolean },
    regular: { type: Boolean },
    square: { type: Boolean },
    iconStyle: {},
    setHeightForImg: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

let imgIcon = computed(() => {
    if (typeof props.icon == "object") {
        return props.icon.imgIcon;
    } else if (props.iconStyle == "img") {
        return props.icon;
    }
    return null
});

let style = computed(() => {
    if (imgIcon.value) {
        return {
            width: '1em !important',
            height: props.setHeightForImg ? '1em !important' : undefined,
            backgroundImage: `url('${imgIcon.value}') !important`,
            backgroundRepeat: 'no-repeat !important',
            backgroundSize: '1em !important',
            backgroundPosition: 'center !important',
            verticalAlign: 'middle'
        }
    }
    return {
        color: typeof props.icon == "object" ? props.icon.color : undefined
    };
})

let className = computed(() => {
    if (typeof props.icon == "string" && props.icon.includes(" ")) {
        return props.icon;
    }
    let className = "far";
    if (props.light || props.iconStyle == ICON_LIGHT) {
        className = "fal";
    } else if (props.bold || props.iconStyle == ICON_SOLID) {
        className = "fas";
    } else if (props.regular || props.iconStyle == ICON_REGULAR) {
        className = "far";
    }
    let iconName = typeof props.icon == "string" ? props.icon : props.icon.fontAwesomeIcon;
    let iconClassName = icon(props.iconStyle == ICON_IMG ? 'custom-img-icon' : iconName, className);

    let result = [iconClassName, props.spin ? "fa-spin" : ""];
    if (props.color) {
        result.push("text-" + props.color);
    }
    if (imgIcon.value) {
        result.push("img-icon");
    }

    if (props.square) {
        result.push("square");
    }
    return result;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(_unref(className)),
    style: _normalizeStyle(_unref(style))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}
}

})