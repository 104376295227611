import AbstractEntity, {AbstractEntityFields, NullableField} from "~/ts/library/AbstractEntity";
import Platform from "~/cabinet/ts/data/integrations/Platform";
import Dictionary from "~/ts/library/Dictionary";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";

export default class Auth extends AbstractEntity {
    id: string;
    platformId: string;
    descr: string;
    primaryId: string;
    groups?: Child[];

    parentAuth?: {
        id: string,
        platform: Platform,
        descr: string
    };

    childId?: string;
    childs?: ChildPlatform[];
    avatar?: string;
    link?: string;
    params: Dictionary<any>;

    additionalConfigForm?: IForm;
    additionalConfigModel?: any;
    onlineChatExportToDataProviderEventDescriptionId?: string;
    onlineChatManualExportToDataProviderEventDescriptionId?: string;
    onlineChatSearchInDataProviderEventDescriptionId?: string;
    onlineChatExportMessageToCrmEventDescriptionId?: string;


    protected getFields(): AbstractEntityFields {
        return {
            groups: Child,
            childs: ChildPlatform
        }
    }

    getDescrWithPlatform(platforms: Platform[]) {
        return `${this.getPlatformDescr(platforms)}: ${this.descr}`;
    }


    public getPlatform(platforms: Platform[]) {
        return platforms.find(platform => platform.id == this.platformId);
    }

    public getPlatformDescr(platforms: Platform[]) {
        let platform = this.getPlatform(platforms);
        return platform ? platform.descr : this.platformId;
    }
}

class ChildPlatform extends AbstractEntity {
    platform: Platform;
    selectDescr: string;
    items: Child[];
    authNotExistedInChilds: Auth[];

    protected getFields(): AbstractEntityFields {
        return {
            platform: Platform,
            items: Child,
            authNotExistedInChilds: Auth
        };
    }
}


export class Child extends AbstractEntity {
    id?: number;
    descr?: string;
    avatar?: string;
    link?: string;
    authId?: string;
    auth?: Auth;
    isAutoSelect: boolean;

    protected getFields(): AbstractEntityFields {
        return {
            auth: new NullableField(Auth)
        }
    }
}