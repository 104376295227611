import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hidden-xs-only" }
const _hoisted_2 = {
  class: "lh-67 text-truncate",
  style: {"max-width":"18em"}
}
const _hoisted_3 = { class: "ocode" }
const _hoisted_4 = {
  key: 0,
  class: "org-descr lh-33"
}
const _hoisted_5 = { style: {"font-weight":"500"} }
const _hoisted_6 = {
  key: 0,
  class: "text-red ml-2"
}
const _hoisted_7 = { style: {"font-weight":"500"} }
const _hoisted_8 = { class: "hidden-sm-and-up" }
const _hoisted_9 = { style: {"font-weight":"500"} }

import {computed} from 'vue';
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import HeaderDropdownButton from "~/cabinet/vue/layout/lcab/Header/HeaderDropdownButton.vue";
import Currency from "~/cabinet/vue/interface/Currency.vue";
import LcabWindowHelper from "~/ts/library/LcabWindowHelper";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import MyDropdownLinkItem from "~/cabinet/vue/interface/dropdown/MyDropdownLinkItem.vue";
import ClientRoutesBuilder, {
    AUTH_LOG_REPORT,
    CLIENT_TARIFS, CONFIG_CHANGE_PASSWORD_ROUTE_NAME, CONFIG_OAUTH_ROUTE_NAME,
    CONFIG_ROUTE_NAME,
    DOC_ROUTE,
    FIN_REPORT_BALANCE_MOVE,
    FIN_REPORT_PAYMENTS,
    FIN_REPORT_USLUGI_TASKS
} from "~/cabinet/ts/routes/ClientRoutesBuilder";
import {ClientPayRoutesBuilder} from "~/cabinet/ts/routes/ClientPayRoutesBuilder";
import {RouteLocationNamedRaw} from "vue-router";


const __default__ = {
    name: "AccountButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let ocode = computed(() => {
    return AccountStore.organization.value.ocode;
});

let paymentsLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: FIN_REPORT_PAYMENTS
    };
});

let documentsLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: DOC_ROUTE
    };
});

let authLogLocation = computed<RouteLocationNamedRaw>(() => ({
    name: AUTH_LOG_REPORT
}))

let balanceMoveLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: FIN_REPORT_BALANCE_MOVE
    };
});

let uslugiLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: FIN_REPORT_USLUGI_TASKS
    }
});

let organizationDescr = computed(() => {
    return AccountStore.organization.value.descr;
});

let userAccess = AccountStore.access;

let isCanTarifInfo = computed(() => {
    return userAccess.value.checkAccessForTarifInfo();
});

let isCanLcabDocuments = computed(() => {
    return userAccess.value.checkAccessForLcabDocuments();
});

let isCanLcabNews = computed(() => {
    return userAccess.value.checkAccessForViewNews();
});

let isNewDoc = AccountStore.isNewDoc;

let isCanChangePassword = computed(() => {
    return userAccess.value.checkAccessForChangePassword();
});

let isCanConfig = computed(() => {
    return userAccess.value.isAdmin;
});

let showCaret = computed(() => {
    return !LcabWindowHelper.isMobile();
});

let balance = AccountStore.balance;

let credit = AccountStore.credit;

let tarifLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: CLIENT_TARIFS
    }
});

let payLocation = computed<RouteLocationNamedRaw>(() => {
    return ClientPayRoutesBuilder.getPayLocation();
});

let configLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: CONFIG_ROUTE_NAME
    }
});

let changePasswordLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: CONFIG_CHANGE_PASSWORD_ROUTE_NAME
    }
});

let oauthLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: CONFIG_OAUTH_ROUTE_NAME
    }
});


let blogLocation = computed(() => ClientRoutesBuilder.getBlogsLocation());


let currency = AccountStore.organizationCurrency;

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!

  return (_openBlock(), _createBlock(HeaderDropdownButton, { "show-caret": _unref(showCaret) }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_item, {
        disabled: "",
        class: "hidden-sm-and-up"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Номер лицевого счёта: " + _toDisplayString(_unref(ocode)), 1)
        ]),
        _: 1
      }),
      (_unref(isCanTarifInfo))
        ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
            key: 0,
            disabled: "",
            class: "hidden-sm-and-up"
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createTextVNode(" Баланс: ")),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(balance)), 1),
              _createVNode(Currency, {
                "currency-id": _unref(currency).id
              }, null, 8, ["currency-id"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(isCanConfig))
        ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
            key: 1,
            icon: "history",
            to: _unref(authLogLocation)
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" История авторизаций ")
            ])),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (_unref(isCanTarifInfo))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_unref(isNewDoc))
              ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 0,
                  icon: "ruble-sign",
                  to: _unref(payLocation)
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Оплатить ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 1,
                  icon: "ruble-sign",
                  href: "/pay"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Оплатить ")
                  ])),
                  _: 1
                })),
            _createVNode(MyDropdownLinkItem, {
              icon: "usd-circle",
              to: _unref(tarifLocation)
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Тарифы ")
              ])),
              _: 1
            }, 8, ["to"]),
            (_unref(isNewDoc))
              ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 2,
                  icon: "list",
                  to: _unref(paymentsLocation)
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Ваши оплаты ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 3,
                  icon: "list",
                  href: "/pay/history"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Ваши оплаты ")
                  ])),
                  _: 1
                })),
            (_unref(isNewDoc))
              ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 4,
                  icon: "exchange-alt",
                  to: _unref(balanceMoveLocation)
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Движение денежных средств ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 5,
                  icon: "exchange-alt",
                  href: "/pay/paymentMove"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Движение денежных средств ")
                  ])),
                  _: 1
                })),
            (_unref(isNewDoc))
              ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 6,
                  icon: "shopping-cart",
                  to: _unref(uslugiLocation)
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Оказанные услуги ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 7,
                  icon: "shopping-cart",
                  href: "/reports/uslugi"
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode(" Оказанные услуги ")
                  ])),
                  _: 1
                })),
            (_unref(isCanLcabDocuments))
              ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 8,
                  class: "hidden-sm-and-up",
                  icon: "file",
                  to: _unref(documentsLocation)
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Документы ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true),
            (_unref(isCanLcabNews))
              ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
                  key: 9,
                  class: "hidden-sm-and-up",
                  icon: "newspaper",
                  to: _unref(blogLocation)
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode(" Блог ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (_unref(isCanConfig))
        ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
            key: 3,
            icon: "cog",
            to: _unref(configLocation)
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode(" Настройки аккаунта ")
            ])),
            _: 1
          }, 8, ["to"]))
        : (_unref(isCanChangePassword))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              _createVNode(MyDropdownLinkItem, {
                icon: "key",
                to: _unref(changePasswordLocation)
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode(" Сменить пароль ")
                ])),
                _: 1
              }, 8, ["to"]),
              (_unref(AccountStore).dealerCabinetConfig.value.oAuthProviders.length > 0)
                ? (_openBlock(), _createBlock(MyDropdownLinkItem, {
                    key: 0,
                    icon: "key",
                    to: _unref(oauthLocation)
                  }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode(" OAuth ")
                    ])),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
      _createVNode(MyDropdownLinkItem, {
        color: "danger",
        icon: "sign-out-alt",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(AccountStore).quit()))
      }, {
        default: _withCtx(() => _cache[20] || (_cache[20] = [
          _createTextVNode(" Выход ")
        ])),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(ocode)), 1),
          _createTextVNode(", " + _toDisplayString(_unref(organizationDescr)), 1)
        ]),
        (_unref(isCanTarifInfo))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createTextVNode(" Баланс: ")),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(balance)), 1),
              _createVNode(Currency, {
                "currency-id": _unref(currency).id
              }, null, 8, ["currency-id"]),
              (_unref(credit))
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _cache[1] || (_cache[1] = _createTextVNode(" Долг: ")),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(credit)), 1),
                    _createVNode(Currency, {
                      "currency-id": _unref(currency).id
                    }, null, 8, ["currency-id"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "clearfix" }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(FontAwesomeIcon, {
          class: "header-button-icon",
          icon: "user-circle",
          bold: ""
        })
      ])
    ]),
    _: 1
  }, 8, ["show-caret"]))
}
}

})