import {
    DataProviderEntitySaveActionLinkInterface,
    DataProviderEntitySaveActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionParamInterface";
import {Component} from "vue";
import DataProviderEntitySaveActionBlockForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionBlockForm.vue";
import Random from "~/ts/library/Random";
import {
    DataProviderEntitySaveActionDescriptionFrontendParamsInterface,
    DataProviderLinkEntityTypeInterface
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/Interface";
import AbstractDataProviderEntityActionDescription
    from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/AbstractDataProviderEntityActionDescription";


export class DataProviderEntitySaveActionDescription extends AbstractDataProviderEntityActionDescription<DataProviderEntitySaveActionParamInterface, DataProviderEntitySaveActionDescriptionFrontendParamsInterface> {
    get dataProviderActionFormComponent(): Component {
        return DataProviderEntitySaveActionBlockForm;
    }

    /*
    get dataProviderActionPreviewComponent(): Component {
        return DataProviderEntitySaveActionBlockPreview;
    }
    */

    getDefaultModelValues(linkType: DataProviderLinkEntityTypeInterface = null): DataProviderEntitySaveActionParamInterface {
        let searchModes = linkType ? linkType.searchModes : this.frontendParams.searchModes;
        return {
            ...super.getAbstractDefaultModelValues(),
            foundActionMode: this.frontendParams.actionModes.found[0].id,
            notFoundActionMode: this.frontendParams.actionModes.notFound[0].id,
            isAlwaysCreate: false,
            searchModes: searchModes
                .filter(item => item.isDefault)
                .map(item => item.id),
            fields: [],
            updateFilters: [],
            additionalConfigFormValues: {},
            links: this.frontendParams.linkEntityTypes
                .filter(type => type.isLinkedByDefault)
                .map(type => {
                    let result: DataProviderEntitySaveActionLinkInterface = {
                        id: Random.uid(),
                        entityTypeId: type.id,
                        value: undefined,
                        additionalConfigFormValues: {}
                    };
                    return result;
                })
        };
    }
}
