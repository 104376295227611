import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "pr-2" }
const _hoisted_3 = { class: "flex-fill" }

import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    DataProviderEntitySearchOnlineChatClientFieldInterface
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/Interface";
import {
    OnlineChatClientListItemInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/search/DataProviderEntitySearchActionParamInterface";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderEntityFieldRow"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    field: {},
    contentVariables: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["remove"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {
let props = __props;

let _modelValue = _useModel<OnlineChatClientListItemInterface>(__props, "modelValue");
let model = useObjectModel<OnlineChatClientListItemInterface>(_modelValue, () => ({
    fieldName: "",
    value: ""
}));

let emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(MyIconButton, {
            delete: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('remove')))
          })
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.field?.descr ?? _unref(model).fieldName), 1)
      ])
    ]),
    _createElementVNode("td", null, [
      _createVNode(TextInputWithVariables, {
        modelValue: _unref(model).value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).value) = $event)),
        "content-variables": _unref(props).contentVariables,
        "min-height": 1
      }, null, 8, ["modelValue", "content-variables"])
    ])
  ]))
}
}

})