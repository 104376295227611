import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mt-1" }
const _hoisted_2 = { class: "lh-1 mb-1" }
const _hoisted_3 = { class: "text-muted lh-1" }

import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {
    OnlineChatMessageToOperatorActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import BigRadioFlexButton from "~/cabinet/vue/interface/button/BigRadioFlexButton.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import OnlineChatMessageFormPinParams
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/OnlineChatMessageFormPinParams.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "OnlineChatMessageToOperatorForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<OnlineChatMessageToOperatorActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let _modelValue = _useModel<getActionDescriptionModelType<OnlineChatMessageToOperatorActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    text: null,
    messageTypeId: props.actionDescription.frontendParams.messageTypes[0].id,
    operators: [],
    operatorsCount: props.actionDescription.frontendParams.operatorsCount[0].value as string,
    isUseStandartContent: props.actionDescription.frontendParams.isStandartContentAvailable && _modelValue.value == null,
    pinMessage: false,
    unpinOtherMessages: false,
    pinMessageForClient: false,
    unpinOtherMessagesForClient: false
}))


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-position": "left",
    "label-width": "200px",
    model: _unref(model)
  }, {
    default: _withCtx(() => [
      (_unref(props).actionDescription.frontendParams.isStandartContentAvailable)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            "label-width": "0"
          }, {
            default: _withCtx(() => [
              _createVNode(BigIconFlexButtonWrapper, null, {
                default: _withCtx(() => [
                  _createVNode(BigRadioFlexButton, {
                    label: true,
                    descr: "Стандартный текст",
                    "extra-descr": "Будет отправлен стандартный текст, подготовленный нами",
                    modelValue: _unref(model).isUseStandartContent,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).isUseStandartContent) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(BigRadioFlexButton, {
                    label: false,
                    descr: "Свой текст",
                    "extra-descr": "Задайте текст сообщения самостоятельно",
                    modelValue: _unref(model).isUseStandartContent,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).isUseStandartContent) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_form_item, {
        label: "Операторы",
        prop: "operators",
        rules: _unref(requiredRule)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_el_select, {
              modelValue: _unref(model).operators,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).operators) = $event)),
              multiple: "",
              class: "w-100",
              placeholder: "Выберите операторов"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(props).actionDescription.frontendParams.operators, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    value: item.value,
                    label: item.descr
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_select, {
              modelValue: _unref(model).operatorsCount,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).operatorsCount) = $event)),
              class: "w-100"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(props).actionDescription.frontendParams.operatorsCount, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    value: item.value,
                    label: item.descr
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["rules"]),
      _createVNode(_component_el_form_item, { label: "Тип сообщения" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _unref(model).messageTypeId,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).messageTypeId) = $event)),
            placeholder: "Реальное сообщение",
            class: "w-100"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(props).actionDescription.frontendParams.messageTypes, (messageType) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: messageType.descr,
                  value: messageType.id,
                  label: messageType.descr,
                  class: "h-auto pt-3 pb-3"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(messageType.descr), 1),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(messageType.extraDescr), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      (!_unref(model).isUseStandartContent)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            rules: _unref(requiredRule),
            prop: "text",
            label: "Текст сообщения"
          }, {
            default: _withCtx(() => [
              _createVNode(TextInputWithVariables, {
                placeholder: "Введите текст сообщения",
                modelValue: _unref(model).text,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).text) = $event)),
                "content-variables": _ctx.contentVariables
              }, null, 8, ["modelValue", "content-variables"])
            ]),
            _: 1
          }, 8, ["rules"]))
        : _createCommentVNode("", true),
      _createVNode(OnlineChatMessageFormPinParams, { value: _unref(model) }, null, 8, ["value"])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})