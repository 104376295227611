import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import AbstractEntity from "~/ts/library/AbstractEntity";
import Dictionary from "~/ts/library/Dictionary";

export default class ActionOutput extends AbstractEntity {
    public id: string;
    public descr: string;
    public extraDescr?: string;
    public unusedExtraDescr?: string;
    public filters?: IFiltersData;
    public color: string;
    public customDisplayParams?: Dictionary<any>;
}

