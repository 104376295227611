import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    TargetActionDescription,
} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "TargetBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<TargetActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<TargetActionDescription>>(__props, "modelValue");
let model = useObjectModel(
    _modelValue,
    () => ({
        outputNameForParentCascadeMessage: null
    })
);

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "Название выхода для родительского сценария" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _unref(model).outputNameForParentCascadeMessage,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).outputNameForParentCascadeMessage) = $event)),
          placeholder: "Не обязательно"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(HelpBlock, null, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("p", null, "Это название стоит задавать, если данный сценарий используется в каком-то другом сценарии.", -1),
        _createElementVNode("p", null, " Если вы укажете название, то в родительском сценарии можно будет разделить действия, которые должны выполняться в зависимости от того, как завершится этот вложенный сценарий. ", -1)
      ])),
      _: 1
    })
  ]))
}
}

})