import {ApiRequestConfig} from "~/core-ui/ts/request/AbstractApiRequest";
import AbstractOpenApiRequest from "~/ts/library/openApi/AbstractOpenApiRequest";

export default class OperatorApiRequestFactory {
    private static requestClass: typeof AbstractOpenApiRequest<any>;

    public static setClass(requestClass: typeof AbstractOpenApiRequest<any>) {
        this.requestClass = requestClass;
    }

    public static create<TData>(options: ApiRequestConfig): AbstractOpenApiRequest<TData> {
        return new this.requestClass(options);
    }
}