import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, isRef as _isRef } from "vue"

const _hoisted_1 = { id: "authDialog" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "mt-1 text-center"
}
const _hoisted_4 = { class: "d-flex w-100" }
const _hoisted_5 = { class: "flex-fill" }
const _hoisted_6 = { class: "mt-2" }

import {computed, getCurrentInstance, onMounted, ref, watch} from 'vue';
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import {FormInstance} from "element-plus";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import CabinetHtmlify from "~/cabinet/vue/interface/CabinetHtmlify.vue";
import RemindDialog from "~/cabinet/vue/auth/RemindDialog.vue";
import IpBlockDisableDialog from "~/cabinet/vue/auth/IpBlockDisableDialog.vue";
import RegFormDialog from "~/cabinet/vue/auth/RegFormDialog.vue";
import {getInitialUrl} from "~/cabinet/ts/InitialUrl";
import CabinetCaptchaElement from "~/cabinet/vue/interface/form/elements/CabinetCaptchaElement.vue";
import {SIMPLE_CABINET_CAPTCHA_FIELD} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {CaptchaExposeInterface} from "~/cabinet/vue/interface/form/elements/CaptchaExposeInterface";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import OAuthButton from "~/core-ui/vue/ui/oauth/OAuthButton.vue";
import {OAuthResultInterface} from "~/core-ui/vue/ui/oauth/Interface";
import {mobileFullWidthButtonClass} from "~/core-ui/ts/CoreUiHelpersMixin";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "AuthForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let regFormDisplayed = ref(false);
let dualAuth = ref(false);
let dualAuthEmail = ref<string>();
let activationDisplayed = ref(false);
let remindDialogDisplayed = ref(false);
let regInitialEmail = ref<string>();

let captchaRef = ref<CaptchaExposeInterface>();

onMounted(() => {
    let url = getInitialUrl();
    if (url) {
        let initialUrl = new URL(url);
        let hash = initialUrl.hash;
        regFormDisplayed.value = initialUrl.hash.includes("regTab");
        let query = hash.split("?");
        if (query[1]) {
            let parseHashQuery = new URL(`http://localhost/?` + query[1]);
            let email = parseHashQuery.searchParams.get("email");
            if (email) {
                regInitialEmail.value = email;
            }
        }
    }
});

let authFormRef = ref<FormInstance>();


let ipBlockDialog = ref<{
    visible: boolean,
    errorDescr: string
}>();

let authFormModel = ref<{
    login: string,
    password: string,
    authCode: string,
    activationCode: string,
    captcha: null
}>({
    login: null,
    password: null,
    authCode: null,
    activationCode: null,
    captcha: null
});

let instance = getCurrentInstance().proxy;
let successLogin = false;

let captchaField = SIMPLE_CABINET_CAPTCHA_FIELD;


let showAuthForm = computed(() => !regFormDisplayed.value && !ipBlockDialog.value?.visible && !remindDialogDisplayed.value);
let authFormKey = ref<number>();

watch(showAuthForm, (value, oldValue) => {
    if (value != oldValue) {
        authFormKey.value = Date.now();
    }
})

async function auth(login?: string, password?: string) {
    if (login) {
        authFormModel.value.login = login;
        authFormModel.value.password = password;
    }
    if (activationDisplayed.value && !authFormModel.value.activationCode?.length) {
        return;
    }
    if (!authFormModel.value.captcha) {
        return;
    }
    let result = await LcabApiRequest.fetch<{
        isDualAuthRequired?: boolean,
        isActivationRequired?: boolean,
        isIpDisallowed?: boolean,
        dualAuthEmail?: string
    }>({
        url: "/api/auth/check",
        p: {
            activation: activationDisplayed.value,
            ...authFormModel.value
        }
    });
    if (result.isSuccess) {
        if (activationDisplayed.value) {
            document.dispatchEvent(new CustomEvent('account-activated'));
        }
        result.showMessage();
        successLogin = true;
        (authFormRef.value.$el as HTMLFormElement).submit();
        //window.location.href = LcabLayoutStore.appUrlPrefix.value;
    } else {
        if (result.data.isDualAuthRequired) {
            activationDisplayed.value = null;
            dualAuthEmail.value = result.data.dualAuthEmail;
            if (!dualAuth.value) {
                dualAuth.value = true;
            } else {
                result.showMessage();
            }
        } else if (result.data.isActivationRequired) {
            LcabApiResult.showSuccess(result.descr);
            activationDisplayed.value = true;
        } else if (result.data.isIpDisallowed) {
            ipBlockDialog.value = {
                visible: true,
                errorDescr: result.descr,
            };
        } else {
            captchaRef.value?.refreshCaptcha();
            if (!dualAuth.value) {
                authFormModel.value.captcha = null;
            }
            result.showMessage();
        }
    }
}


(window as any).cabinetAuth = () => {
    if (successLogin) {
        return true;
    }
    auth();
    return false;
}

function openRegForm() {
    regFormDisplayed.value = true;
}


let csrfTokenValue = computed(() => CsrfToken.get());

let authPageContent = computed(() => AccountStore.data.value.htmlContent.authPage);

let dealerParams = computed(() => AccountStore.data.value.dealerCabinetConfig);

function openRemindForm() {
    remindDialogDisplayed.value = true;
}

async function onOAuth(providerId: string, data: OAuthResultInterface) {
    let result = await LcabApiRequest.save<{
        isDualAuthRequired?: boolean,
        isActivationRequired?: boolean,
        isIpDisallowed?: boolean,
        dualAuthEmail?: string
    }>({
        url: `/api/auth/${providerId}/checkOAuth`,
        p: {
            code: data.code,
            state: data.state
        }
    });
    if (result.isSuccess) {
        (authFormRef.value.$el as HTMLFormElement).submit();
    }
}



return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(showAuthForm))
      ? (_openBlock(), _createBlock(_component_el_card, {
          key: _unref(authFormKey),
          style: _normalizeStyle({
                visibility: _unref(showAuthForm) ? 'visible': 'hidden',
                opacity: _unref(showAuthForm) ? '1': '0',
                transition: 'none',
                zIndex:12,
                maxWidth: '400px',
                margin: '0 auto'
            }),
          class: "position-relative"
        }, {
          default: _withCtx(() => [
            _createVNode(ElCardHeader, {
              title: _unref(__)('Авторизация')
            }, null, 8, ["title"]),
            _createVNode(ElCardSection, {
              style: _normalizeStyle(!_unref(dualAuth) ? 'padding-top: 0' : null)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  ref_key: "authFormRef",
                  ref: authFormRef,
                  action: `${_unref(LcabApiRequest).getCabinetUrlPrefix()}`,
                  "label-position": "top",
                  method: "POST",
                  onsubmit: "return window.cabinetAuth()"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("input", {
                      value: _unref(csrfTokenValue),
                      name: "csrf-token",
                      type: "hidden"
                    }, null, 8, _hoisted_2),
                    (_unref(activationDisplayed))
                      ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                          default: _withCtx(() => [
                            _cache[10] || (_cache[10] = _createElementVNode("p", null, " Введите полученный код активации ", -1)),
                            _createVNode(_component_el_input, {
                              modelValue: _unref(authFormModel).activationCode,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(authFormModel).activationCode) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }))
                      : (_unref(dualAuth))
                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                            key: 1,
                            class: "text"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, " На Email " + _toDisplayString(_unref(dualAuthEmail)) + ", указанный в настройках вашего личного кабинета, был выслан авторизационный код. Введите его в поле ниже. ", 1),
                              _createVNode(_component_el_input, {
                                modelValue: _unref(authFormModel).authCode,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(authFormModel).authCode) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createVNode(_component_el_form_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  id: "login",
                                  modelValue: _unref(authFormModel).login,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(authFormModel).login) = $event)),
                                  autocomplete: "username",
                                  name: "login",
                                  placeholder: "Ваш логин"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  id: "password",
                                  modelValue: _unref(authFormModel).password,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(authFormModel).password) = $event)),
                                  autocomplete: "current-password",
                                  name: "password",
                                  placeholder: "Ваш пароль",
                                  type: "password",
                                  "show-password": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, { label: "Введите код с картинки" }, {
                              default: _withCtx(() => [
                                _createVNode(CabinetCaptchaElement, {
                                  modelValue: _unref(authFormModel).captcha,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(authFormModel).captcha) = $event)),
                                  field: _unref(captchaField),
                                  ref_key: "captchaRef",
                                  ref: captchaRef
                                }, null, 8, ["modelValue", "field"])
                              ]),
                              _: 1
                            })
                          ], 64)),
                    _createVNode(_component_el_form_item, { class: "text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          id: "auth-check-button",
                          "native-type": "submit",
                          type: "success",
                          class: "d-block w-100",
                          disabled: !_unref(authFormModel).captcha
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(activationDisplayed) ? "Продолжить" : "Войти в личный кабинет"), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]),
                        (!_unref(activationDisplayed))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_unref(dealerParams).oAuthProviders.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _cache[11] || (_cache[11] = _createTextVNode(" Или ")),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dealerParams).oAuthProviders, (oAuthProvider) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: oAuthProvider.id,
                                        class: "mt-1"
                                      }, [
                                        _createVNode(OAuthButton, {
                                          "authorization-url": oAuthProvider.authorizationUrl,
                                          onAuth: ($event: any) => (onOAuth(oAuthProvider.id, $event))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_button, { class: "w-100 d-block oauth-button" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_4, [
                                                  _createVNode(FontAwesomeIcon, {
                                                    icon: oAuthProvider.icon
                                                  }, null, 8, ["icon"]),
                                                  _createElementVNode("span", _hoisted_5, _toDisplayString(oAuthProvider.descr), 1)
                                                ])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1032, ["authorization-url", "onAuth"])
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_6, [
                                _cache[13] || (_cache[13] = _createTextVNode(" Нет аккаунта? ")),
                                _createVNode(_component_el_button, {
                                  class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                                  link: "",
                                  onClick: openRegForm
                                }, {
                                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                                    _createTextVNode(" Зарегистрироваться ")
                                  ])),
                                  _: 1
                                }, 8, ["class"])
                              ]),
                              _createElementVNode("div", null, [
                                _createVNode(_component_el_button, {
                                  link: "",
                                  onClick: openRemindForm
                                }, {
                                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                                    _createTextVNode(" Забыли пароль? ")
                                  ])),
                                  _: 1
                                })
                              ])
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["action"])
              ]),
              _: 1
            }, 8, ["style"])
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true),
    _createVNode(RegFormDialog, {
      visible: _unref(regFormDisplayed),
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_isRef(regFormDisplayed) ? (regFormDisplayed).value = $event : regFormDisplayed = $event)),
      "initial-email": _unref(regInitialEmail),
      onDone: _cache[6] || (_cache[6] = ($event: any) => (auth($event.login, $event.password)))
    }, null, 8, ["visible", "initial-email"]),
    (_unref(ipBlockDialog) && _unref(ipBlockDialog).visible)
      ? (_openBlock(), _createBlock(IpBlockDisableDialog, {
          key: 1,
          "auth-form-model": _unref(authFormModel),
          visible: _unref(ipBlockDialog).visible,
          "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_unref(ipBlockDialog).visible) = $event)),
          "error-descr": _unref(ipBlockDialog).errorDescr,
          onDone: _cache[8] || (_cache[8] = ($event: any) => (auth()))
        }, null, 8, ["auth-form-model", "visible", "error-descr"]))
      : _createCommentVNode("", true),
    _createVNode(RemindDialog, {
      visible: _unref(remindDialogDisplayed),
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => (_isRef(remindDialogDisplayed) ? (remindDialogDisplayed).value = $event : remindDialogDisplayed = $event))
    }, null, 8, ["visible"]),
    _createVNode(CabinetHtmlify, {
      escape: false,
      value: _unref(authPageContent)
    }, null, 8, ["value"])
  ]))
}
}

})