import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-weight: bold;" }
const _hoisted_3 = {
  key: 1,
  class: "text-muted"
}

import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";
import {TargetActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "TargetBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<TargetActionDescription>()
    },
  setup(__props) {

let props = __props;

let outputNameForParentCascadeMessage = computed(() => {
    return props.params.outputNameForParentCascadeMessage;
});

return (_ctx: any,_cache: any) => {
  return (_unref(outputNameForParentCascadeMessage))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createTextVNode(" Название выхода: ")),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(outputNameForParentCascadeMessage)), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, " Название выхода не указано "))
}
}

})