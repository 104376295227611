import {
    AbstractTarifPrice,
    SingleTarifPrice,
    TarifPriceType,
    TarifPriceWithPricesDictionary
} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/AbstractTarifPrice";
import {Discriminator} from "~/ts/library/AbstractEntity";
import Dictionary from "~/ts/library/Dictionary";
import {ComponentOptions} from "vue";
import PagerPartPriceTableCell
    from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/priceTypes/PagerPartPriceTableCell.vue";
import PagerPartPriceEdit from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/priceTypes/PagerPartPriceEdit.vue";
import PagerScalePriceEdit from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/priceTypes/PagerScalePriceEdit.vue";
import PagerScalePriceTableCell
    from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/priceTypes/PagerScalePriceTableCell.vue";
import {UseTarifListInterface} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/useTarifList";
import {TarifPath} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/Interfaces";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {USLUGA_ID_PAGER} from "~/cabinet/ts/Constant";
import Currency from "~/cabinet/ts/service/Currency";
import {useRawComponent} from "~/core-ui/ts/useRawComponent";

export const AS_COUNTRY_PAGER_PRICE_TYPE_ID = "asCountry";
export const PART_PRICE_PAGER_PRICE_TYPE_ID = "partPrice";
export const SCALE_PRICE_PAGER_PRICE_TYPE_ID = "scalePrice";
export const SINGLE_PRICE_PAGER_PRICE_TYPE_ID = "singlePrice";

//type PagerTarifPriceTypeId = "asCountry" | "partPrice" | "scalePrice" | "singlePrice";

export class PagerTarifType extends TarifPriceType {
    isForOpsosOnly: boolean;

    setIsForOpsosOnly() {
        this.isForOpsosOnly = true;
        return this;
    }
}


function modifyPriceForHighlight(result: {
    sum: number;
    currencyId: string
}, tarifList: UseTarifListInterface<any>, tarifId: string, path: TarifPath) {
    if (result) {
        if (AccountStore.access.value.checkDealerOwnsUsluga(USLUGA_ID_PAGER)) {
            if (!["opsosSourceList"].includes(path[1].toString())) {
                if (tarifList.costTarif.value.id == tarifId) {
                    let params = tarifList.uslugaParams.value;
                    let dealerOwnerServicePriceCurrencyId: string = params?.dealerOwnerServicePriceCurrencyId;
                    let dealerOwnerServicePrice: number = params?.dealerOwnerServicePrice;
                    if (dealerOwnerServicePrice && dealerOwnerServicePriceCurrencyId) {
                        result.sum += Currency.convert(dealerOwnerServicePrice, dealerOwnerServicePriceCurrencyId, result.currencyId);
                    }
                }
            }
        }
    }
}


export abstract class AbstractPagerTarifPrice extends AbstractTarifPrice {
    public static getPagerTarifTypes(): PagerTarifType[] {
        return [
            new PagerTarifType(SinglePagerTarifPrice, SINGLE_PRICE_PAGER_PRICE_TYPE_ID, 'Единая цена'),
            (new PagerTarifType(AsCountryPagerTarifPrice, AS_COUNTRY_PAGER_PRICE_TYPE_ID, 'Как у страны'))
                .setIsForOpsosOnly(),
            new PagerTarifType(PartPagerTarifPrice, PART_PRICE_PAGER_PRICE_TYPE_ID, "Спец-цена"),
            new PagerTarifType(ScalePagerTarifPrice, SCALE_PRICE_PAGER_PRICE_TYPE_ID, 'Тарифная сетка')
        ];
    }

    protected getDiscriminator(): Discriminator {
        return new Discriminator('priceTypeId', {
            [SINGLE_PRICE_PAGER_PRICE_TYPE_ID]: SinglePagerTarifPrice,
            [AS_COUNTRY_PAGER_PRICE_TYPE_ID]: AsCountryPagerTarifPrice,
            [PART_PRICE_PAGER_PRICE_TYPE_ID]: PartPagerTarifPrice,
            [SCALE_PRICE_PAGER_PRICE_TYPE_ID]: ScalePagerTarifPrice
        });
    }
}

export class SinglePagerTarifPrice extends SingleTarifPrice {
    getPriceTypeId(): string {
        return SINGLE_PRICE_PAGER_PRICE_TYPE_ID;
    }

    getPriceForHighlight(tarifList: UseTarifListInterface<any>, tarifId: string, path: TarifPath): {
        sum: number;
        currencyId: string
    } {
        let result = super.getPriceForHighlight(tarifList, tarifId, path);
        modifyPriceForHighlight(result, tarifList, tarifId, path);
        return result;
    }
}

export class AsCountryPagerTarifPrice extends AbstractPagerTarifPrice {
    get editComponent(): ComponentOptions<any> | any {
        return undefined;
    }

    get tableComponent(): ComponentOptions<any> | any {
        return undefined;
    }

    getPriceTypeId(): string {
        return AS_COUNTRY_PAGER_PRICE_TYPE_ID;
    }

    applyActionMinRaznostByCostTarifPrice(minRaznost: number, cost: AbstractTarifPrice): this {
        return this;
    }

    applyActionPlusPrice(plusValue: number): this {
        return this;
    }

    applyActionProfitPercentByCostTarifPrice(percent: number, cost: AbstractTarifPrice): this {
        return this;
    }

    applyActionUpdateByPercent(percent: number): this {
        return this;
    }

    formatPrice(): this {
        return this;
    }

    priceToArray(): number[] {
        return [0];
    }

    makeConvertCurrencyTo(newCurrencyId: string): void {
    }

    protected applySumFromSource(source: AbstractTarifPrice): this {
        return this;
    }

    getPriceForHighlight(tarifList: UseTarifListInterface<any>, tarifId: string, path: string[]): { sum: number; currencyId: string } {
        path = [...path];
        path[2] = path[3] = "";
        return tarifList.getTarifPriceByPath(tarifId, path)?.getPriceForHighlight(tarifList, tarifId, path);
    }
}

export class PartPagerTarifPrice extends TarifPriceWithPricesDictionary {
    protected prices: Dictionary<number> = {
        '0': 5,
        '1': 4,
        '2': 3,
        '3': 2,
        'n': 1
    };

    get tableComponent(): ComponentOptions<any> | any {
        return useRawComponent(PagerPartPriceTableCell);
    }

    get editComponent(): ComponentOptions<any> | any {
        return useRawComponent(PagerPartPriceEdit);
    }

    getPriceTypeId(): string {
        return PART_PRICE_PAGER_PRICE_TYPE_ID;
    }

    protected getPricesDictionary(): Dictionary<number> {
        return this.prices;
    }

    getPriceForHighlight(tarifList: UseTarifListInterface<any>, tarifId: string, path: TarifPath): {
        sum: number;
        currencyId: string
    } {
        let result = super.getPriceForHighlight(tarifList, tarifId, path);
        modifyPriceForHighlight(result, tarifList, tarifId, path);
        return result;
    }

}

export class ScalePagerTarifPrice extends TarifPriceWithPricesDictionary {
    public prices: Dictionary<number> = {
        '0': 5,
        '100000': 4,
        '300000': 3
    };

    get editComponent(): ComponentOptions<any> | any {
        return useRawComponent(PagerScalePriceEdit);
    }

    get tableComponent(): ComponentOptions<any> | any {
        return useRawComponent(PagerScalePriceTableCell);
    }

    getPriceTypeId(): string {
        return SCALE_PRICE_PAGER_PRICE_TYPE_ID;
    }

    protected getPricesDictionary(): Dictionary<number> {
        return this.prices;
    }

    getPriceForHighlight(tarifList: UseTarifListInterface<any>, tarifId: string, path: TarifPath): {
        sum: number;
        currencyId: string
    } {
        let result = super.getPriceForHighlight(tarifList, tarifId, path);
        modifyPriceForHighlight(result, tarifList, tarifId, path);
        return result;
    }
}



