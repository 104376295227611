import {computed, ExtractPropTypes} from "vue";
import {useActionBlockFormProps} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import AbstractDataProviderEntityActionDescription
    from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/AbstractDataProviderEntityActionDescription";
import Dictionary from "~/ts/library/Dictionary";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequestFactory from "~/cabinet/ts/api/LcabApiRequestFactory";
import {
    DataProviderEntityFieldInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionParamInterface";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";

type PropsType = ExtractPropTypes<ReturnType<typeof useActionBlockFormProps<AbstractDataProviderEntityActionDescription<any>>>>;

/** пришлось костыль, пхпшторм чет отказывается подсвечивать */
type FixedPropsType = PropsType & {
    actionDescription: AbstractDataProviderEntityActionDescription<any>
}

export function getEntityActionRequest(
    props: PropsType,
    discriminatorValues: Dictionary<any>,
    additionalRequestData?: Dictionary<any>
) {
    let fixedProps = props as FixedPropsType;
    additionalRequestData = additionalRequestData ?? {};

    return new ReactiveRequest<ResponseInterface>(LcabApiRequestFactory.create({
        url: `/api/events/dataProvider/getFrontendParamsForSaveEntityAction`,
        p: {
            dataProviderPrimaryId: fixedProps.actionDescription.authPrimaryId,
            dataProviderPlatformId: fixedProps.actionDescription.platformId,
            entityClassId: fixedProps.actionDescription.frontendParams.entityClassId,
            eventDescriptionId: fixedProps.eventParamsByAuth.eventId,
            eventPlatformId: fixedProps.eventParamsByAuth.platform.id,
            eventAuthId: fixedProps.eventParamsByAuth.authId,
            discriminatorValues,
            ...additionalRequestData
        }
    }));
}

export const SEARCH_MODE_LAST_SAVED_IN_THIS_BLOCK = "lastSavedInThisBlock";
export const SEARCH_MODE_LAST_SAVED_EVERYWHERE = "lastSavedEverywhere";

export function getSearchModesData(props: PropsType) {
    let fixedProps = props as FixedPropsType;

    let searchByIdModes: Dictionary<string> = {
        [SEARCH_MODE_LAST_SAVED_EVERYWHERE]: 'в любом блоке',
        [SEARCH_MODE_LAST_SAVED_IN_THIS_BLOCK]: 'в этом блоке'
    };

    let searchTypes = computed(() => fixedProps.actionDescription.frontendParams.searchModes);
    let otherSearchTypes = computed(() => searchTypes.value.filter(item => !searchByIdModes.hasOwnProperty(item.id)));
    let searchByIdSearchTypes = computed(() => searchTypes.value.filter(item => searchByIdModes.hasOwnProperty(item.id)));
    return {
        searchByIdModes,
        searchTypes,
        otherSearchTypes,
        searchByIdSearchTypes
    }
}

interface ResponseInterface {
    fields: DataProviderEntityFieldInterface[];
    filters: IFiltersData;
    isUpdateAllowed: boolean;
}