import {ApiRequestConfig} from "~/core-ui/ts/request/AbstractApiRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";

export default class LcabApiRequestFactory {
    private static requestClass: typeof LcabApiRequest<any>;

    public static setClass(requestClass: typeof LcabApiRequest<any>) {
        this.requestClass = requestClass;
    }

    public static create<TData>(options: ApiRequestConfig, dataType?: AbstractEntityBuilder<TData>): LcabApiRequest<TData> {
        return new this.requestClass(options, dataType);
    }
}