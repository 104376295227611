import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { class: "text-danger" }
const _hoisted_4 = {
  key: 3,
  class: "mt-4"
}
const _hoisted_5 = { key: 0 }

import {ref, computed, watch, onBeforeUnmount} from "vue";
import Platform from "~/cabinet/ts/data/integrations/Platform";
import Dictionary from "~/ts/library/Dictionary";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import Auth, {Child} from "~/cabinet/ts/data/integrations/Auth";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import ObjectHelper from "~/ts/library/ObjectHelper";
import Delay from "~/ts/library/Delay";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import PreviewKbaseArticle from "~/cabinet/vue/client/online/config/knowledgeBase/PreviewKbaseArticle.vue";
import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import IAuthCreatedPayload from "~/cabinet/vue/client/integrations/IAuthCreatedPayload";
import Events from "~/ts/library/Events";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";
import AuthCard from "~/cabinet/vue/client/integrations/AuthCard.vue";


const __default__ = {
    name: "IntegrationAuthDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    platformTypeId: {},
    platforms: {},
    parentAuth: {},
    platform: {},
    value: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["auth-created", "save", "remove"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;


class GetAuthForm extends AbstractEntity {
    auth: Auth;
    form: IForm;
    reopen: boolean;

    protected getFields(): AbstractEntityFields {
        return {
            auth: Auth
        }
    }
}

let visible = _useModel<boolean>(__props, "visible");

let platformAuthForm = ref<IForm>();
let reopenPlatformAuthForm = ref(false);

let knowledgeBaseArticleVisible = ref(false);

let editAuthDialog = ref<{
    auth: Auth,
    platform: Platform,
    visible: boolean
}>();

let model = ref<{
    id: string,
    form: any,
    descr: string,
    childId: number,
    parentAuthId: string,
    platformId: string,
    additionalForm?: {
        form: IForm,
        model: Dictionary<any>
    }
}>();

let additionalForm = ref<{
    visible: boolean,
    descr: string,
    form: IForm,
    model: Dictionary<any>
}>();

let form = ref<Dictionary<any>>();
let oauthWindow = ref<Window>();
let oauthWindowCloseInterval = ref<number>();
let childAuthFormDisplayed = ref(false);


addDisposableEvent(Events.addEventListener(
    "oauth-done", window, (event: CustomEvent) => {
        if (event && event.detail && event.detail.authId) {
            oauthSuccess(event.detail.authId);
        }
    }
));

onBeforeUnmount(() => {
    clearOAuthEventInterval();
    oauthWindow.value?.close();
});

let isReadonly = computed(() => {
    return model.value.id && !props.platform.isAuthEditable;
});

let childsCount = computed(() => {
    let result = 0;
    if (props.parentAuth?.childs) {
        for (let child of props.parentAuth.childs) {
            result += child.items.length;
        }
    }
    return result;
});

let parentAuthPlatform = computed(() => {
    return props.parentAuth?.getPlatform(props.platforms);
});

let isNeedToSelectChildPlatformItem = computed(() => {
    return parentAuthPlatform.value?.hasChildPlatform;
});

let isCanDisplayFormAndApply = computed(() => {
    return !isNeedToSelectChildPlatformItem.value || model.value.childId;
});

let isNeedToFillSomeForm = computed(() => {
    return props.platform.isAuthDescrManual || platformAuthForm.value;
});

async function resetModel(authId?: string) {
    if (!authId && props.value) {
        authId = props.value.id;
    }
    let getAuthFormUrl = `/api/client/integration/getAuthForm/${props.platform.id}`;
    if (authId) {
        getAuthFormUrl += `/${authId}`;
    }
    let result = await LcabApiRequest.fetch({
        url: getAuthFormUrl
    }, GetAuthForm);
    if (!result.showMessageOnError()) {
        childAuthFormDisplayed.value = false;
        platformAuthForm.value = result.data.form;
        reopenPlatformAuthForm.value = result.data.reopen;
        let auth: Auth = result.data.auth;
        if (auth && auth.id) {
            if (props.platform.isNeedPrepareAuthSave) {
                try {
                    await ElConfirm("Внимание. Если вы продолжите редактирование, существующая интеграция будет остановлена. Вам будет необходимо завершить настройку, если вы продолжите.");
                    let result = await LcabApiRequest.fetch({
                        url: `/api/client/integration/prepareAuthSave/${props.platform.id}/${auth.id}`
                    });
                    if (result.showMessageOnError()) {
                        visible.value = false;
                        return;
                    }
                } catch (e) {
                    visible.value = false;
                    return;
                }
            }
        }

        model.value = {
            id: auth ? auth.id : null,
            form: auth ? auth.params : null,
            descr: auth ? auth.descr : null,
            childId: null,
            parentAuthId: null,
            platformId: props.platform.id
        };

        applyIfNeeded();
    } else {
        visible.value = false;
    }
}

watch(props.platform, () => resetModel());


resetModel();

let isNeedInteraction = computed(() => {
    return platformAuthForm.value || (parentAuthPlatform.value?.hasChildPlatform);
});


function applyIfNeeded() {
    if (!platformAuthForm.value) {
        onApply();
    }
}

async function selectChild(child: Child, parentAuth: Auth) {
    if (child.authId) {
        emitAuthId(child.authId);
    } else {
        await resetModel();
        childAuthFormDisplayed.value = true;
        model.value.childId = child.id;
        model.value.parentAuthId = parentAuth.id;
        applyIfNeeded();
    }
}

function getAuthCreatedPayload(authId: string): IAuthCreatedPayload {
    return {
        authId,
        closeDialog: !reopenPlatformAuthForm.value
    };
}

function emitAuthId(authId: string) {
    emits("auth-created", getAuthCreatedPayload(authId));
    emits("save");
    if (reopenPlatformAuthForm.value) {
        resetModel(authId);
    } else {
        visible.value = false;
    }
}

function clearOAuthEventInterval() {
    clearInterval(oauthWindowCloseInterval.value);
}

async function onApply(showLoading: boolean = true) {
    if (isCanDisplayFormAndApply.value) {
        let modelClone = ObjectHelper.jsonClone(model.value);
        if (additionalForm.value?.visible) {
            modelClone.additionalForm = {
                form: additionalForm.value.form,
                model: additionalForm.value.model
            };
        }
        if (showLoading) {
            additionalForm.value = null;
        }
        if (props.platform.isOAuth) {
            oauthWindow.value = window.open("", "oauthWindow", "width=700,height=500");
            let doc = oauthWindow.value.document;
            doc.body.innerHTML = `<div style="font-family: Arial, sans-serif; font-size: 13px; text-align: center; padding: 30px;">Сейчас вы будете перенаправлены на ${props.platform.descr} для продолжения интеграции.
<br /><br />Не закрывайте это окно.
</div>`;
            doc.title = `Интеграция с ${props.platform.descr}`;
            let result = await LcabApiRequest.fetch({
                url: `/api/client/integration/makeOuathUrl`,
                p: modelClone
            });
            if (!result.showMessageOnError()) {
                let url = result.getData("url");
                if (oauthWindow.value) {
                    oauthWindow.value.location.href = url;
                }
                //= window.open(url, "oauthWindow", "width=700,height=500");
                clearOAuthEventInterval();
                oauthWindowCloseInterval.value = setInterval(() => {
                    if (oauthWindow.value.closed) {
                        clearOAuthEventInterval();
                        visible.value = false;
                    }
                }, 500) as any;

            } else {
                oauthWindow.value?.close();
                visible.value  = false;
            }
        } else {
            try {
                let result = await LcabApiRequest.fetch({
                    url: `/api/client/integration/saveAuth`,
                    p: modelClone,
                    silent: !showLoading
                });
                if (result.isSuccess) {
                    let authId = result.getData("id");
                    if (!modelClone.id) {
                        emitAuthId(authId);
                    } else {
                        emits("save");
                        result.showMessage();
                        if (reopenPlatformAuthForm.value) {
                            resetModel(authId)
                        } else {
                            visible.value = false;
                        }
                    }
                } else {
                    let form: IForm = result.getData("form");
                    if (form) {
                        additionalForm.value = {
                            visible: true,
                            model: {},
                            form,
                            descr: result.descr
                        };
                        let reloadDelay = result.getData("reloadDelay");
                        if (reloadDelay) {
                            (async () => {
                                try {
                                    await Delay.make(reloadDelay * 1000);
                                    await onApply(false);
                                } catch (e) {

                                }
                            })();
                        }
                    } else {
                        result.showMessage();
                        //close();
                    }
                }
            } catch (e) {
                console.error(e);
                if (!isNeedInteraction.value) {
                    visible.value = false;
                }
            }
        }
    }
}

function removeAuth(authId: string) {
    emits("remove", authId);
}

function oauthSuccess(authId: string) {
    oauthWindow.value?.close();
    emits("auth-created", getAuthCreatedPayload(authId));
}

function onAdditionalFormToggle(value: boolean) {
    if (!value) {
        if (!isNeedInteraction.value) {
            visible.value = false;
        }
    }
}

function editAuth(auth: Auth, platform: Platform) {
    editAuthDialog.value = {
        auth,
        platform,
        visible: true
    };
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_integration_auth_dialog = _resolveComponent("integration-auth-dialog", true)!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_unref(model))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(isNeedInteraction))
          ? (_openBlock(), _createBlock(MyDialog, {
              key: 0,
              visible: _unref(visible),
              "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
              title: `Интеграция c ${_ctx.platform.descr}`,
              "apply-disabled": !_unref(isCanDisplayFormAndApply),
              "button-text": !_unref(isReadonly) ? 'Сохранить' : null,
              onApply: onApply,
              form: {
                labelWidth: '200px',
                labelPosition: 'left',
                model: _unref(model)
            }
            }, {
              default: _withCtx(() => [
                (_unref(parentAuthPlatform) && !_unref(childAuthFormDisplayed))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_unref(parentAuthPlatform).hasChildPlatform)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.parentAuth.childs && _ctx.parentAuth.childs.length)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.parentAuth.childs, (childPlatform) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                                    _createElementVNode("h4", null, _toDisplayString(childPlatform.selectDescr), 1),
                                    (!_unref(childsCount))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(parentAuthPlatform).emptyChildErrorDescr), 1),
                                          _createElementVNode("p", null, [
                                            _createVNode(_component_el_button, {
                                              onClick: _cache[0] || (_cache[0] = ($event: any) => (editAuth(_ctx.parentAuth, _unref(parentAuthPlatform))))
                                            }, {
                                              icon: _withCtx(() => [
                                                _createVNode(FontAwesomeIcon, { icon: "refresh" })
                                              ]),
                                              default: _withCtx(() => [
                                                _cache[11] || (_cache[11] = _createTextVNode(" Переподключиться "))
                                              ]),
                                              _: 1
                                            }),
                                            (_unref(editAuthDialog) && _unref(editAuthDialog).visible)
                                              ? (_openBlock(), _createBlock(_component_integration_auth_dialog, {
                                                  key: 0,
                                                  platform: _unref(editAuthDialog).platform,
                                                  platforms: [_unref(editAuthDialog).platform],
                                                  "platform-type-id": _ctx.platformTypeId,
                                                  visible: _unref(editAuthDialog).visible,
                                                  "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_unref(editAuthDialog).visible) = $event)),
                                                  value: _unref(editAuthDialog).auth
                                                }, null, 8, ["platform", "platforms", "platform-type-id", "visible", "value"]))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (childPlatform.items.length)
                                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(childPlatform.items, (childItem) => {
                                          return (_openBlock(), _createBlock(AuthCard, {
                                            key: childItem.id,
                                            auth: childItem.auth,
                                            avatar: childItem.avatar,
                                            descr: childItem.descr,
                                            platform: childPlatform.platform,
                                            "platform-type-id": _ctx.platformTypeId,
                                            "auto-select": childItem.isAutoSelect && childPlatform.authNotExistedInChilds.length == 0 && childPlatform.items.length == 1,
                                            onRemove: ($event: any) => (removeAuth(childItem.authId)),
                                            onSelect: ($event: any) => (selectChild(childItem, _ctx.parentAuth)),
                                            onReload: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('save')))
                                          }, null, 8, ["auth", "avatar", "descr", "platform", "platform-type-id", "auto-select", "onRemove", "onSelect"]))
                                        }), 128))
                                      : _createCommentVNode("", true),
                                    (childPlatform.authNotExistedInChilds.length)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                          _cache[12] || (_cache[12] = _createElementVNode("p", null, "Подключенные ранее интеграции", -1)),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(childPlatform.authNotExistedInChilds, (childAuth) => {
                                            return (_openBlock(), _createBlock(AuthCard, {
                                              key: childAuth.id,
                                              auth: childAuth,
                                              "platform-type-id": _ctx.platformTypeId,
                                              platform: childPlatform.platform,
                                              onReload: _cache[3] || (_cache[3] = ($event: any) => (_unref(emits)('save'))),
                                              onRemove: ($event: any) => (removeAuth(childAuth.id)),
                                              onSelect: ($event: any) => (emitAuthId(childAuth.id))
                                            }, null, 8, ["auth", "platform-type-id", "platform", "onRemove", "onSelect"]))
                                          }), 128))
                                        ], 64))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 256))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (_unref(isCanDisplayFormAndApply))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_unref(isNeedToFillSomeForm))
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.platform.isAuthDescrManual)
                              ? (_openBlock(), _createBlock(_component_el_form_item, {
                                  key: 0,
                                  label: "Название интеграции",
                                  required: "",
                                  prop: "descr"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_input, {
                                      modelValue: _unref(model).descr,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).descr) = $event)),
                                      placeholder: "Оно будет видно только вам"
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_unref(platformAuthForm))
                              ? (_openBlock(), _createBlock(MyForm, {
                                  key: 1,
                                  form: _unref(platformAuthForm),
                                  modelValue: _unref(model).form,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).form) = $event)),
                                  preview: _unref(isReadonly)
                                }, null, 8, ["form", "modelValue", "preview"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (!_ctx.platform.isOAuth)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(" Нажмите кнопку \"Сохранить\" для продолжения интеграции. ")
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(" Сейчас должно открыться всплывающее окно для продолжения интеграции с " + _toDisplayString(_ctx.platform.descr) + "... ", 1)
                                ], 64))
                          ], 64))
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.platform.knowledgeBaseRegisterId && _ctx.platform.knowledgeBaseArticleHistoryId)
                  ? (_openBlock(), _createBlock(_component_el_form_item, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(knowledgeBaseArticleVisible) //@ts-ignore
 ? knowledgeBaseArticleVisible.value = true : knowledgeBaseArticleVisible = true))
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode(" Посмотреть инструкцию ")
                          ])),
                          _: 1
                        }),
                        (_unref(knowledgeBaseArticleVisible))
                          ? (_openBlock(), _createBlock(PreviewKbaseArticle, {
                              key: 0,
                              visible: _unref(knowledgeBaseArticleVisible),
                              "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => (_isRef(knowledgeBaseArticleVisible) ? (knowledgeBaseArticleVisible).value = $event : knowledgeBaseArticleVisible = $event)),
                              "knowledge-base-article-history-id": _ctx.platform.knowledgeBaseArticleHistoryId,
                              "knowledge-base-register-id": _ctx.platform.knowledgeBaseRegisterId
                            }, null, 8, ["visible", "knowledge-base-article-history-id", "knowledge-base-register-id"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.platform.notGuaranteedText)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("h4", null, [
                        _createVNode(FontAwesomeIcon, {
                          class: "text-orange mr-1",
                          icon: "exclamation-triangle"
                        }),
                        _cache[14] || (_cache[14] = _createTextVNode(" Работа не гарантирована "))
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platform.notGuaranteedText, (line, index) => {
                        return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(line), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["visible", "title", "apply-disabled", "button-text", "form"]))
          : _createCommentVNode("", true),
        (_unref(additionalForm) && _unref(additionalForm).visible)
          ? (_openBlock(), _createBlock(MyDialog, {
              key: 1,
              visible: _unref(additionalForm).visible,
              "onUpdate:visible": [
                _cache[10] || (_cache[10] = ($event: any) => ((_unref(additionalForm).visible) = $event)),
                onAdditionalFormToggle
              ],
              form: {
                    model: _unref(additionalForm).model,
                    labelPosition: 'left',
                    labelWidth: '200px'
                },
              "button-text": "Продолжить",
              title: "Требуется дополнительное действие",
              onApply: onApply
            }, {
              default: _withCtx(() => [
                (_unref(additionalForm).descr.length)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(additionalForm).descr), 1))
                  : _createCommentVNode("", true),
                _createVNode(MyForm, {
                  modelValue: _unref(additionalForm).model,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(additionalForm).model) = $event)),
                  form: _unref(additionalForm).form,
                  preview: _unref(isReadonly)
                }, null, 8, ["modelValue", "form", "preview"])
              ]),
              _: 1
            }, 8, ["visible", "form"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})