import * as moment from "~/node_modules/moment";
import {Intervals} from "~/ts/library/delay/Intervals";
import {__} from "~/ts/library/Translate";
import {
    MY_TIME_SELECTOR_MULTIPLY_DAYS,
    MY_TIME_SELECTOR_MULTIPLY_HOURS,
    MY_TIME_SELECTOR_MULTIPLY_MINUTES
} from "~/cabinet/vue/interface/form/IMyTimeSelectorValue";
import {reactive} from "vue";


let timeOffsetCache: number;

let reactiveHelper = reactive({
    now: (new Date()).getTime(),
    nowEverySecond: (new Date()).getTime()
});

const secondsFormatList = [
    {multiply: MY_TIME_SELECTOR_MULTIPLY_DAYS, descr: __("д")},
    {multiply: MY_TIME_SELECTOR_MULTIPLY_HOURS, descr: __("ч")},
    {multiply: MY_TIME_SELECTOR_MULTIPLY_MINUTES, descr: __("мин")}
];

Intervals.set(() => {
    reactiveHelper.now = Date.now();
}, 60000);

Intervals.set(() => {
    reactiveHelper.nowEverySecond = Date.now();
}, 1000);

export default class DateHelper {
    private date: Date;

    private static timeZoneOffset: number = 0;

    public static setTimeZoneOffset(seconds: number) {
        this.timeZoneOffset = seconds * 1000 + (new Date()).getTimezoneOffset() * 60000;
    }

    constructor(date: string | number | Date, utc: boolean = false) {
        if (typeof date == "number") {
            if (date < 10000000000) {
                date *= 1000;
            }
            if (!utc) {
                date += DateHelper.timeZoneOffset;
            }
        }
        this.date = (typeof date == "object") ? date : DateHelper.parseDate(date);
        if (utc) {
            this.date.setMinutes(this.date.getMinutes() + this.date.getTimezoneOffset());
        }
    }

    public static getMonthDescr(month: number): string {
        let date = new Date();
        date.setDate(1);
        date.setMonth(month);
        return (new DateHelper(date)).moment.format("MMMM");
    }

    private static parseDate(str: string | number): Date {
        if (typeof str == "number") {
            return new Date(str);
        } else {
            var a: any[] = str.split(/[^0-9]/);
            if (a.length == 3) {
                return new Date(a[0], parseInt(a[1]) - 1, a[2]);
            } else {
                return new Date(a[0], parseInt(a[1]) - 1, a[2], a[3], a[4], a[5]);
            }
        }
    }

    public convertFromServer() {
        this.date.setHours(this.date.getHours() + this.timeOffset());
        return this;
    }

    public convertToServer() {
        this.date.setHours(this.date.getHours() - this.timeOffset());
        return this;
    }

    /*
    private addZero(value: number): string {
        return value > 9 ? value.toString() : "0" + value;
    }
     */

    get moment() {
        return moment(this.date);
    }

    toMysqlFormat() {
        return this.moment.format("YYYY-MM-DD HH:mm:ss");
        //return this.toMysqlFormatDate() + " " + twoDigits(this.date.getHours()) + ":" + twoDigits(this.date.getMinutes()) + ":" + twoDigits(this.date.getSeconds());
    };

    toMysqlFormatDate() {
        return this.moment.format("YYYY-MM-DD");
        //return this.date.getFullYear() + "-" + twoDigits(1 + this.date.getMonth()) + "-" + twoDigits(this.date.getDate());
    };

    toLocaleDateTimeString(withSeconds: boolean = false) {
        let format = "L LT";
        if (withSeconds) {
            format += "S";
        }

        return this.moment.format(format);
        //return this.date.toLocaleDateString() + " " + this.addZero(this.date.getHours()) + ":" + this.addZero(this.date.getMinutes());
    }

    toLocaleTimeString() {
        return this.moment.format("LT");
    }

    toMysqlFormatTime() {
        return this.moment.format("HH:mm:ss");
    }

    toLocaleDateString(wordMonth: boolean = false) {
        return this.moment.format(wordMonth ? "LL" : "L");
        //return this.date.toLocaleDateString();
    }

    timeOffset() {
        if (timeOffsetCache == null) {
            timeOffsetCache = (-(new Date()).getTimezoneOffset() / 60) - 3;
        }
        return timeOffsetCache;
    }

    fromNow() {
        if (reactiveHelper.now < 0) {
            reactiveHelper.now = 1;
        }
        return this.moment.fromNow();
    }

    public static get now() {
        return reactiveHelper.now;
    }

    public static get nowInSeconds() {
        return Math.ceil(reactiveHelper.now / 1000);
    }

    public static get nowEverySecond() {
        return reactiveHelper.nowEverySecond;
    }

    public static get nowEverySecondInSeconds() {
        return Math.ceil(reactiveHelper.nowEverySecond / 1000);
    }


    get dateObject() {
        return this.date;
    }

    public static secondsFormat(seconds: number): string {
        for (let item of secondsFormatList) {
            let result = Math.floor(seconds / item.multiply);
            if (result * item.multiply == seconds) {
                return `${result} ${item.descr}`;
            }
        }
        return seconds + ' ' + __('сек');
    }

    public static secondsFormatToClosestFloor(seconds: number, exceptDays: boolean = false): string {
        for (let item of secondsFormatList) {
            if (exceptDays && item.multiply == MY_TIME_SELECTOR_MULTIPLY_DAYS) {
                continue;
            }
            let result = Math.floor(seconds / item.multiply);
            if (result > 0) {
                return `${result} ${item.descr}`;
            }
        }
        return seconds + ' ' + __('сек');
    }

    public static secondsToHoursMinutes(seconds: number): string | null {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;
        if (hours || minutes) {
            let result: string[] = [];
            if (hours) {
                result.push(`${hours} ч`);
            }
            if (minutes) {
                result.push(`${minutes} мин`);
            }
            return result.join(" ");
        } else {
            return null;
        }
    }

}
