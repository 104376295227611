import AbstractEntity, {AbstractEntityBuilder, AbstractEntityFields, Discriminator} from "~/ts/library/AbstractEntity";
import ActionOutput from "~/cabinet/ts/data/events/ActionOutput";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import ActionBlock from "~/cabinet/ts/data/events/ActionBlock";
import {Component} from "vue";
import {IActionDescriptionFrontendParams} from "~/cabinet/ts/data/events/Interface";
import Dictionary from "~/ts/library/Dictionary";

export default abstract class AbstractActionDescription<Model, FrontendParams extends IActionDescriptionFrontendParams = IActionDescriptionFrontendParams> extends AbstractEntity {
    public id: string;
    public typeId: string;
    public platformId: string;
    public platformDescr: string;
    public frontendParams: FrontendParams;
    public descr: string;
    public groupDescr?: string;
    public extraDescr?: string;
    public iconId: string;
    public dialogWidth: number;

    public isOutputDependOnBlock?: boolean;
    public authPrimaryId?: string;
    private outputs: ActionOutput[];
    public maxOutputTime: number;
    public minOutputTime: number;
    public defaultOutputTime: number;
    public isUseOutputTime?: boolean;
    public isRemovable: boolean;
    public isEditable: boolean;
    private readyToUse: boolean;
    public isInputAllowed: boolean;
    public backgroundColor: string;
    public textColor: string;
    public isExpert: boolean;
    public form?: IForm;
    public availableError?: string;
    private static currentDiscriminator: Discriminator;

    protected getDiscriminator(): Discriminator {
        return AbstractActionDescription.currentDiscriminator;
    }

    public static setDiscriminator(classList: Dictionary<AbstractEntityBuilder<any>>): void {
        this.currentDiscriminator = new Discriminator("typeId", classList);
    }

    makeNewBlock() {
        return new ActionBlock();
    }

    getOutputs(block: ActionBlock) {
        return this.isOutputDependOnBlock ? block.outputs : this.outputs;
    }

    protected getFields(): AbstractEntityFields {
        return {
            outputs: ActionOutput
        };
    }

    get previewComponent(): Component {
        return null;
    }

    get formComponent(): Component {
        return null;
    }

    get outputComponent(): Component {
        return null;
    }

    get isReadyToUse(): boolean {
        return this.readyToUse || window.location.port == "8088";
    }

    isNeedOutputTimeByActionBlock(actionBlock: ActionBlock): boolean {
        return true;
    }

    validateModel(model: Model): string | null {
        return null;
    }

    protected isFreezeObject(): boolean {
        return true;
    }

    getDynamicGlobalVariablesByActionBlock(actionBlock: ActionBlock): string[] {
        return [];
    }

}