import {RouteLocationNamedRaw, Router} from "vue-router";
import Dictionary from "~/ts/library/Dictionary";
import CoreUIAppStorage from "~/core-ui/ts/CoreUIAppStorage";

class MyRouter {
    private get router(): Router {
        return CoreUIAppStorage.getRouter();
    }

    private clearUnusedParams(routeName: string | symbol, params: Dictionary<any>): void {
        let routeConfigByName = this.router.getRoutes().find(item => item.name === routeName);
        if (routeConfigByName) {
            let path = routeConfigByName.path;
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    let placeholder = `:${key}`;
                    let hasParam = path.endsWith(placeholder) || path.endsWith(`${placeholder}?`) || path.includes(`/${placeholder}/`);
                    if (!hasParam) {
                        delete params[key];
                    }
                }
            }
        }
    }

    public prepareLocationBeforeRouterPush(location: RouteLocationNamedRaw): RouteLocationNamedRaw {
        if (location.params) {
            this.clearUnusedParams(location.name, location.params);
        }
        return location;
    }

    public push(location: RouteLocationNamedRaw | string) {
        if (typeof location == "object") {
            this.prepareLocationBeforeRouterPush(location);
        }
        this.router.push(location);
    }

    public replace(location: RouteLocationNamedRaw) {
        this.prepareLocationBeforeRouterPush(location);
        this.router.replace(location);
    }
}

let myRouter = new MyRouter();

export function useMyRouter() {
    return myRouter;
}