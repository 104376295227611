import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {PropType} from "vue";
import {EventParamsByAuth} from "~/cabinet/vue/client/events/EventParamsByAuth";
import AbstractActionDescription from "~/cabinet/ts/data/events/AbstractActionDescription";

export function useActionBlockPreviewProps<ActionDescriptionType extends AbstractActionDescription<any>>() {
    type getType<T> = T extends AbstractActionDescription<infer U> ? U : never;
    type Model = getType<ActionDescriptionType>;
    return {
        params: {
            type: Object as PropType<Model>,
            required: true
        },
        actionDescription: {
            type: Object as PropType<ActionDescriptionType>,
            required: true
        },
        contentVariables: {
            type: Array as PropType<ContentVariableInterface[]>
        },
        eventParamsByAuth: {
            type: Object as PropType<EventParamsByAuth>,
            required: true
        }
    }
}