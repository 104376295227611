import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "SideMenuCardSection"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    compact: { type: Boolean },
    disabled: { type: Boolean },
    clickListening: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

function emitClick() {
    if (!props.disabled) {
        emits('click');
    }
}


return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return _withDirectives((_openBlock(), _createBlock(ElCardSection, {
    class: _normalizeClass({
            'side-menu-card-section': true,
            'compact': _unref(props).compact,
            'clickable': !_ctx.disabled && !!_ctx.clickListening
        })
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"])), [
    [_directive_on_native, () => emitClick(), "click"]
  ])
}
}

})