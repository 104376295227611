import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {IconStyle} from "~/chat/ts/data/ISiteParams";
import Dictionary from "~/ts/library/Dictionary";

export const FIELD_TYPE_CHECKBOX = "checkbox";
export const FIELD_TYPE_RADIO = "radio";
export const FIELD_TYPE_SELECT = "select";
export const FIELD_TYPE_TEXT = "text";
export const FIELD_TYPE_TEXTAREA = "textarea";
export const FIELD_TYPE_INFO = "info";
export const FIELD_TYPE_SLIDER = "slider";
export const FIELD_TYPE_COLOR_PICKER = "colorpicker";
export const FIELD_SWITCH = "switch";
export const FIELD_IMAGE = "image";
export const FIELD_FILEMANAGER = "filemanager"
export const FIELD_CABINET_CAPTCHA = "cabinetCaptcha";
export const FIELD_TYPE_FILE_SHARE = "fileshare";
export const FIELD_TYPE_DATE = "date";
export const FIELD_TYPE_DATETIME = "datetime";
export const FIELD_TYPE_TIME = "time";
export const FIELD_TYPE_HIDDEN = "hidden";
export const FIELD_TYPE_LABEL = "label";
export const FIELD_TYPE_CHAT_BOT = "chatBot";
export const FIELD_TYPE_SEX = "sex";
export const FIELD_TYPE_MOBILE = "mobile";
export const FIELD_TYPE_EMAIL = "email";
export const FIELD_TYPE_INTEGER = "integer";
export const FIELD_TYPE_NUMBER = "number";
export const FIELD_TYPE_STARS = "stars";


export interface IField<TParams = Dictionary<any>> {
    type: string;
    name: string;
    descr?: string;
    icon?: string;
    iconStyle?: IconStyle;
    required?: boolean;
    optional?: boolean;
    descrFontWeightBold?: boolean;
    displayCondition?: {
        fieldName: string,
        values?: any[],
        notNull?: boolean
    },
    defaultValue?: any;
    isHtmlBeforeInputEnabled?: boolean;
    htmlBeforeInput?: string;
    helpUnderInput?: string;
    htmlUnderInput?: string;
    placeholder?: string;
    bbcode?: boolean;
    siteId?: string;
    updateDisabled?: boolean;
    readonly?: boolean;
    previousName?: string
    params?: TParams
}


export interface IForm {
    sections: IFormSection[];
}

export interface IFormSection {
    descr?: string;
    elements: IField[];
}

export interface IFieldWithOptions extends IField, IFieldWithOptionsMixin {

}

export interface ISelectField extends IFieldWithOptions {

}

export interface IFieldWithOptionsMixin {
    options: IOption[];
    optgroup: IOptionGroup[];
    placeholder?: string;
    lazyLoad?: {
        url: string,
        params: any
    };
}

export interface IInfoFieldMixin {
    info: string;
    showCopyButton?: boolean;
    showLikeInput?: boolean;
}


export interface ITextFieldMixin {
    placeholder?: string;
    suggest?: IOption[];
    readonly?: boolean;
    dimension?: string
}

export interface ICabinetCaptchaField extends IField, ITextFieldMixin {

}

export interface ITextareaFieldMixin extends ITextFieldMixin{
    upload: boolean;
    isHtml: boolean;
}

export interface ICheckboxField extends IFieldWithOptions, ITextFieldMixin {
    placeholder: string;
}

export interface IRadioboxField extends ICheckboxField {

}

export interface IOption {
    value: string | number;
    descr: string | number;
    default?: boolean;
    disabled?: boolean
    icon?: string;
    iconColor?: string;
    iconStyle?: IconStyle;
}

export interface IOptionGroup {
    id: string;
    descr: string;
    options: IOption[];
}

export interface IInfoField extends IField, IInfoFieldMixin {
}

export interface ITextField extends IField, ITextFieldMixin {
    isPassword?: boolean;
    maxLength?: number;
    prefix?: string;
    contentVariables?: ContentVariableInterface[];
}

export interface ITextareaField extends ITextField, ITextareaFieldMixin {

}


export interface ISliderField extends IField {
    min: number;
    max: number;
    defaultValue: number;
    step: number;
}

export interface IHiddenField extends IField {
    value: string;
}

export interface ILabelField extends IHiddenField {

}

export interface IFileManagerField extends IField {
    clearable: boolean;
    buttonText: string;
}

export interface ISwitchField extends IField {
    activeValue: any;
    inactiveValue: any;
    activeText?: string;
    inactiveText?: string;
    descrAfterSwitch?: string;
}

export interface IImageField extends IField {
    src?: string;
}

export interface IColorPickerField extends IField {

}

export interface IFileShareField extends IField {
    tag: string;
    longStore: boolean;
    multiple: boolean;
    accept?: string;
}

export interface MyFormExposeInterface {
    formToString: () => string
}

export interface MyFormSectionExposeInterface {
    sectionToString: () => string
}

export const SIMPLE_CABINET_CAPTCHA_FIELD: IField = {
    type: FIELD_CABINET_CAPTCHA,
    name: "captcha",
    descr: "Captcha"
};

