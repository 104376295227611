import {onBeforeRouteUpdate, RouteLocationNormalized, useRoute} from "vue-router";
import {inject, ref} from "vue";
import {OnlineChatSiteIsFreeProvide} from "~/cabinet/vue/client/online/OnlineChatProvides";
import useActivated from "~/ts/vuePlugins/useActivated";

export default function useOnlineChatComponent(p?: UseOnlineChatComponentParams) {
    p = p ?? {};
    let siteIsFree = inject(OnlineChatSiteIsFreeProvide, ref(false));
    let siteId = ref<string>();
    let isActivated = useActivated();

    function updateSiteId(route: RouteLocationNormalized) {
        if (isActivated.value) {
            if (siteId.value != route.params.siteId && route.params.siteId) {
                siteId.value = route.params.siteId as string;
            }
        }
    }

    onBeforeRouteUpdate((to, from, next) => {
        updateSiteId(to);
        if (p.onRouteUpdate) {
            p.onRouteUpdate(to, from);
        }
        next();
    });

    updateSiteId(useRoute());

    return {
        siteIsFree,
        siteId
    };
}

export interface UseOnlineChatComponentParams {
    onRouteUpdate?: (to: RouteLocationNormalized, from: RouteLocationNormalized) => void
}