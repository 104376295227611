import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
    OnlineChatMessageToClientActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {useActionBlockOutputProps} from "~/cabinet/vue/client/events/action/types/useActionBlockOutput";


const __default__ = {
    name: "OnlineChatMessageToClientBlockOutput"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useActionBlockOutputProps<OnlineChatMessageToClientActionDescription>()
},
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(InputWithVariablesPreview, {
      text: _unref(props).output.descr,
      variables: _ctx.contentVariables
    }, null, 8, ["text", "variables"])
  ]))
}
}

})