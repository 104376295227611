import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "side-menu-field-descr mb-1 d-flex" }
const _hoisted_2 = { class: "flex-fill" }
const _hoisted_3 = {
  key: 0,
  class: "pl-2"
}

import {
    DataProviderEntityFieldInterface,
    DataProviderEntityFieldModelInterface,
    DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_ENTITY_ID_INJECTION_KEY
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionParamInterface";
import SideMenuCardSection from "~/core-ui/vue/chat/sideMenu/SideMenuCardSection.vue";

import DataProviderEntityFieldForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntityFieldForm.vue";
import {computed, inject} from "vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderManualFormEntityFieldRow"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    field: {},
    model: {}
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit

let entityId = inject(DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_ENTITY_ID_INJECTION_KEY, computed(() => null));
let isEditForm = computed(() => !!entityId.value);

let isVisible = computed(() => props.model.operatorCanEditField || !props.model.hideFromOperator);

return (_ctx: any,_cache: any) => {
  return (_unref(isVisible))
    ? (_openBlock(), _createBlock(SideMenuCardSection, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.field.descr), 1),
            (_unref(isEditForm) && !_ctx.field.isRequired)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("a", {
                    tabindex: "",
                    class: "cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('remove')))
                  }, [
                    _createVNode(FontAwesomeIcon, { icon: "times" })
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(DataProviderEntityFieldForm, {
            field: _ctx.field,
            "content-variables": [],
            modelValue: _ctx.model.values,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.values) = $event)),
            readonly: _ctx.model.operatorCanEditField === false && !_unref(isEditForm)
          }, null, 8, ["field", "modelValue", "readonly"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})