import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, unref as _unref } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pl-3 pr-3"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  class: "flex-fill",
  style: {"word-break":"break-word"}
}
const _hoisted_4 = { class: "text-right pl-3 lh-1" }
const _hoisted_5 = { class: "d-flex min-width-0 pb-1 w-100 mw-100 counter-row" }
const _hoisted_6 = { class: "text-right pl-3" }

import SystemStateInterface from "~/cabinet/vue/layout/lcab/SystemStateInterface";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import {computed, ref} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";


const __default__ = {
    name: "SystemStateCounters"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    systemState: {}
  },
  setup(__props: any) {

let props = __props;

let currentRowLogDialog = ref<{
    visible: boolean,
    daemonName: string,
    className: string
}>();

let currentRowLogRequest = computed(() => {
    let dialog = currentRowLogDialog.value
    if (dialog?.visible) {
        return new ReactiveRequest<{ item: string, formattedLog?: string }>(new LcabApiRequest({
            url: `/api/superdealer/dev/php/getCurrentDaemonRowLog`,
            p: {
                daemonName: dialog.daemonName,
                className: dialog.className
            }
        }));
    }
    return null;
})

function onItemClick(daemonName: string, isClickable: boolean, className: string) {
    if (isClickable) {
        currentRowLogDialog.value = {
            visible: true,
            daemonName,
            className
        };
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, {
      class: "el-row-root",
      gutter: 20
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.systemState.queues, (item, key) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            key: item.descr,
            md: 12
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, null, {
                default: _withCtx(() => [
                  _createVNode(ElCardHeader, {
                    title: item.descr
                  }, null, 8, ["title"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.groups, (group) => {
                    return (_openBlock(), _createBlock(ElCardSection, {
                      key: group.className,
                      class: "pt-2 pb-2 pr-0 pl-0"
                    }, {
                      default: _withCtx(() => [
                        (group.count > 1)
                          ? (_openBlock(), _createElementBlock("h6", _hoisted_1, _toDisplayString(group.defaultName), 1))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.values, (value, key) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass({
                                'd-flex min-width-0 pb-1 pr-3 pl-3 w-100 mw-100 counter-row align-items-center': true,
                                'clickable': group.isLogCurrentRow.includes(key)
                            }),
                            onClick: ($event: any) => (onItemClick(key, group.isLogCurrentRow.includes(key), group.className))
                          }, [
                            _createElementVNode("div", _hoisted_3, [
                              (key.startsWith(group.defaultName+'_'))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(key.substr(group.defaultName.length + 1)), 1)
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(key), 1)
                                  ], 64)),
                              (typeof group.rps[key]  === 'number')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                    _createTextVNode(" (" + _toDisplayString(group.rps[key]) + "/sec) ", 1)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                              (value > group.criticalCount[key])
                                ? (_openBlock(), _createBlock(_component_el_tag, {
                                    key: 0,
                                    type: "danger",
                                    style: {"transform":"translateX(10px)"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(value), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(value), 1)
                                  ], 64))
                            ])
                          ], 10, _hoisted_2))
                        }), 256))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024),
              (key===1)
                ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(ElCardSection, { class: "pt-2 pb-2" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-fill" }, " Клиентов онлайн ", -1)),
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.systemState.currentOnline), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    (_unref(currentRowLogDialog) && _unref(currentRowLogDialog).visible)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          title: _unref(currentRowLogDialog).daemonName,
          modelValue: _unref(currentRowLogDialog).visible,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(currentRowLogDialog).visible) = $event)),
          "custom-class": "body-background",
          width: "1200px"
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(currentRowLogRequest).make()))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Обновить ")
              ])),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(ReactiveRequestLoader, { request: _unref(currentRowLogRequest) }, {
              default: _withCtx(() => [
                (_unref(currentRowLogRequest).data)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_unref(currentRowLogRequest).data.formattedLog)
                        ? (_openBlock(), _createBlock(_component_el_card, {
                            key: 0,
                            class: "mb-4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(ElCardHeader, { title: "Отформатированное значение" }),
                              _createVNode(ElCardSection, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("pre", null, _toDisplayString(_unref(currentRowLogRequest).data.formattedLog), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_el_card, null, {
                        default: _withCtx(() => [
                          _createVNode(ElCardHeader, { title: "Оригинал" }),
                          _createVNode(ElCardSection, null, {
                            default: _withCtx(() => [
                              _createElementVNode("pre", null, _toDisplayString(_unref(currentRowLogRequest).data.item), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["request"])
          ]),
          _: 1
        }, 8, ["title", "modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})