import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-4" }

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    AbstractDataProviderActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/AbstractDataProviderActionDescription";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "AbtractDataProviderActionBlockForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<AbstractDataProviderActionDescription<any>>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let frontendParams = props.actionDescription.frontendParams;

let _modelValue = _useModel<getActionDescriptionModelType<AbstractDataProviderActionDescription<any>>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => props.actionDescription.getDefaultModelValues());

return (_ctx: any,_cache: any) => {
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      "label-position": "left",
      "label-width": "200px"
    }, {
      default: _withCtx(() => [
        (_unref(props).actionDescription.dataProviderActionFormComponent)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h3", _hoisted_1, _toDisplayString(_unref(frontendParams).authDescr), 1),
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(props).actionDescription.dataProviderActionFormComponent), _mergeProps(_unref(props), {
                modelValue: _unref(model),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event))
              }), null, 16, ["modelValue"]))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})