import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex",
  style: {"max-width":"250px"}
}
const _hoisted_2 = { class: "pr-2 pl-2" }

import MyTimeSelectorInSeconds from "~/cabinet/vue/interface/form/MyTimeSelectorInSeconds.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    DelayActionDescription,
} from "~/cabinet/ts/data/events/ActionDescription";
import {
    DELAY_ACTION_TYPE_DELAY,
    DELAY_ACTION_TYPE_TIME_RANGE,
    IDelayCascadeBlockParam
} from "~/cabinet/vue/client/events/action/types/Interface";
import BigRadioFlexButton from "~/cabinet/vue/interface/button/BigRadioFlexButton.vue";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import MyTimePicker from "~/core-ui/vue/ui/MyTimePicker.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "DelayBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<DelayActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<DelayActionDescription>>(__props, "modelValue");
let model = useObjectModel<IDelayCascadeBlockParam>(
    _modelValue,
    () => ({
        delay: 10,
        timeRange: {
            start: "09:00:00",
            stop: "18:00:00"
        },
        type: DELAY_ACTION_TYPE_DELAY
    })
);

return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "Тип задержки" }, {
      default: _withCtx(() => [
        _createVNode(BigIconFlexButtonWrapper, null, {
          default: _withCtx(() => [
            _createVNode(BigRadioFlexButton, {
              label: _unref(DELAY_ACTION_TYPE_DELAY),
              descr: "Фиксированная задержка",
              "extra-descr": "Задержка на заданное количество секунд",
              modelValue: _unref(model).type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).type) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(BigRadioFlexButton, {
              label: _unref(DELAY_ACTION_TYPE_TIME_RANGE),
              descr: "Диапазон времени",
              "extra-descr": "Ожидание наступления заданного диапазона времени",
              modelValue: _unref(model).type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).type) = $event))
            }, null, 8, ["label", "modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_unref(model).type == _unref(DELAY_ACTION_TYPE_DELAY))
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "Задержка"
        }, {
          default: _withCtx(() => [
            _createVNode(MyTimeSelectorInSeconds, {
              modelValue: _unref(model).delay,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).delay) = $event)),
              min: 1
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : (_unref(model).type == _unref(DELAY_ACTION_TYPE_TIME_RANGE))
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "Диапазон времени"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(MyTimePicker, {
                  modelValue: _unref(model).timeRange.start,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).timeRange.start) = $event)),
                  class: "flex-fill"
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(FontAwesomeIcon, { icon: "ellipsis-h" })
                ]),
                _createVNode(MyTimePicker, {
                  modelValue: _unref(model).timeRange.stop,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).timeRange.stop) = $event)),
                  class: "flex-fill"
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(HelpBlock, null, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("p", null, " Сценарий продолжит выполнение, если в текущий момент время находится в заданном диапазоне. ", -1),
                  _createElementVNode("p", null, " Иначе, выполнение сценария приостановится до тех пор, пока не наступит время, указанное в начале диапазона. ", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
    _createVNode(HelpBlock, null, {
      default: _withCtx(() => _cache[6] || (_cache[6] = [
        _createElementVNode("p", null, "Задержка помогает на время приостановить исполнение алгоритма для конкретного получателя.", -1),
        _createElementVNode("p", null, "Подключите этот блок к любому выходу предыдущего блока, а к выходу блока \"Задержка\" подключите следующий блок, который должен будет исполниться спустя заданное время задержки.", -1)
      ])),
      _: 1
    })
  ]))
}
}

})