import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createBlock as _createBlock } from "vue"

import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import CabinetCaptchaElement from "~/cabinet/vue/interface/form/elements/CabinetCaptchaElement.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import Dictionary from "~/ts/library/Dictionary";
import {ref} from "vue";
import {SIMPLE_CABINET_CAPTCHA_FIELD} from "~/cabinet/vue/interface/form/elements/Interfaces";


const __default__ = {
    name: "IpBlockDisableDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    authFormModel: {},
    errorDescr: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["done"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let visible = _useModel<boolean>(__props, "visible");

let ipBlockDialog = ref<{
    errorDescr: string,
    displayMessageCode: boolean,
    form: {
        contact: string,
        captcha: string,
        code: string
    }
}>({
    errorDescr: props.errorDescr,
    displayMessageCode: false,
    form: {
        captcha: null,
        contact: null,
        code: null
    }
});

let captchaField = SIMPLE_CABINET_CAPTCHA_FIELD;

async function sendIpBlockMessage() {
    let result = await LcabApiRequest.save({
        url: "/api/auth/sendIpBlockMessage",
        p: {
            ...props.authFormModel,
            ...ipBlockDialog.value.form
        }
    });
    if (result.isSuccess) {
        ipBlockDialog.value.displayMessageCode = true;
    }
}

async function removeIpBlock() {
    let result = await LcabApiRequest.save({
        url: "/api/auth/removeIpBlock",
        p: {
            ...props.authFormModel,
            ...ipBlockDialog.value.form
        }
    });
    if (result.isSuccess) {
        emits("done");
        close();
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_unref(visible))
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modal: false,
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
        title: "Снятие блокировки по IP",
        width: "500px"
      }, {
        default: _withCtx(() => [
          _createVNode(HelpBlock, { danger: "" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(ipBlockDialog).errorDescr), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form, { "label-position": "top" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, null, {
                label: _withCtx(() => [
                  _createTextVNode(" Введите номер телефона или Email привязанный к логину " + _toDisplayString(_ctx.authFormModel.login) + " для того, чтобы получить сообщение, позволяющее отключить блокировку по IP. ", 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(ipBlockDialog).form.contact,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(ipBlockDialog).form.contact) = $event)),
                    disabled: _unref(ipBlockDialog).displayMessageCode
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              (!_unref(ipBlockDialog).displayMessageCode)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_el_form_item, { label: "Введите код с картинки" }, {
                      default: _withCtx(() => [
                        _createVNode(CabinetCaptchaElement, {
                          modelValue: _unref(ipBlockDialog).form.captcha,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(ipBlockDialog).form.captcha) = $event)),
                          field: _unref(captchaField)
                        }, null, 8, ["modelValue", "field"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: sendIpBlockMessage
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" Отправить сообщение ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_el_form_item, { label: "Введите проверочный код из отправленного вам сообщения" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _unref(ipBlockDialog).form.code,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(ipBlockDialog).form.code) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: removeIpBlock
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" Снять блокировку ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})