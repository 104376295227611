import CoreUI from "~/core-ui/ts/CoreUI";
import App from "~/cabinet/vue/layout/Cabinet.vue";
import router from "../routes/Router";
import {IInitialAppData} from "~/cabinet/ts/store/AccountStore";
//import {Event} from '@sentry/types/dist';
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import SetInitialAppData from "~/cabinet/ts/initialize/SetInitialAppData";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import "~/cabinet/scss/index.scss";
import {ElAside, ElContainer, ElFooter, ElHeader, ElMain} from "element-plus";
import {setInitialUrl} from "~/cabinet/ts/InitialUrl";
import OperatorApiRequestFactory from "~/operatorApplication/ts/request/OperatorApiRequestFactory";
import OperatorApiRequestFromCabinet from "~/operatorApplication/ts/request/OperatorApiRequestFromCabinet";
import LcabApiRequestFactory from "~/cabinet/ts/api/LcabApiRequestFactory";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {
    AbTestActionDescription,
    CabinetNotificationActionDescription,
    CallActionDescription,
    ContactbookGroupActionDescription,
    DelayActionDescription,
    DISCOUNT_COUPON_ACTION_DESCRIPTION_ID,
    DiscountCouponActionDescription,
    EventAsActionDescription,
    FilterActionDescription,
    GLOBAL_VARS_ACTION_DESCRIPTION_ID,
    GlobalVarsActionDescription,
    NoteActionDescription,
    ONLINE_CHAT_MESSAGE_FOR_CLIENT_ACTION_DESCRIPTION_ID,
    ONLINE_CHAT_REQUEST_RATE_ACTION_DESCRIPTION_ID,
    OnlineChatClientOpenWidgetTabActionDescription,
    OnlineChatClientRedirectActionDescription,
    OnlineChatForwardClientToOperatorActionDescription,
    OnlineChatMessageToClientActionDescription,
    OnlineChatMessageToOperatorActionDescription,
    OnlineChatRequestRateActionDescription,
    OnlineChatRunExternalBotActionDescription,
    PhonebookActionWithGroupActionDescription,
    SenderActionDescription,
    SendNotificationToClientOnlineChatOperatorActionDescription,
    TargetActionDescription,
    ToggleTagActionDescription,
    WEBHOOK_ACTION_DESCRIPTION_ID,
    WebhookActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import AbstractActionDescription from "~/cabinet/ts/data/events/AbstractActionDescription";
import {
    DataProviderEntitySaveActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/DataProviderEntitySaveActionDescription";
import {
    CrmSendMessageActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/CrmSendMessageActionDescription";
import UploadHelperFactory from "~/ts/library/UploadHelperFactory";
import CabinetUploadHelper from "~/cabinet/ts/service/CabinetUploadHelper";
import {
    DataProviderEntitySearchActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/DataProviderEntitySearchActionDescription";

setInitialUrl();

OperatorApiRequestFactory.setClass(OperatorApiRequestFromCabinet);
LcabApiRequestFactory.setClass(LcabApiRequest);
UploadHelperFactory.setClass(CabinetUploadHelper);

AbstractActionDescription.setDiscriminator({
    sender: SenderActionDescription,
    [WEBHOOK_ACTION_DESCRIPTION_ID]: WebhookActionDescription,
    [DISCOUNT_COUPON_ACTION_DESCRIPTION_ID]: DiscountCouponActionDescription,
    delay: DelayActionDescription,
    target: TargetActionDescription,
    note: NoteActionDescription,
    filter: FilterActionDescription,
    abtest: AbTestActionDescription,
    call: CallActionDescription,
    cabinetNotification: CabinetNotificationActionDescription,
    onlineChatMessage: OnlineChatMessageToOperatorActionDescription,
    [ONLINE_CHAT_MESSAGE_FOR_CLIENT_ACTION_DESCRIPTION_ID]: OnlineChatMessageToClientActionDescription,
    [ONLINE_CHAT_REQUEST_RATE_ACTION_DESCRIPTION_ID]: OnlineChatRequestRateActionDescription,
    onlineChatForwardClientToOperator: OnlineChatForwardClientToOperatorActionDescription,
    phonebookActionWithGroupSelect: PhonebookActionWithGroupActionDescription,
    onlineChatRedirect: OnlineChatClientRedirectActionDescription,
    onlineChatOpenTab: OnlineChatClientOpenWidgetTabActionDescription,
    //crm: CrmActionDescription,
    eventAsAction: EventAsActionDescription,
    sendNotificationToClientOnlineChatOperator: SendNotificationToClientOnlineChatOperatorActionDescription,
    [GLOBAL_VARS_ACTION_DESCRIPTION_ID]: GlobalVarsActionDescription,
    contactbookAddToGroup: ContactbookGroupActionDescription,
    contactbookRemoveFromGroup: ContactbookGroupActionDescription,
    dataProviderEntitySave: DataProviderEntitySaveActionDescription,
    dataProviderEntitySearch: DataProviderEntitySearchActionDescription,
    crmSendMessage: CrmSendMessageActionDescription,
    toggleTags: ToggleTagActionDescription,
    onlineChatRunExternalBot: OnlineChatRunExternalBotActionDescription
})

let meta: HTMLMetaElement = document.querySelector("meta[name='csrf-token']");
if (meta) {
    let csrfTokenFromMeta = meta.content;
    if (csrfTokenFromMeta && csrfTokenFromMeta.length) {
        CsrfToken.set(csrfTokenFromMeta);
    }
}

let container = document.getElementById("app").getElementsByTagName("App")[0];
if (container) {
    let initAttribute = container.getAttribute("data");
    if (initAttribute) {
        let initData: IInitialAppData = JSON.parse(initAttribute);
        SetInitialAppData(initData);
    }
}
let $router = router();


LcabLayoutStore.router.value = $router;
let coreUI = new CoreUI(App, $router);
/*
if (window.location.port != "8088") {
    coreUI.setupSentry("https://8b657de41b0c4f77bcb8a047276fa396@o384149.ingest.sentry.io/6168798", (event: Event) => {
        let organization = AccountStore.organization.value;
        if (organization) {
            event.user = {
                ...(event.user ? event.user : {}),
                username: organization.descr,
                id: organization.ocode,
            };

            event.tags = {
                ...(event.tags ? event.tags : {}),
                dealerId: organization.dealerId,
                isDealer: organization.isDealer ? "true" : "false"
            };
        }


        return event;
    });
}
*/


coreUI.createApp(() => {

}, app => {
    app
        .component("el-container", ElContainer)
        .component("el-main", ElMain)
        .component("el-header", ElHeader)
        .component("el-footer", ElFooter)
        .component("el-aside", ElAside);
});

export default () => {
};