import {RouteLocationNamedRaw, RouteRecordRaw} from "vue-router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import RouteHelper from "~/core-ui/ts/router/RouteHelper";
import PublicLayout from "~/cabinet/vue/layout/public/Layout.vue";
import {DASHBOARD_ID} from "~/cabinet/ts/routes/DashboardRouteBuilder";

export const PUBLIC_WEBHOOK_DOC_ROUTE_NAME = "publicWebhookDocumentation";
export const PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME = "publicOnlineChatWidgetDocumentation";


export function getWebhookDocumentationLocation(platformId: string): RouteLocationNamedRaw {
    return {
        name: PUBLIC_WEBHOOK_DOC_ROUTE_NAME,
        params: {
            platformId
        }
    }
}

export function getJavascriptDocumentationLocation(methodId: string): RouteLocationNamedRaw {
    return {
        name: PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME,
        params: {
            sectionId: methodId
        }
    }
}

export default class PublicRoutesBuilder {
    public make(): RouteRecordRaw[] {
        let result: RouteRecordRaw[] = [
            this.getOperatorApplicationBuddyListFilters(),
            {
                name: "PUBLIC_ROOT_ROUTE",
                path: "/public",
                component: PublicLayout,
                meta: {
                    guestOrUser: true
                } as IRouteMeta,
                children: [
                    this.getOnlineRenewLicense(),
                    this.getTarifs(),
                    this.getDocumentation(),
                    this.getApplication(),
                    this.getDashboardAnonymousLink(),

                ]
            }
        ];
        for (let item of result) {
            RouteHelper.setDefaultRouteComponents(item, true);
        }

        return result;
    }

    private getOperatorApplicationBuddyListFilters(): RouteRecordRaw {
        return {
            path: "/public/mobileOperatorApplication/buddyListSearchFilters",
            component: () => import(/* webpackChunkName: "operator-application-buddy-list-filters" */ '~/cabinet/vue/public/online/operatorApplication/buddyListFilters/MobileOperatorApplicationBuddyListFilters.vue'),
            meta: {
                guestOrUser: true
            } as IRouteMeta,
        }
    }

    private getOnlineRenewLicense(): RouteRecordRaw {
        return {
            name: "PUBLIC_ONLINE_RENEW_LICENSE",
            path: "online/renewLicense",
            component: () => import(/* webpackChunkName: "online-renew-license" */ '~/cabinet/vue/public/online/renewLicense/RenewLicense.vue'),
        }
    }

    private getTarifs(): RouteRecordRaw {
        return {
            name: "PUBLIC_TARIFS",
            path: "tarifs",
            children: [
                {
                    path: ":uslugaId/:ocode?",
                    component: () => import(/* webpackChunkName: "public-tarifs" */ '~/cabinet/vue/public/tarifs/PublicTarifs.vue'),
                    props: route => ({
                        uslugaId: route.params.uslugaId,
                        ocode: route.params.ocode
                    })
                }
            ]
        }
    }

    private getDashboardAnonymousLink(): RouteRecordRaw {
        const name = "PUBLIC_ANONYMOUS_DASHBOARD_LINK";
        const dashboardName = `${name}_DASHBOARD`;
        return {
            name,
            path: "dashboard/:linkId",
            component: () => import(/* webpackChunkName: "dashboard" */ '~/cabinet/vue/client/dashboard/AnonymousDashboardLink.vue'),
            props: (route) => ({
                linkId: route.params.linkId,
                getRawLocationByDashboardId: (dashboardId: string) => {
                    return {
                        name: dashboardName,
                        params: {
                            linkId: route.params.linkId as string,
                            [DASHBOARD_ID]: dashboardId as string
                        }
                    } as RouteLocationNamedRaw;
                }
            }),
            children: [
                {
                    name: dashboardName,
                    path: `:${DASHBOARD_ID}`,
                    props: route => ({
                        linkId: route.params.linkId as string,
                        [DASHBOARD_ID]: route.params[DASHBOARD_ID] as string
                    })
                } as RouteRecordRaw
            ]
        }
    }

    private getDocumentation(): RouteRecordRaw {
        return {
            path: 'documentation',
            meta: {guestOrUser: true} as IRouteMeta,
            children: [
                {
                    name: PUBLIC_WEBHOOK_DOC_ROUTE_NAME,
                    path: 'webhook/:platformId/:sectionId?',
                    component: () => import(/* webpackChunkName: "webhook-doc" */ '~/cabinet/vue/public/documentation/WebhookDocumentation.vue'),
                    props: route => ({
                        platformId: route.params.platformId as string,
                        sectionId: route.params.sectionId as string
                    })
                },

                {
                    name: PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME,
                    path: 'onlineChatWidget/:sectionId?',
                    component: () => import(/* webpackChunkName: "online-chat-widget-doc" */ '~/cabinet/vue/public/documentation/OnlineChatWidgetDocumentation.vue'),
                    props: route => ({
                        sectionId: route.params.sectionId as string
                    })
                }
            ]
        }
    }

    private getApplication(): RouteRecordRaw {
        return {
            path: 'app',
            children: [
                {
                    name: "PUBLIC_OPEN_CLIENT_IN_APPLICATION",
                    path: 'openClient/:username',
                    component: () => import(/* webpackChunkName: "public-client-in-application" */ '~/cabinet/vue/public/application/OpenClientInApplication.vue'),
                    props: route => ({
                        username: route.params.username as string
                    })
                },
            ]
        }
    }
}