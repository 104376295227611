import {
    CrmSendMessageActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/crm/sendMessage/CrmSendMessageActionParamInterface";
import {Component} from "vue";
import CrmSendMessageActionBlockForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/crm/sendMessage/CrmSendMessageActionBlockForm.vue";
import {
    AbstractDataProviderActionDescriptionFrontendParamsInterface
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/Interface";
import {
    AbstractDataProviderActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/AbstractDataProviderActionDescription";

interface CrmSendMessageActionDescriptionFrontendParamsInterface extends AbstractDataProviderActionDescriptionFrontendParamsInterface {
    isFromClient: boolean
}

export class CrmSendMessageActionDescription extends AbstractDataProviderActionDescription<CrmSendMessageActionParamInterface, CrmSendMessageActionDescriptionFrontendParamsInterface> {
    get dataProviderActionFormComponent(): Component {
        return CrmSendMessageActionBlockForm;
    }

    /*
    get dataProviderActionPreviewComponent(): Component {
        return null;
    }
    */

    getDefaultModelValues(): CrmSendMessageActionParamInterface {
        return {
            ...super.getAbstractDefaultModelValues(),
            text: null,
            additionalParams: null
        };
    }
}
