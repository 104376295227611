import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }

import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {
    OnlineChatMessageToClientActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import BigRadioFlexButton from "~/cabinet/vue/interface/button/BigRadioFlexButton.vue";
import {
    ONLINE_CHAT_MESSAGE_TO_CLIENT_SEND_ERROR_MESSAGE_AND_WAIT_FOR_VALID_ANSWER_ACTION_ID
} from "~/cabinet/vue/client/events/action/types/Interface";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import AttachmentsConfig from "~/cabinet/vue/interface/AttachmentsConfig.vue";
import CabinetUploadHelper from "~/cabinet/ts/service/CabinetUploadHelper";
import MyFilter from "~/cabinet/vue/interface/filter/MyFilter.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import OnlineChatMessageFormPinParams
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/OnlineChatMessageFormPinParams.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "OnlineChatMessageToOperatorForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<OnlineChatMessageToClientActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let operatorTypes = computed(() => {
    return props.actionDescription.frontendParams.operatorTypes;
});

const sendErrorMessageAndWaitForValidAnswerActionId = ONLINE_CHAT_MESSAGE_TO_CLIENT_SEND_ERROR_MESSAGE_AND_WAIT_FOR_VALID_ANSWER_ACTION_ID;

let _modelValue = _useModel<getActionDescriptionModelType<OnlineChatMessageToClientActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    text: null,
    countInDialogStatistic: false,
    operatorLogin: null,
    operatorType: operatorTypes.value.length ? operatorTypes.value[0].value as string : null,
    virtualOperatorLogin: null,
    answerVariants: [""],
    markPreviousMessagesAsReaded: false,
    waitingForClientAnswer: false,
    useAnswerVariants: true,
    messageOnValidateError: null,
    messageOnValidateErrorAttachments: [],
    actionOnValidateError: sendErrorMessageAndWaitForValidAnswerActionId,
    saveAnswerAsField: null,
    attachments: [],
    answerFilters: {},
    withFilters: false,
    pinMessage: false,
    unpinOtherMessages: false,
    disableTextInput: false,
    pinMessageForClient: false,
    unpinOtherMessagesForClient: false
}), model => {
    if (!model.value.answerFilters) {
        model.value.answerFilters = {};
    }
});


let frontendParams = computed(() => {
    return props.actionDescription.frontendParams;
});


const answerTypes = [
    {
        value: false,
        descr: "Произвольный текст",
        extraDescr: "Клиент сам вводит текст ответа"
    },
    {
        value: true,
        descr: "Выбор из вариантов ответа",
        extraDescr: "Клиенту отобразятся кнопки с вариантами ответа"
    }
];

let isAnswerFiltersUsed = computed(() => props.actionDescription.isAnswerFiltersUsed(model.value));

const uploadHelper = new CabinetUploadHelper();

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-position": "top",
    "label-width": "200px",
    model: _unref(model)
  }, {
    default: _withCtx(() => [
      (_unref(operatorTypes).length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_el_form_item, {
              label: "Отправитель сообщения",
              prop: "operatorType",
              rules: _unref(requiredRule)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _unref(model).operatorType,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).operatorType) = $event)),
                  class: "w-100"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operatorTypes), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        value: item.value,
                        label: item.descr
                      }, null, 8, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["rules"]),
            (_unref(model).operatorType === 'selectedOperator')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: "Оператор",
                  prop: "operatorLogin",
                  rules: _unref(requiredRule)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _unref(model).operatorLogin,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).operatorLogin) = $event)),
                      multiple: "",
                      class: "w-100",
                      placeholder: "Выберите оператора"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).operators, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            value: item.value,
                            label: item.descr
                          }, null, 8, ["value", "label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["rules"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_el_form_item, {
        rules: _unref(requiredRule),
        prop: "text",
        label: "Текст сообщения"
      }, {
        default: _withCtx(() => [
          _createVNode(TextInputWithVariables, {
            placeholder: "Введите текст сообщения",
            modelValue: _unref(model).text,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).text) = $event)),
            "content-variables": _ctx.contentVariables,
            bbcode: ""
          }, null, 8, ["modelValue", "content-variables"])
        ]),
        _: 1
      }, 8, ["rules"]),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, { class: "transparent-dashed-card" }, {
            default: _withCtx(() => [
              _createVNode(AttachmentsConfig, {
                modelValue: _unref(model).attachments,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).attachments) = $event)),
                "upload-helper": _unref(uploadHelper)
              }, null, 8, ["modelValue", "upload-helper"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.isInScheme)
        ? (_openBlock(), _createBlock(_component_el_card, {
            key: 1,
            class: "transparent-dashed-card"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_switch, {
                    modelValue: _unref(model).waitingForClientAnswer,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).waitingForClientAnswer) = $event)),
                    class: "mr-2"
                  }, null, 8, ["modelValue"]),
                  _cache[15] || (_cache[15] = _createTextVNode(" Ожидать ответа посетителя "))
                ]),
                _: 1
              }),
              (_unref(model).waitingForClientAnswer)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(BigIconFlexButtonWrapper, null, {
                          default: _withCtx(() => [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(answerTypes, (type) => {
                              return _createVNode(BigRadioFlexButton, {
                                key: type.descr,
                                modelValue: _unref(model).useAnswerVariants,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).useAnswerVariants) = $event)),
                                label: type.value,
                                descr: type.descr,
                                "extra-descr": type.extraDescr,
                                style: {"min-width":"50%"}
                              }, null, 8, ["modelValue", "label", "descr", "extra-descr"])
                            }), 64))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_unref(model).useAnswerVariants)
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          label: "Варианты ответа"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(MyInputList, {
                              modelValue: _unref(model).answerVariants,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(model).answerVariants) = $event)),
                              sortable: "",
                              "remove-last-available": false
                            }, {
                              default: _withCtx(({index}) => [
                                _createVNode(TextInputWithVariables, {
                                  modelValue: _unref(model).answerVariants[index],
                                  "onUpdate:modelValue": ($event: any) => ((_unref(model).answerVariants[index]) = $event),
                                  "content-variables": _ctx.contentVariables,
                                  multiline: false,
                                  class: "lh-0"
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "content-variables"])
                              ]),
                              _: 1
                            }, 8, ["modelValue"]),
                            _createElementVNode("div", _hoisted_1, [
                              _createVNode(_component_el_switch, {
                                modelValue: _unref(model).disableTextInput,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(model).disableTextInput) = $event)),
                                class: "mr-2"
                              }, null, 8, ["modelValue"]),
                              _cache[17] || (_cache[17] = _createTextVNode(" Блокировать поле для ввода текста ")),
                              _createVNode(HelpBlock, null, {
                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                  _createTextVNode(" Эта опция доступна только для бота, запущенного в виджете на вашем сайте ")
                                ])),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_el_form_item, { label: "Фильтры ответа" }, {
                            default: _withCtx(() => [
                              _createVNode(MyFilter, {
                                modelValue: _unref(model).answerFilters,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(model).answerFilters) = $event)),
                                "filters-data": _unref(frontendParams).answerFilters,
                                placeholder: "Добавьте, если требуется проверка ответа на корректность"
                              }, null, 8, ["modelValue", "filters-data"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_form_item, {
                            label: `Сохранить ${_unref(isAnswerFiltersUsed) ? 'корректный' : ''} ответ в информацию о клиенте`
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: _unref(model).saveAnswerAsField,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(model).saveAnswerAsField) = $event)),
                                clearable: "",
                                class: "w-100"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).fields, (field) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: field.name,
                                      value: field.name,
                                      label: field.descr
                                    }, null, 8, ["value", "label"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          (_unref(isAnswerFiltersUsed))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_component_el_form_item, { label: "Действие при некорректном ответе:" }, {
                                  default: _withCtx(() => [
                                    _createVNode(BigIconFlexButtonWrapper, null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).actionsOnInvalidAnswer, (descr, actionId) => {
                                          return (_openBlock(), _createBlock(BigRadioFlexButton, {
                                            key: descr,
                                            modelValue: _unref(model).actionOnValidateError,
                                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(model).actionOnValidateError) = $event)),
                                            label: actionId,
                                            descr: descr,
                                            style: {"min-width":"50%"}
                                          }, null, 8, ["modelValue", "label", "descr"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                (_unref(model).actionOnValidateError === _unref(sendErrorMessageAndWaitForValidAnswerActionId))
                                  ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createVNode(TextInputWithVariables, {
                                          placeholder: "Введите текст сообщения",
                                          modelValue: _unref(model).messageOnValidateError,
                                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(model).messageOnValidateError) = $event)),
                                          "content-variables": _ctx.contentVariables,
                                          "left-span": 12,
                                          bbcode: ""
                                        }, {
                                          left: _withCtx(() => _cache[18] || (_cache[18] = [
                                            _createTextVNode(" Сообщение при неправильном вводе данных ")
                                          ])),
                                          _: 1
                                        }, 8, ["modelValue", "content-variables"]),
                                        _createVNode(_component_el_card, { class: "transparent-dashed-card mt-3" }, {
                                          default: _withCtx(() => [
                                            _createVNode(AttachmentsConfig, {
                                              modelValue: _unref(model).messageOnValidateErrorAttachments,
                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(model).messageOnValidateErrorAttachments) = $event)),
                                              "upload-helper": _unref(uploadHelper)
                                            }, null, 8, ["modelValue", "upload-helper"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                        ], 64))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(operatorTypes).length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _unref(model).countInDialogStatistic,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(model).countInDialogStatistic) = $event)),
                  class: "mr-2"
                }, null, 8, ["modelValue"]),
                _cache[19] || (_cache[19] = _createTextVNode(" Учитывать этот ответ в статистике операторов "))
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _unref(model).markPreviousMessagesAsReaded,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(model).markPreviousMessagesAsReaded) = $event)),
                  class: "mr-2"
                }, null, 8, ["modelValue"]),
                _cache[20] || (_cache[20] = _createTextVNode(" Помечать предыдущие сообщения клиента прочитанными для оператора, от имени которого будет отправлено сообщение "))
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(OnlineChatMessageFormPinParams, { value: _unref(model) }, null, 8, ["value"])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})