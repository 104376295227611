import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import {Highlight} from "~/core-ui/ts/InitPrismJs";
import {computed} from "vue";


const __default__ = {
    name: "Prism"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    language: {},
    code: {},
    preWrap: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

var language = props.language;
var prismLanguage = window.Prism.languages[language];
var className = "language-".concat(language);

if (process.env.NODE_ENV === 'development' && !prismLanguage) {
    throw new Error("Prism component for language \"".concat(language, "\" was not found, did you forget to register it? See all available ones: https://cdn.jsdelivr.net/npm/prismjs/components/"));
}

let html = computed(() => {
    return Highlight(props.code, prismLanguage);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("pre", {
    class: _normalizeClass({prism: true, 'pre-wrap': _ctx.preWrap, [_unref(className)]: true})
  }, [
    _createElementVNode("code", {
      innerHTML: _unref(html),
      class: _normalizeClass({[_unref(className)]: true})
    }, null, 10, _hoisted_1)
  ], 2))
}
}

})