import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

import {IFileShareField} from "../Interfaces";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";



const __default__ = {
    name: "FileShareElementConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let isDealer = computed(() => AccountStore.isDealer);

let _modelValue = _useModel<IFileShareField>(__props, "modelValue");
let model = useObjectModel<Partial<IFileShareField>>(_modelValue, () => ({
    longStore: false,
    tag: null,
    multiple: false,
    accept: null
}));

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(isDealer))
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "Требуется долгосрочное хранение"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_switch, {
              modelValue: _unref(model).longStore,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).longStore) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form_item, { label: "Разрешено загружать несколько файлов" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_switch, {
          modelValue: _unref(model).multiple,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).multiple) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "Допустимые расширения файлов, через запятую" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _unref(model).accept,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).accept) = $event)),
          placeholder: "Необязательно. Например: pdf, docx, png"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})