import {Component} from "vue";
import {
    DataProviderEntitySearchActionDescriptionFrontendParamsInterface
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/Interface";
import AbstractDataProviderEntityActionDescription
    from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/AbstractDataProviderEntityActionDescription";
import {
    DataProviderEntitySearchActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/search/DataProviderEntitySearchActionParamInterface";
import DataProviderEntitySearchActionBlockForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/search/DataProviderEntitySearchActionBlockForm.vue";
import ActionBlock from "~/cabinet/ts/data/events/ActionBlock";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";


export class DataProviderEntitySearchActionDescription extends AbstractDataProviderEntityActionDescription<DataProviderEntitySearchActionParamInterface, DataProviderEntitySearchActionDescriptionFrontendParamsInterface> {
    get dataProviderActionFormComponent(): Component {
        return DataProviderEntitySearchActionBlockForm;
    }

    /*
    get dataProviderActionPreviewComponent(): Component {
        return DataProviderEntitySaveActionBlockPreview;
    }
    */

    getDefaultModelValues(): DataProviderEntitySearchActionParamInterface {
        return {
            ...super.getAbstractDefaultModelValues(),
            ...this.frontendParams.defaultModel
        };
    }

    getDynamicGlobalVariablesByActionBlock(actionBlock: ActionBlock): string[] {
        let result: string[] = [];
        let params: DataProviderEntitySearchActionParamInterface = actionBlock.param as any;
        if (params?.globalVariables?.enabled) {
            let variables = params.globalVariables.list;
            if (variables) {
                for (let item of variables) {
                    let variable = item.descr;
                    if (variable?.length > 0) {
                        toggleArrayElement(result, variable, true);
                    }
                }
            }
        }
        return result;
    }
}