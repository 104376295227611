import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-muted"
}
const _hoisted_2 = {
  key: 1,
  class: "text-muted"
}
const _hoisted_3 = {
  key: 1,
  class: "text-muted"
}

import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {OnlineChatMessageToClientActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import ActionBlockPreviewItem from "~/cabinet/vue/client/events/action/types/ActionBlockPreviewItem.vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import MyFilterPreview from "~/cabinet/vue/interface/filter/MyFilterPreview.vue";
import {
    useActionBlockPreviewProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";


const __default__ = {
    name: "OnlineChatMessageToOperatorBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<OnlineChatMessageToClientActionDescription>()
    },
  setup(__props) {

let props = __props;

let operatorTypes = computed(() => {
    return props.actionDescription.frontendParams.operatorTypes;
});

let frontendParams = computed(() => {
    return props.actionDescription.frontendParams;
});

let senderType = computed(() => {
    return operatorTypes.value.find(option => option.value === props.params.operatorType);
});

let operator = computed(() => {
    return props.actionDescription.frontendParams.operators
        .filter(option => props.params.operatorLogin.includes(option.value as string))
        .map(option => option.descr)
        .join(", ");
});

let saveAnswerAs = computed(() => {
    if (props.params.waitingForClientAnswer && !props.params.useAnswerVariants && props.params.saveAnswerAsField) {
        let field = props.actionDescription.frontendParams.fields.find(field => field.name == props.params.saveAnswerAsField);
        return field ? field.descr : props.params.saveAnswerAsField;
    }
    return null;
});

let answerFilters = computed(() => {
    if (!props.params.withFilters) {
        props.params.withFilters = true;
    }
    if (!props.params.useAnswerVariants && props.params.waitingForClientAnswer) {
        return ObjectHelper.hasKeys(props.params.answerFilters) ? props.params.answerFilters : null;
    }
    return null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(operatorTypes).length)
      ? (_openBlock(), _createBlock(ActionBlockPreviewItem, {
          key: 0,
          label: "Отправитель"
        }, {
          default: _withCtx(() => [
            (_unref(senderType))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_unref(senderType).value === 'selectedOperator')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_unref(operator) && _unref(operator).length)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_unref(operator)), 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock("span", _hoisted_1, "Не задан"))
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_unref(senderType).descr), 1)
                      ], 64))
                ], 64))
              : (_openBlock(), _createElementBlock("span", _hoisted_2, "Не задан"))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(ActionBlockPreviewItem, { label: "Текст" }, {
      default: _withCtx(() => [
        (_ctx.params.text != null)
          ? (_openBlock(), _createBlock(InputWithVariablesPreview, {
              key: 0,
              text: _ctx.params.text,
              variables: _ctx.contentVariables,
              bbcode: ""
            }, null, 8, ["text", "variables"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, " Текст не задан "))
      ]),
      _: 1
    }),
    (_unref(answerFilters))
      ? (_openBlock(), _createBlock(ActionBlockPreviewItem, {
          key: 1,
          label: "Фильтры ответа"
        }, {
          default: _withCtx(() => [
            _createVNode(MyFilterPreview, {
              value: _unref(answerFilters),
              editable: false,
              "filters-data": _unref(frontendParams).answerFilters,
              class: "p-0 m-0"
            }, null, 8, ["value", "filters-data"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(saveAnswerAs))
      ? (_openBlock(), _createBlock(ActionBlockPreviewItem, {
          key: 2,
          label: "Сохранять ответ как"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(saveAnswerAs)), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(operatorTypes).length)
      ? (_openBlock(), _createBlock(ActionBlockPreviewItem, { key: 3 }, {
          label: _withCtx(() => [
            (_ctx.params.countInDialogStatistic)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(" Учитывать ")
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" Не учитывать ")
                ], 64)),
            _cache[0] || (_cache[0] = _createTextVNode(" этот ответ в статистике операторов "))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})