import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

import {
    WEBHOOK_METHODS,
    WebhookConfigInterface,
    WebhookHeaderInterface
} from "~/cabinet/vue/interface/webhook/WebhookConfigInterface";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "WebhookConfig"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    value: {},
    contentVariables: {},
    hideMethod: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

function h(header: WebhookHeaderInterface) {
    return header;
}

function getNewHeader(): WebhookHeaderInterface {
    return {
        name: "",
        value: ""
    };
}


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, {
      label: "URL",
      rules: _unref(requiredRule)
    }, {
      default: _withCtx(() => [
        (_ctx.contentVariables)
          ? (_openBlock(), _createBlock(TextInputWithVariables, {
              key: 0,
              modelValue: _ctx.value.url,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.url) = $event)),
              placeholder: "Например: http://mysite.com/hello.php",
              "content-variables": _ctx.contentVariables,
              multiline: false
            }, null, 8, ["modelValue", "content-variables"]))
          : (_openBlock(), _createBlock(_component_el_input, {
              key: 1,
              modelValue: _ctx.value.url,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.url) = $event)),
              placeholder: "Например: http://mysite.com/hello.php"
            }, null, 8, ["modelValue"]))
      ]),
      _: 1
    }, 8, ["rules"]),
    (!_ctx.hideMethod)
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "Метод",
          rules: _unref(requiredRule)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.value.method,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.method) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(WEBHOOK_METHODS), (method) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    value: method,
                    key: method,
                    label: method.toUpperCase()
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["rules"]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form_item, { "label-width": "0px" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.value.basicAuth.enabled,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.basicAuth.enabled) = $event))
        }, null, 8, ["modelValue"]),
        _cache[7] || (_cache[7] = _createTextVNode(" Basic Authorization "))
      ]),
      _: 1
    }),
    (_ctx.value.basicAuth.enabled)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_el_form_item, { label: "Логин" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.value.basicAuth.login,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.basicAuth.login) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Пароль" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.value.basicAuth.password,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.basicAuth.password) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form_item, { label: "Заголовки" }, {
      default: _withCtx(() => [
        _createVNode(MyInputList, {
          modelValue: _ctx.value.headers,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value.headers) = $event)),
          "add-button-text": "Добавить заголовок",
          "new-item-value-getter": getNewHeader
        }, {
          default: _withCtx(({index}) => [
            _createVNode(_component_el_card, { class: "mb-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  "label-position": "left",
                  "label-width": "150px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Название заголовка" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: h(_ctx.value.headers[index]).name,
                          "onUpdate:modelValue": ($event: any) => ((h(_ctx.value.headers[index]).name) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_form_item, { label: "Значение заголовка" }, {
                      default: _withCtx(() => [
                        (_ctx.contentVariables)
                          ? (_openBlock(), _createBlock(TextInputWithVariables, {
                              key: 0,
                              modelValue: h(_ctx.value.headers[index]).value,
                              "onUpdate:modelValue": ($event: any) => ((h(_ctx.value.headers[index]).value) = $event),
                              "content-variables": _ctx.contentVariables,
                              multiline: false
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "content-variables"]))
                          : (_openBlock(), _createBlock(_component_el_input, {
                              key: 1,
                              modelValue: h(_ctx.value.headers[index]).value,
                              "onUpdate:modelValue": ($event: any) => ((h(_ctx.value.headers[index]).value) = $event)
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}
}

})