import type {InjectionKey, Ref} from 'vue'
import OperatorApplicationType from "~/cabinet/ts/data/online/config/OperatorApplicationType";
import {
    EditAutoActionPayloadInterface
} from "~/cabinet/vue/client/online/config/autoActions/useOnlineChatAutoActionConfig";

export const OnlineChatOperatorApplicationsProvide = Symbol("OnlineChatOperatorApplicationsProvide") as InjectionKey<Ref<OperatorApplicationType[]>>;
export const OnlineChatSiteIsFreeProvide = Symbol("OnlineChatSiteIsFreeProvide") as InjectionKey<Ref<boolean>>;
export const OnlineChatSiteIsUsePremiumOptionsProvide = Symbol("OnlineChatSiteIsUsePremiumOptionsProvide") as InjectionKey<Ref<boolean>>;
export const OnlineChatSiteIdProvide = Symbol("OnlineChatSiteIdProvide") as InjectionKey<Ref<string>>;
export const OnlineChatEditAutoActionProvide = Symbol("OnlineChatEditAutoActionProvide") as InjectionKey<(payload: EditAutoActionPayloadInterface) => void>