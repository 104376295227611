import AbstractUploadHelper from "~/ts/library/AbstractUploadHelper";

export default class UploadHelperFactory {
    private static helperClass: typeof AbstractUploadHelper;

    public static setClass(helperClass: typeof AbstractUploadHelper) {
        this.helperClass = helperClass;
    }

    public static getClass(): typeof AbstractUploadHelper {
        return this.helperClass;
    }

    public static create(): AbstractUploadHelper {
        return new (this.helperClass as any)();
    }
}