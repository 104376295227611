import ActionBlock, {ActionBlockParamModelInterface} from "~/cabinet/ts/data/events/ActionBlock";
import SenderBlockPreview from "~/cabinet/vue/client/events/action/types/common/sender/send/SenderBlockPreview.vue";
import SenderBlockForm from "~/cabinet/vue/client/events/action/types/common/sender/send/SenderBlockForm.vue";
import UserType from "~/cabinet/ts/data/events/UserType";
import WebhookBlockPreview from "~/cabinet/vue/client/events/action/types/common/webhook/WebhookBlockPreview.vue";
import WebhookBlockForm from "~/cabinet/vue/client/events/action/types/common/webhook/WebhookBlockForm.vue";
import DelayBlockPreview from "~/cabinet/vue/client/events/action/types/common/delay/DelayBlockPreview.vue";
import DelayBlockForm from "~/cabinet/vue/client/events/action/types/common/delay/DelayBlockForm.vue";
import CallBlockForm from "~/cabinet/vue/client/events/action/types/dealer/call/CallBlockForm.vue";
import CabinetNotificationBlockPreview
    from "~/cabinet/vue/client/events/action/types/dealer/cabinetNotification/CabinetNotificationBlockPreview.vue";
import CabinetNotificationForm
    from "~/cabinet/vue/client/events/action/types/dealer/cabinetNotification/CabinetNotificationForm.vue";
import OnlineChatMessageToOperatorBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toOperator/OnlineChatMessageToOperatorBlockPreview.vue";
import OnlineChatMessageToOperatorForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toOperator/OnlineChatMessageToOperatorForm.vue";
import {IForm, IOption} from "~/cabinet/vue/interface/form/elements/Interfaces";
import PhonebookWithGroupSelectForm
    from "~/cabinet/vue/client/events/action/types/phonebook/PhonebookWithGroupSelectForm.vue";
import PhonebookWithGroupSelectBlockPreview
    from "~/cabinet/vue/client/events/action/types/phonebook/PhonebookWithGroupSelectBlockPreview.vue";
import OnlineChatRedirectBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/redirect/OnlineChatRedirectBlockPreview.vue";
import OnlineChatRedirectBlockForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/redirect/OnlineChatRedirectBlockForm.vue";
import OnlineChatOpenWidgetTabBlockForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/openWidgetTab/OnlineChatOpenWidgetTabBlockForm.vue";
import OnlineChatOpenWidgetTabBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/openWidgetTab/OnlineChatOpenWidgetTabBlockPreview.vue";
import EventAsActionBlockForm
    from "~/cabinet/vue/client/events/action/types/common/eventAsAction/EventAsActionBlockForm.vue";
import EventAsActionBlockPreview
    from "~/cabinet/vue/client/events/action/types/common/eventAsAction/EventAsActionBlockPreview.vue";
import OnlineChatMessageToClientBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toClient/OnlineChatMessageToClientBlockPreview.vue";
import OnlineChatMessageToClientForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toClient/OnlineChatMessageToClientForm.vue";
import OnlineChatRequestRateForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/requestRate/OnlineChatRequestRateForm.vue";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import FilterBlockForm from "~/cabinet/vue/client/events/action/types/common/filter/FilterBlockForm.vue";
import FilterBlockOutput from "~/cabinet/vue/client/events/action/types/common/filter/FilterBlockOutput.vue";
import {
    DiscountCouponBlockParam,
    ICabinetNotificationParam,
    ICallAlgoBlockParam,
    IDelayCascadeBlockParam,
    IEventAsActionParam,
    IFilterCascadeBlockParam,
    IGlobalVarsBlockParam,
    INoteCascadeBlockParam,
    IOnlineChatForwardClientToOperatorParam,
    IOnlineChatMessageToClientParam,
    IOnlineChatMessageToOperatorParam,
    IOnlineChatOpenWidgetTabParam,
    IOnlineChatRedirectParam,
    IOnlineChatRequestRateParam,
    IPhonebookWithGroupSelectParam,
    ISenderCascadeBlockParam,
    ISendNotificationToClientOnlineChatOperatorParam,
    ITargetCascadeBlockParam,
    IWebhookCascadeBlockParam,
    ONLINE_CHAT_MESSAGE_TO_CLIENT_SEND_ERROR_MESSAGE_AND_WAIT_FOR_VALID_ANSWER_ACTION_ID
} from "~/cabinet/vue/client/events/action/types/Interface";
import SendNotificationToClientOnlineChatOperatorForm
    from "~/cabinet/vue/client/events/action/types/dealer/sendNotificationToClientOnlineChatOperator/SendNotificationToClientOnlineChatOperatorForm.vue";
import SendNotificationToClientOnlineChatOperatorBlockPreview
    from "~/cabinet/vue/client/events/action/types/dealer/sendNotificationToClientOnlineChatOperator/SendNotificationToClientOnlineChatOperatorBlockPreview.vue";
import OnlineChatForwardClientToOperatorForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/forwardClientToOperator/OnlineChatForwardClientToOperatorForm.vue";
import OnlineChatForwardClientToOperatorBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/forwardClientToOperator/OnlineChatForwardClientToOperatorBlockPreview.vue";
import GlobalVarsBlockPreview
    from "~/cabinet/vue/client/events/action/types/common/globalVars/GlobalVarsBlockPreview.vue";
import GlobalVarsBlockForm from "~/cabinet/vue/client/events/action/types/common/globalVars/GlobalVarsBlockForm.vue";
import Dictionary from "~/ts/library/Dictionary";
import {IFilter, IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {IFlowChartContentVariable} from "~/cabinet/vue/interface/flowChart/FlowChartInterfaces";
import ContactbookGroupBlockForm
    from "~/cabinet/vue/client/events/action/types/phonebook/ContactbookGroupBlockForm.vue";
import OnlineChatMessageToClientBlockOutput
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toClient/OnlineChatMessageToClientBlockOutput.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {Component} from "vue";
import DiscountCouponBlockPreview
    from "~/cabinet/vue/client/events/action/types/dealer/discountCoupon/DiscountCouponBlockPreview.vue";
import DiscountCouponBlockForm
    from "~/cabinet/vue/client/events/action/types/dealer/discountCoupon/DiscountCouponBlockForm.vue";
import ToggleTagActionBlockForm
    from "~/cabinet/vue/client/events/action/types/common/toggleTag/ToggleTagActionBlockForm.vue";
import ToggleTagActionBlockPreview
    from "~/cabinet/vue/client/events/action/types/common/toggleTag/ToggleTagActionBlockPreview.vue";
import {
    ToggleTagActionParamInterface
} from "~/cabinet/vue/client/events/action/types/common/toggleTag/ToggleTagActionParamInterface";
import OnlineChatRunExternalBotBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/OnlineChatRunExternalBotBlockPreview.vue";
import OnlineChatRunExternalBotForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/OnlineChatRunExternalBotForm.vue";
import {
    RunExternalBotActionModelInterface
} from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/Interface";
import NoteBlockPreview from "~/cabinet/vue/client/events/action/types/common/note/NoteBlockPreview.vue";
import NoteBlockForm from "~/cabinet/vue/client/events/action/types/common/note/NoteBlockForm.vue";
import {ActionDescriptionGroup, IActionDescriptionFrontendParams} from "~/cabinet/ts/data/events/Interface";
import AbstractActionDescription from "~/cabinet/ts/data/events/AbstractActionDescription";
import TargetBlockPreview from "~/cabinet/vue/client/events/action/types/common/target/TargetBlockPreview.vue";
import TargetBlockForm from "~/cabinet/vue/client/events/action/types/common/target/TargetBlockForm.vue";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";


export const GLOBAL_VARS_ACTION_DESCRIPTION_ID = "globalVars";
export const WEBHOOK_ACTION_DESCRIPTION_ID = "webhook";
export const DISCOUNT_COUPON_ACTION_DESCRIPTION_ID = "discountCoupon";
export const ONLINE_CHAT_MESSAGE_FOR_CLIENT_ACTION_DESCRIPTION_ID = "onlineChatMessageForClient";
export const ONLINE_CHAT_REQUEST_RATE_ACTION_DESCRIPTION_ID = "onlineChatRequestRate";

export function groupActionDescriptions(actionDescriptions: AbstractActionDescription<any>[]) {
    let result: (AbstractActionDescription<any> | ActionDescriptionGroup)[] = [];
    for (let actionDescription of actionDescriptions) {
        if (actionDescription.isReadyToUse) {
            let otherItemsOfThisGroup = actionDescriptions.filter(item => item.groupDescr == actionDescription.groupDescr);
            if (!actionDescription.groupDescr || otherItemsOfThisGroup.length == 1 || otherItemsOfThisGroup.length == actionDescriptions.length) {
                result.push(actionDescription);
            } else {
                let group = (result as ActionDescriptionGroup[]).find(item => item.type == "group" && item.groupDescr == actionDescription.groupDescr);
                if (!group) {
                    group = {
                        type: "group",
                        groupDescr: actionDescription.groupDescr,
                        actionDescriptions: [],
                    };
                    result.push(group);
                }
                group.actionDescriptions.push(actionDescription);
            }
        }
    }
    return result;
}

interface IActionDescriptionWithUserTypeFrontendParams extends IActionDescriptionFrontendParams {
    userTypes: UserType[];
}

export class AbstractActionDescriptionWithUserType<Model, FrontendParams extends IActionDescriptionWithUserTypeFrontendParams = IActionDescriptionWithUserTypeFrontendParams> extends AbstractActionDescription<Model, FrontendParams> {
    /*
    get isUseUserType(): boolean {
        return this.userTypes && this.userTypes.length > 0;
    }
    */

    get userTypes(): UserType[] {
        return this.frontendParams.userTypes;
    }
}

interface ISenderActionFrontendParams extends IActionDescriptionWithUserTypeFrontendParams {
    uslugaId: string,
    senderFormId: string,
    standartContentParams?: any
    senderRecipientType: "manager" | "client";
}

export class SenderActionDescription extends AbstractActionDescriptionWithUserType<ISenderCascadeBlockParam, ISenderActionFrontendParams> {
    get previewComponent(): Component {
        return SenderBlockPreview;
    }

    get formComponent(): Component {
        return SenderBlockForm;
    }

    get isReadyToUse(): boolean {
        return !!SenderUslugi[this.frontendParams.uslugaId];
    }
}

export class WebhookActionDescription extends AbstractActionDescription<IWebhookCascadeBlockParam, IWebhookActionFrontendParams> {
    get previewComponent(): Component {
        return WebhookBlockPreview;
    }

    get formComponent(): Component {
        return WebhookBlockForm;
    }

    getDynamicGlobalVariablesByActionBlock(block: ActionBlock): string[] {
        let globalVars: string[] = [];
        let params: IWebhookCascadeBlockParam = block.param as any;
        if (params && Array.isArray(params.globalVariables)) {
            for (let item of params.globalVariables) {
                if (item.name) {
                    toggleArrayElement(globalVars, item.name, true);
                }
            }
        }
        return globalVars;
    }
}

export class OnlineChatRunExternalBotActionDescription extends AbstractActionDescription<RunExternalBotActionModelInterface, RunExternalBotActionFrontendParamsInterface> {
    get previewComponent(): Component {
        return OnlineChatRunExternalBotBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatRunExternalBotForm;
    }
}

export class DiscountCouponActionDescription extends AbstractActionDescription<DiscountCouponBlockParam, DiscountCouponActionFrontendParamsInterface> {

    get previewComponent(): Component {
        return DiscountCouponBlockPreview;
    }

    get formComponent(): Component {
        return DiscountCouponBlockForm;
    }
}

export interface IWebhookActionFrontendParams extends IActionDescriptionFrontendParams {
    methods: string[];
}

export interface DiscountCouponActionFrontendParamsInterface extends IActionDescriptionFrontendParams {
    uslugi: {
        id: string,
        descr: string
    }[]
}

export class ContactbookGroupActionDescription extends AbstractActionDescription<ActionBlockParamModelInterface, IContactbookGroupActionFrontendParams> {
    get formComponent(): Component {
        return ContactbookGroupBlockForm;
    }
}

interface IContactbookGroupActionFrontendParams extends IActionDescriptionFrontendParams {

}

export class GlobalVarsActionDescription extends AbstractActionDescription<IGlobalVarsBlockParam> {
    get previewComponent(): Component {
        return GlobalVarsBlockPreview;
    }

    get formComponent(): Component {
        return GlobalVarsBlockForm;
    }

    getDynamicGlobalVariablesByActionBlock(block: ActionBlock): string[] {
        let globalVars: string[] = [];
        let params: IGlobalVarsBlockParam = block.param as any;
        if (params && Array.isArray(params.vars)) {
            for (let item of params.vars) {
                toggleArrayElement(globalVars, item.key, true);
            }
        }
        return globalVars;
    }
}


export class DelayActionDescription extends AbstractActionDescription<IDelayCascadeBlockParam> {
    get previewComponent(): Component {
        return DelayBlockPreview;
    }

    get formComponent(): Component {
        return DelayBlockForm;
    }
}

export class TargetActionDescription extends AbstractActionDescription<ITargetCascadeBlockParam> {
    get previewComponent(): Component {
        return TargetBlockPreview;
    }

    get formComponent(): Component {
        return TargetBlockForm;
    }
}

export class NoteActionDescription extends AbstractActionDescription<INoteCascadeBlockParam> {
    get previewComponent(): Component {
        return NoteBlockPreview;
    }

    get formComponent(): Component {
        return NoteBlockForm;
    }
}


interface IFilterActionFrontendParams extends IActionDescriptionFrontendParams {
    filters: IFiltersData;
    globalVarFilterSample: IFilter;
}

export class FilterActionDescription extends AbstractActionDescription<IFilterCascadeBlockParam, IFilterActionFrontendParams> {
    get formComponent(): Component {
        return FilterBlockForm;
    }

    get outputComponent(): Component {
        return FilterBlockOutput;
    }

    getFilters(contentVariables?: IFlowChartContentVariable[]) {
        let result = ObjectHelper.jsonClone(this.frontendParams.filters);
        if (contentVariables) {
            let groupId = 'schemeGlobalVariables';
            let hasGlobalVar = false;
            for (let item of contentVariables) {
                if (item.isGlobalVar) {
                    hasGlobalVar = true;
                    let filter = ObjectHelper.jsonClone(this.frontendParams.globalVarFilterSample);
                    filter.group = groupId;
                    filter.id = item.id;
                    filter.descr = filter.descrForPreview = item.descr;
                    result.filters[item.id] = filter;
                }
            }
            if (hasGlobalVar) {
                result.groups = {
                    [groupId]: {
                        id: groupId,
                        descr: 'Глобальные переменные',
                        descrArray: ['Глобальные переменные']
                    },
                    ...result.groups
                };
            }
        }
        return result;
    }
}


export class CallActionDescription extends AbstractActionDescriptionWithUserType<ICallAlgoBlockParam> {
    get formComponent(): Component {
        return CallBlockForm;
    }
}


export class CabinetNotificationActionDescription extends AbstractActionDescriptionWithUserType<ICabinetNotificationParam> {
    get previewComponent(): Component {
        return CabinetNotificationBlockPreview;
    }

    get formComponent(): Component {
        return CabinetNotificationForm;
    }
}


export class SendNotificationToClientOnlineChatOperatorActionDescription extends AbstractActionDescriptionWithUserType<ISendNotificationToClientOnlineChatOperatorParam, SendNotificationToClientOnlineChatOperatorFrontendParamsInterface> {
    get previewComponent(): Component {
        return SendNotificationToClientOnlineChatOperatorBlockPreview;
    }

    get formComponent(): Component {
        return SendNotificationToClientOnlineChatOperatorForm;
    }
}

export class AbTestActionDescription extends AbstractActionDescription<null> {

}

interface IOnlineChatMessageActionFrontendParams extends IActionDescriptionFrontendParams {
    isStandartContentAvailable: boolean
}

abstract class AbstractOnlineChatMessageActionDescription<Model, FrontendParams extends IOnlineChatMessageActionFrontendParams> extends AbstractActionDescription<Model, FrontendParams> {
}

interface IOnlineChatMessageToOperatorActionFrontendParams extends IOnlineChatMessageActionFrontendParams {
    operators: IOption[],
    operatorsCount: IOption[],
    messageTypes: {
        id: string,
        descr: string;
        extraDescr: string;
    }[]
}

interface IOnlineChatMessageToOperatorFrontendParams extends IActionDescriptionFrontendParams {
    operators: IOption[];
    operatorTypes: IOption[];
}

interface SendNotificationToClientOnlineChatOperatorFrontendParamsInterface extends IActionDescriptionWithUserTypeFrontendParams {
    sites: {
        id: string,
        title: string
    }[]
}


interface IOnlineChatForwardClientToOperatorFrontendParams extends IActionDescriptionFrontendParams {
    operators: IOption[];
    operatorTypes: IOption[];
    defaultForwardTextForClient: string;
    forwardMessageContentVariables: ContentVariableInterface[];
}


export class OnlineChatMessageToOperatorActionDescription extends AbstractOnlineChatMessageActionDescription<IOnlineChatMessageToOperatorParam, IOnlineChatMessageToOperatorActionFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatMessageToOperatorBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatMessageToOperatorForm;
    }
}

export interface IOnlineChatMessageToClientFrontendParams extends IOnlineChatMessageToOperatorFrontendParams {
    fields: { name: string, descr: string }[];
    validationTypes: Dictionary<string>;
    actionsOnInvalidAnswer: Dictionary<string>;
    answerFilters: IFiltersData;
}

export class OnlineChatRequestRateActionDescription extends AbstractActionDescription<IOnlineChatRequestRateParam> {
    get formComponent(): Component {
        return OnlineChatRequestRateForm;
    }
}


export class OnlineChatMessageToClientActionDescription extends AbstractActionDescription<IOnlineChatMessageToClientParam, IOnlineChatMessageToClientFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatMessageToClientBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatMessageToClientForm;
    }

    get outputComponent(): Component {
        return OnlineChatMessageToClientBlockOutput;
    }

    isNeedOutputTimeByActionBlock(actionBlock: ActionBlock): boolean {
        let params: IOnlineChatMessageToClientParam = actionBlock.param as any;
        return params && !!params.waitingForClientAnswer;
    }

    isAnswerFiltersUsed(model: IOnlineChatMessageToClientParam): boolean {
        if (model.waitingForClientAnswer) {
            if (!model.useAnswerVariants) {
                if (model.answerFilters && ObjectHelper.hasKeys(model.answerFilters)) {
                    return true;
                }
            }
        }
        return false;
    }

    validateModel(model: IOnlineChatMessageToClientParam): string | null {
        if (typeof model.text == "string" && model.text.trim().length) {
            if (this.isAnswerFiltersUsed(model)) {
                if (model.actionOnValidateError == ONLINE_CHAT_MESSAGE_TO_CLIENT_SEND_ERROR_MESSAGE_AND_WAIT_FOR_VALID_ANSWER_ACTION_ID) {
                    if (typeof model.messageOnValidateError != "string" || !model.messageOnValidateError.trim().length) {
                        return "Введите текст сообщения об ошибке проверки ответа";
                    }
                }
            }
        } else {
            return "Введите текст сообщения";
        }
        return null;

    }
}

export class OnlineChatForwardClientToOperatorActionDescription extends AbstractActionDescription<IOnlineChatForwardClientToOperatorParam, IOnlineChatForwardClientToOperatorFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatForwardClientToOperatorBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatForwardClientToOperatorForm;
    }

    validateModel(model: IOnlineChatForwardClientToOperatorParam): string | null {
        if (!model.operators || !model.operators.length) {
            return "Не выбраны операторы";
        }
        return null;
    }
}


export class OnlineChatClientRedirectActionDescription extends AbstractActionDescription<IOnlineChatRedirectParam> {
    get previewComponent(): Component {
        return OnlineChatRedirectBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatRedirectBlockForm;
    }
}

interface IOnlineChatClientOpenWidgetTabFrontendParams extends IActionDescriptionFrontendParams {
    tabs: {
        descr: string,
        id: string,
        type: string,
        form?: IForm
    }[];
    showTypes: {
        id: string,
        descr: string
    }[];
}

export class OnlineChatClientOpenWidgetTabActionDescription extends AbstractActionDescription<IOnlineChatOpenWidgetTabParam, IOnlineChatClientOpenWidgetTabFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatOpenWidgetTabBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatOpenWidgetTabBlockForm;
    }
}

interface IPhonebookActionFrontendParams extends IActionDescriptionFrontendParams {
    groups: IOption[];
}

export class PhonebookActionWithGroupActionDescription extends AbstractActionDescription<IPhonebookWithGroupSelectParam, IPhonebookActionFrontendParams> {
    get previewComponent(): Component {
        return PhonebookWithGroupSelectBlockPreview;
    }

    get formComponent(): Component {
        return PhonebookWithGroupSelectForm;
    }
}

/*
export class CrmActionDescription extends ActionDescription {
    get formComponent(): Component {
        return CrmBlockForm;
    }
}
*/


interface IEventAsDescriptionFrontendParams extends IActionDescriptionFrontendParams {
    events: IEventAsDescriptionFrontendParamsEvent[]
}

export interface IEventAsDescriptionFrontendParamsEvent {
    eventDescriptionId: string;
    descr: string;
    platformId: string;
    authPrimaryId: string;
    eventIdentifiers: {
        id: string;
        descr: string;
    }[]
}

export class EventAsActionDescription extends AbstractActionDescription<IEventAsActionParam, IEventAsDescriptionFrontendParams> {
    get previewComponent(): Component {
        return EventAsActionBlockPreview;
    }

    get formComponent(): Component {
        return EventAsActionBlockForm;
    }
}

interface OrganizationTagActionDescriptionFrontendParamsInterface extends IActionDescriptionFrontendParams {
    tagTypeId: string
}

export class ToggleTagActionDescription extends AbstractActionDescription<ToggleTagActionParamInterface, OrganizationTagActionDescriptionFrontendParamsInterface> {
    get formComponent(): Component {
        return ToggleTagActionBlockForm;
    }

    get previewComponent(): Component {
        return ToggleTagActionBlockPreview;
    }
}


export interface RunExternalBotActionFrontendParamsInterface extends IActionDescriptionFrontendParams {
    platformTypeId: string;
    lastClientMessageVarName: string;
    customBotPlatformId: string;
    webhook?: {
        platformId: string;
        authPrimaryId: string;
        webhookId: string;
    };
}