import AbstractOpenApiRequest from "~/ts/library/openApi/AbstractOpenApiRequest";
import {ApiRequestConfig} from "~/core-ui/ts/request/AbstractApiRequest";
import {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";

export default class OperatorApiRequestFromCabinet<DataType> extends AbstractOpenApiRequest<DataType> {
    constructor(options: ApiRequestConfig, dataType?: AbstractEntityBuilder<DataType>) {
        super(options, dataType);
        this.addHeader("X-Language", "ru");
        this.addHeader("X-Token", JSON.stringify({
            'cabinet-session': true
        }));
    }

    protected getUrl(path: string): string {
        return `/json/v1.0/operatorApplication${path}`;
    }
}