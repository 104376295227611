import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import MyFilterPreview from "~/cabinet/vue/interface/filter/MyFilterPreview.vue";
import {FilterActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import MyFilterPreviewTagTemplate from "~/cabinet/vue/interface/filter/MyFilterPreviewTagTemplate.vue";
import {useActionBlockOutputProps} from "~/cabinet/vue/client/events/action/types/useActionBlockOutput";
import {computed} from "vue";


const __default__ = {
    name: "FilterBlockOutput"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useActionBlockOutputProps<FilterActionDescription>()
},
  setup(__props) {

let props = __props;

let filters = computed(() => {
    return props.actionDescription.getFilters(props.contentVariables);
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(props).params.descr)
      ? (_openBlock(), _createBlock(MyFilterPreviewTagTemplate, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(props).params.descr), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(MyFilterPreview, {
          key: 1,
          "filters-data": _unref(filters),
          value: _unref(props).params.filterValues,
          editable: false,
          "empty-filters-string": "Фильтры не заданы"
        }, null, 8, ["filters-data", "value"]))
  ]))
}
}

})