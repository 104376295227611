import {SenderSourceInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import {
    ContentFormParamsInterface,
    SenderContentFormModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {ContentButtonsConfigInterface} from "~/cabinet/vue/client/sender/common/Interface";

export interface IWhatsappSource extends SenderSourceInterface {
    whatsapp: {
        messageTemplateAvailable: boolean
    }
}

export const WHATSAPP_CONTENT_TYPE_TEXT = "text";
export const WHATSAPP_CONTENT_TYPE_TEMPLATE = "template";

export interface IWhatsappContentFormValue extends SenderContentFormModelInterface {
    type: typeof WHATSAPP_CONTENT_TYPE_TEXT | typeof WHATSAPP_CONTENT_TYPE_TEMPLATE,
    data: IWhatsappTextContentFormValue;
}

export interface IWhatsappTextContentFormValue {
    text: IWhatsappTextValue;
}

export interface IWhatsappTemplateContentFormValue {
    template: WhatsappTemplateModelInterface;
}

export interface WhatsappTemplateModelInterface {
    id: string;
    textVariables: string[];
    headerVariables: string[];
    videoFileId: string;
    videoFileName: string;
    imageFileId: string;
    imageFileName: string;
}

export interface WhatsappTemplateInterface {
    id: string;
    descr: string;
    text: string;
    textVariablesCount: number;
    headerVariablesCount: number;
    hasHeader: boolean;
    isHeaderTypeImage: boolean;
    isHeaderTypeVideo: boolean;
    isHeaderTypeText: boolean;
    headerText?: string;
    defaultModel: Partial<WhatsappTemplateModelInterface>
}


export interface WhatsappContentFormParamsInterface extends ContentFormParamsInterface<IWhatsappSource> {
    whatsapp: {
        maxKeyboardButtonCount: number;
        maxKeyboardButtonTextLength: number;
    }
}

export interface IWhatsappTextValue extends ContentButtonsConfigInterface {
    text: string;
    isImageEnabled: boolean;
    imageFileId: string;
    imageFileName: string;
}
