import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

import Random from "~/ts/library/Random";
import {computed, onBeforeUnmount, ref, watch} from "vue";
import ContentVariables from "~/cabinet/vue/client/sender/send/ContentVariables.vue";
import Events from "~/ts/library/Events";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";

// import Prism Editor
import {PrismEditor as VuePrismEditor} from 'vue-prism-editor';

// import highlighting library (you can use any library you want just return html string)
import {Highlight} from "~/core-ui/ts/InitPrismJs";



import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import ContentVariablesHelper from "~/cabinet/vue/client/sender/send/ContentVariablesHelper";

const __default__ = {
    name: "PrismEditor"
}

interface PrismEnvInterface {
    attributes: any,
    classes: string[],
    content: string,
    language: string,
    tag: string,
    type: string,
}

(window as any).Prism.hooks.add("wrap", function (env: PrismEnvInterface) {
    if (env.type == 'content-variable') {
        let label = env.content;
        let contentVariables: ContentVariableInterface[] = (window as any).Prism.languages[env.language]?.['content-variable']?.variables;
        if (contentVariables) {
            let name = ContentVariablesHelper.getVariableNameFromPlaceholder(label);
            let contentVariable = contentVariables.find(item => item.id === name);
            if (contentVariable) {
                label = contentVariable.descr;
            }
        }
        env.attributes['data-label'] = label;
        env.attributes.contenteditable = 'false';
    }
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    language: {},
    lineNumbers: { type: Boolean },
    maxHeight: {},
    contentVariables: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<string>(__props, "modelValue");

let root = ref<HTMLDivElement>();

let uid = Random.uid().toLowerCase();
let privateLanguage = `lang-${uid}`;
let languages = ((window as any).Prism.languages);

let languageId = computed(() => {
    let result = props.language ?? "javascript";
    if (result == "html") {
        result = "markup";
    }
    return result;
});

function highlighter(code: string): string {
    return Highlight(code, languages[languageId.value]);
}

function initLanguage() {
    let language = languages[languageId.value];
    if (language) {
        language = {
            'content-variable': {
                pattern: /(\[([a-zA-Z0-9_]+)])/,
                variables: props.contentVariables ?? []
            },
            ...language
        }
        if (language.string) {
            language.string = {...language.string};
            if (!language.string.inside) {
                language.string.inside = {};
            } else {
                language.string.inside = {
                    ...language.string.inside
                }
            }
            language.string.inside['content-variable'] = /(\[([a-zA-Z0-9_]+)])/;
        }
    }
    languages[privateLanguage] = language;
}

initLanguage();
watch(computed(() => props.language), () => initLanguage());

function onContentVariablePaste(value: string) {
    let pre = root.value.querySelector("pre");
    if (lastSelection?.focusNode?.nodeType === 3) {
        let string = lastSelection.focusNode.textContent;
        string = string.substring(0, lastSelection.anchorOffset) + value + string.substring(lastSelection.focusOffset);
        lastSelection.focusNode.textContent = string;
        pre.dispatchEvent(new KeyboardEvent("keyup"));
    } else {
        model.value = (model.value ?? "") + value;
    }
}

let lastSelection: {
    focusNode: Node,
    anchorOffset: number,
    focusOffset: number
};

addDisposableEvent(Events.addEventListener('selectionchange', document, () => {
    if (root.value) {
        let selection = window.getSelection();
        if (selection) {
            let pre = root.value.querySelector("pre");
            let node = selection.focusNode;
            while (node) {
                if (node == pre) {
                    lastSelection = {
                        focusNode: selection.focusNode,
                        anchorOffset: selection.anchorOffset,
                        focusOffset: selection.focusOffset
                    };
                    break;
                } else {
                    node = node.parentNode;
                }
            }
        }
    }
}));


onBeforeUnmount(() => {
    delete languages[privateLanguage];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root,
    class: "position-relative root"
  }, [
    (_ctx.contentVariables)
      ? (_openBlock(), _createBlock(ContentVariables, {
          key: 0,
          "content-variables": _ctx.contentVariables,
          onPaste: onContentVariablePaste,
          class: "position-absolute content-variables",
          style: {"bottom":"100%"}
        }, null, 8, ["content-variables"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(VuePrismEditor), {
      class: "prism-editor",
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      highlight: highlighter
    }, null, 8, ["modelValue"])
  ], 512))
}
}

})