import 'prismjs'
import {highlight} from 'prismjs/components/prism-core';
import 'prismjs/themes/prism.css'
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism-tomorrow.css';
import "../../cabinet/vue/interface/PrismAdditionals";

export const Highlight = highlight;