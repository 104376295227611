import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {SwitchFieldMixinInterface} from "../Interfaces";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";


const __default__ = {
    name: "SwitchElementConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let _modelValue = _useModel<SwitchFieldMixinInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    descrAfterSwitch: null
}));

return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "Текст рядом с переключателем" }, {
      default: _withCtx(() => [
        _createVNode(TextInputWithVariables, {
          modelValue: _unref(model).descrAfterSwitch,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).descrAfterSwitch) = $event)),
          placeholder: "Введите текст",
          bbcode: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})