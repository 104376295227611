import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_2 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_3 = {
  key: 2,
  class: "suggest-container"
}
const _hoisted_4 = ["onClick"]

import {FIELD_TYPE_INTEGER, FIELD_TYPE_NUMBER, IOption, ITextareaField, ITextField} from './Interfaces';
import ContentVariables from "~/cabinet/vue/client/sender/send/ContentVariables.vue";
import Delay from "~/ts/library/Delay";
import Random from "~/ts/library/Random";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";
import {computed, ref} from "vue";
import {InputInstance} from "element-plus";
import HtmlInputWithVariables from "~/cabinet/vue/interface/tinymce/HtmlInputWithVariables.vue";


const __default__ = {
    name: "TextElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<ITextField>(),
    type: {
        type: [String, Number]
    },
    withDimension: {
        type: Boolean
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["blur", "focus"], ["update:modelValue"]),
  setup(__props, { expose: __expose, emit: __emit }) {

let props = __props;

let input = ref<InputInstance>();

let emits = __emit;

let model = _useModel<string | number>(__props, "modelValue");

let formElement = useFormElement(model, props, {
    focus: () => {
        input.value?.focus();
    }
});

__expose<FormElementExposeInterface>(formElement.expose);

let focused = ref(false);
let instanceId = ref(Random.uid());

async function onFocus() {
    Delay.override(instanceId.value);
    focused.value = true;
    emits("focus");
}

let isHtml = computed(() => {
    return !!(props.field as ITextareaField).isHtml;
});

async function onBlur() {
    try {
        await Delay.make(300, instanceId.value, true);
        focused.value = false;
        emits("blur");
    } catch (e) {

    }
}

let computedContentVariables = computed(() => {
    if (props.field.contentVariables) {
        if (!props.contentVariables || props.field.contentVariables.length) {
            return props.field.contentVariables;
        }
    }
    return props.contentVariables;
});

let showContentVariablesInput = computed(() => {
    return (props.type == "text" || props.type == "textarea" || !props.type) && computedContentVariables.value && computedContentVariables.value.length;
});

function pasteContentVariable(str: string) {
    let value = model.value ? model.value : "";
    model.value = value + str;
}

let typeAttribute = computed(() => {
    if (props.field.isPassword) {
        return "password";
    }
    if ([FIELD_TYPE_NUMBER, FIELD_TYPE_INTEGER].includes(props.field.type)) {
        return "number";
    }
    return props.type;
});

function onSuggestClick(suggest: IOption) {
    model.value = suggest.value;
    input.value.focus();
}

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'text-element': true, focused: _unref(focused)})
  }, [
    (_ctx.readonly && _ctx.textReadonly)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_unref(showContentVariablesInput))
            ? (_openBlock(), _createBlock(InputWithVariablesPreview, {
                key: 0,
                text: _unref(model),
                variables: _unref(computedContentVariables)
              }, null, 8, ["text", "variables"]))
            : (_unref(model))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(`${_ctx.field.prefix ? `${_ctx.field.prefix} ` : ''}${_unref(model)}`), 1)
                ], 64))
              : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_unref(isHtml))
            ? (_openBlock(), _createBlock(HtmlInputWithVariables, {
                key: 0,
                modelValue: _unref(model),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                placeholder: _ctx.field.placeholder,
                "content-variables": _unref(computedContentVariables),
                disabled: _ctx.readonly || _ctx.field.readonly,
                "min-height": 10,
                maxlength: _ctx.field.maxLength,
                bbcode: _ctx.field.bbcode,
                ref_key: "input",
                ref: input,
                onFocus: onFocus,
                onBlur: onBlur,
                multiline: "",
                "like-textarea": "",
                autocomplete: "new-password",
                toolbar: `bold italic underline strikethrough | bullist numlist` + (_unref(props).contentVariables?.length ? '  | PasteContentVariable' : '')
              }, null, 8, ["modelValue", "placeholder", "content-variables", "disabled", "maxlength", "bbcode", "toolbar"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_unref(showContentVariablesInput))
                  ? (_openBlock(), _createBlock(TextInputWithVariables, {
                      key: 0,
                      modelValue: _unref(model),
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                      placeholder: _ctx.field.placeholder,
                      "content-variables": _unref(computedContentVariables),
                      disabled: _ctx.readonly || _ctx.field.readonly,
                      "min-height": 10,
                      maxlength: _ctx.field.maxLength,
                      bbcode: _ctx.field.bbcode,
                      ref_key: "input",
                      ref: input,
                      onFocus: onFocus,
                      onBlur: onBlur,
                      multiline: "",
                      autocomplete: "new-password"
                    }, null, 8, ["modelValue", "placeholder", "content-variables", "disabled", "maxlength", "bbcode"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_el_input, _mergeProps(_ctx.$attrs, {
                        class: "flex-fill",
                        modelValue: _unref(model),
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                        type: _unref(typeAttribute),
                        placeholder: _ctx.field.placeholder,
                        disabled: _ctx.field.readonly || _ctx.readonly,
                        "show-password": _ctx.field.isPassword,
                        ref_key: "input",
                        ref: input,
                        onFocus: onFocus,
                        onBlur: onBlur,
                        autosize: {minRows:1, maxRows: 10},
                        autocomplete: "new-password",
                        maxlength: _ctx.field.maxLength
                      }), _createSlots({ _: 2 }, [
                        (_ctx.field.prefix)
                          ? {
                              name: "prepend",
                              fn: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.field.prefix), 1)
                              ]),
                              key: "0"
                            }
                          : undefined,
                        (_unref(props).withDimension && _unref(props).field.dimension)
                          ? {
                              name: "append",
                              fn: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(props).field.dimension), 1)
                              ]),
                              key: "1"
                            }
                          : undefined
                      ]), 1040, ["modelValue", "type", "placeholder", "disabled", "show-password", "maxlength"]),
                      (_unref(computedContentVariables) && _unref(computedContentVariables).length && !_ctx.field.readonly && !_ctx.readonly)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(ContentVariables, {
                              "content-variables": _unref(computedContentVariables),
                              onPaste: pasteContentVariable
                            }, null, 8, ["content-variables"])
                          ]))
                        : _createCommentVNode("", true)
                    ])),
                (_ctx.field.suggest && _ctx.field.suggest.length && !_ctx.readonly && !_ctx.field.readonly)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (_ctx.field.suggest && _ctx.field.suggest.length)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(" Подставить: ")
                          ], 64))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.suggest, (suggest, i) => {
                        return (_openBlock(), _createElementBlock("a", {
                          key: i,
                          class: "link-dashed mr-2",
                          onClick: ($event: any) => (onSuggestClick(suggest))
                        }, _toDisplayString(suggest.descr), 9, _hoisted_4))
                      }), 128)),
                      (_unref(computedContentVariables) && _unref(computedContentVariables).length)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(" Подставить ")
                          ], 64))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
        ], 64))
  ], 2))
}
}

})