import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "badge-value" }
const _hoisted_3 = {
  key: 1,
  class: "status-circle"
}

import {computed} from 'vue';
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {internationalUrlIfNeeded} from "~/core-ui/ts/Constants";
import OperatorApplicationPlatformIcon from "~/operatorApplication/vue/misc/OperatorApplicationPlatformIcon.vue";


const __default__ = {
    name: "CoreUiAvatar"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    background: {},
    src: {},
    name: {},
    size: { default: "30px" },
    statusColor: {},
    statusColorClass: {},
    statusIcon: {},
    icon: {},
    overrideHost: {},
    badgeTooltip: {},
    platform: {},
    showStatus: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

let  letters = computed(() => {
    if (props.name) {
        let name = props.name;
        name = name.toUpperCase().replace(/[^A-ZА-ЯЁ]/g, " ");
        let words = name.split(/\s/);
        let result = [];
        for (let i = 0; i < words.length && result.length < 2; i++) {
            if (words[i].length > 0) {
                result.push(words[i][0]);
            }
        }
        if (result.length) {
            return result.join("");
        }
    }
    return null;
});

let preparedSrc = computed(() => {
    return props.src ? internationalUrlIfNeeded(props.src) : null;
});


return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "core-ui-avatar",
    style: _normalizeStyle({
            background: _ctx.background ? _ctx.background : '#bdc3c7',
            width: _ctx.size,
            height: _ctx.size,
            minWidth: _ctx.size
        })
  }, [
    (_unref(letters) || _ctx.icon || _ctx.platform)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "letters",
          style: _normalizeStyle({fontSize: _ctx.size})
        }, [
          _createElementVNode("span", null, [
            (_ctx.platform)
              ? (_openBlock(), _createBlock(OperatorApplicationPlatformIcon, {
                  key: 0,
                  platform: _ctx.platform,
                  "with-color": false,
                  class: "m-0"
                }, null, 8, ["platform"]))
              : (_ctx.icon)
                ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                    key: 1,
                    icon: _ctx.icon,
                    class: "m-0"
                  }, null, 8, ["icon"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(_unref(letters)), 1)
                  ], 64))
          ])
        ], 4))
      : (_unref(preparedSrc))
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _unref(preparedSrc)
          }, null, 8, _hoisted_1))
        : _renderSlot(_ctx.$slots, "default", { key: 2 }),
    (_ctx.$slots.badge)
      ? (_openBlock(), _createElementBlock("span", {
          key: 3,
          class: "badge status",
          onMouseover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          onMouseenter: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(_component_el_tooltip, { content: _ctx.badgeTooltip }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, [
                _createElementVNode("span", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "badge")
                ])
              ])
            ]),
            _: 3
          }, 8, ["content"])
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.showStatus && (_ctx.statusColor || _ctx.statusColorClass))
      ? (_openBlock(), _createElementBlock("span", {
          key: 4,
          class: _normalizeClass({
                status: true,
                'custom-icon': !!_ctx.statusIcon,
                [`text-${_ctx.statusColorClass}`]: !!_ctx.statusColorClass
            }),
          style: _normalizeStyle({
                color: _ctx.statusColor ? `${_ctx.statusColor} !important` : null,
                fontSize: _ctx.size
            })
        }, [
          _createElementVNode("span", null, [
            (_ctx.statusIcon)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(_ctx.icon(_ctx.statusIcon))
                }, null, 2))
              : (_openBlock(), _createElementBlock("span", _hoisted_3))
          ])
        ], 6))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "append")
  ], 4))
}
}

})