import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "position-relative" }

import {Editor} from "tinymce";
import ContentVariables from "~/cabinet/vue/client/sender/send/ContentVariables.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {useInputWithVariables, useInputWithVariablesProps} from "~/cabinet/vue/interface/tinymce/useInputWithVariables";
import {
    computed,
    getCurrentInstance,
    nextTick,
    onActivated,
    onDeactivated,
    PropType,
    ref,
    watch
} from "vue";
import {
    ContentVariablesComponentExposeInterface
} from "~/cabinet/vue/client/sender/send/ContentVariablesComponentExposeInterface";
import TextInputWithVariablesExposeInterface
    , {
    TextInputWithVariablesEnterEventInterface
} from "~/cabinet/vue/interface/tinymce/TextInputWithVariablesExposeInterface";
import StringHelper from "~/ts/library/StringHelper";
import ObjectHelper from "~/ts/library/ObjectHelper";
import Random from "~/ts/library/Random";
import MyTinyMceEditorWrapper from "~/cabinet/vue/interface/tinymce/MyTinyMceEditorWrapper.vue";


import TinyMceEditor from "@tinymce/tinymce-vue";

const __default__ = {
    name: "TextInputWithVariables",
    components: {
        TinyMceEditor
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useInputWithVariablesProps(),
    leftSpan: {type: Number, default: 0},
    minHeight: {type: Number, default: 95},
    maxHeight: {type: [Number, String], default: 300},
    maxlength: {type: Number},
    inline: {type: Boolean},
    inlineLimitedHeight: {type: Boolean},
    editorClassList: {type: Array, default: () => [] as string[]},
    bbcode: {type: Boolean},
    availableBbcode: {type: Array as PropType<string[]>},
    shiftEnter: {type: Boolean},
    onEnter: {type: Function as PropType<(e: KeyboardEvent, shift: boolean) => void>},
    autoFocus: {type: Boolean}
},
  emits: ["value-without-variables", "update:modelValue", "enter"],
  setup(__props, { expose: __expose, emit: __emit }) {

let props = __props;

let isBbcode = computed(() => props.bbcode && (!Array.isArray(props.availableBbcode) || props.availableBbcode.length > 0));

let pasteTextOverride: string;

let emits = __emit;

let uid = Random.uid();

//let needFocusAfterInit = false;
let isAutoFocusedOnce = false;

let {
    variablesList,
    commonSettings,
    getText,
    helper,
    regexp,
    variableGroups,
    getSettings,
    paste
} = useInputWithVariables(props, {
    isHtml: () => false,
    isBbCode: () => isBbcode.value,
    setup: (editor: Editor) => {
        editor.on('keydown', (e: KeyboardEvent) => {
            if ((props.maxlength != null && getText().length + 1 > props.maxlength)) {
                if (e.key !== "Backspace" && e.key !== "Delete") {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            }

            if (e.key == "Enter" && e.shiftKey) {
                (e as any).shiftKey = false;
            }

            return true;
        });

        let shift = false;
        let ctrl = false;
        let shiftForKeyup: boolean = false;
        let eventForKeyup: KeyboardEvent;

        function preventEnter(e: KeyboardEvent) {
            if (((!shift && !ctrl) && props.shiftEnter) || (!props.shiftEnter && ctrl)) {
                if (e.key == "Enter") {
                    e.preventDefault();
                    return true;
                }
            }
            return false;
        }

        editor.on("keyup", function (e) {
            if (e.key == "Shift") {
                shift = false;
            } else if (e.key == "Control") {
                ctrl = false;
            } else if (e.key == "Enter") {
                emits("enter", {
                    event: eventForKeyup,
                    shift: shiftForKeyup
                });
            }
            preventEnter(e);
        });
        editor.on("keydown", function (e) {
            if (e.key == "Enter") {
                shiftForKeyup = shift;
                eventForKeyup = e;
            }
            if (e.key == "Shift") {
                shift = true;
            } else if (e.key == "Control") {
                ctrl = true;
            }
            preventEnter(e);
        });
        editor.on("keypress", function (e) {
            preventEnter(e);
        });

        editor.ui.registry.addToggleButton('custom-copy-block', {
            tooltip: "Текст для копирования",
            icon: 'copy',
            onAction: (_) => editor.execCommand('mceToggleFormat', false, 'custom-copy-block'),
            onSetup: (api) => {
                api.setActive(editor.formatter.match('custom-copy-block'));
                const changed = editor.formatter.formatChanged('custom-copy-block', (state) => api.setActive(state));
                return () => changed.unbind();
            }
        });
    },
    getContentVariablesComponent: () => contentVariables.value,
    getToolbar: () => {
        let result = [];
        if (props.inline) {
            if (variablesList.value.length) {
                result.push('PasteContentVariable');
            }
        }
        return result;
    },
    getSettings: () => {
        return {
            ...commonSettings.value,
            browser_spellcheck: true,
            inline: true,
            forced_root_block: "",
            paste_as_text: true,
            contextmenu: false,
            resize: false,
            //auto_focus: uid,
            paste_preprocess: function (a: any, b: any) {
                if (typeof pasteTextOverride === "string") {
                    b.content = StringHelper.textToHtml(pasteTextOverride);
                    pasteTextOverride = null;
                }
            },
            init_instance_callback: () => {
                emitTextWithoutVariables();
                if (props.autoFocus && !isAutoFocusedOnce) {
                    isAutoFocusedOnce = true;
                    focus();
                    moveCursorToEnd();
                }
            },
            target_list: false,
            link_title: false,
            quickbars_image_toolbar: false,
            quickbars_table_toolbar: false,
            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar: isBbcode.value ? bbCodeToolbar.value : undefined,
            formats: {
                'custom-copy-block': {inline: 'span', attributes: {'data-copyblock': "true"}, exact: true}
            },

        };
    },
    getPlugins: () => {
        return isBbcode.value ? ['link', 'quickbars'] : [];
    },
    getEditor: () => getEditor()
});

let availableBBCodeIds = computed(() => {
    return Array.isArray(props.availableBbcode) ? props.availableBbcode : ObjectHelper.getKeys(bbcodeList);
});

const bbcodeList = StringHelper.bbcodeList;

let bbCodeToolbar = computed<string>(() => {
    let result: string[] = [];
    for (let key in bbcodeList) {
        if (bbcodeList.hasOwnProperty(key)) {
            if (availableBBCodeIds.value.includes(key)) {
                let item = bbcodeList[key].toolbar;
                if (result.length && item.delimiter) {
                    result.push("|");
                }
                result.push(item.name);
            }
        }
    }
    return result.length ? result.join(" ") : undefined;
});


function getEditor(): Editor {
    return editor.value?.getEditor();
}

let editor = ref<any>();
let contentVariables = ref<ContentVariablesComponentExposeInterface>();

let active = ref(true);

onDeactivated(() => {
    active.value = false;
});

onActivated(() => {
    active.value = true;
});

let isOneLiner = computed(() => {
    return !props.inline && !props.multiline;
});

let isTextarea = computed(() => {
    return props.multiline && !props.inline;
});


let inputClassName = computed(() => {
    let result: string = null;
    if (!props.inline) {
        result = props.multiline ? 'el-textarea' : 'el-input';
    }
    return result;
});


let inputInnerClassName = computed(() => {
    let result: string = null;
    if (!props.inline) {
        result = props.multiline ? 'el-textarea__inner' : 'el-input__inner';
    }
    return result;
});

let inputWrapperClassName = computed(() => {
    let result: string = null;
    if (!props.inline) {
        result = props.multiline ? '' : 'el-input__wrapper';
    }
    return result;
})

let model = ref<string>();

let editorKey = computed(() => {
    return helper.value.getId();
});

function setModel() {
    let text = props.modelValue ? props.modelValue : null;
    if (text) {
        if (isBbcode.value) {
            text = StringHelper.bbcodeToHtml(text);
        } else {
            text = StringHelper.textToHtml(text);
        }
        text = text.split("\n").join("<br />");
        text = text.split('  ').join(' &nbsp;');
    }
    model.value = text;
    getEditor()?.setContent(text ?? "");
}

watch(editorKey, () => setModel());

function emitTextWithoutVariables() {
    let text = getText();
    if (text == null) {
        text = "";
    }
    emits("value-without-variables", text.replace(regexp.value, () => ""));
}

watch(computed(() => props.modelValue), () => {
    if (getText() != props.modelValue) {
        setModel();
    }
    /*
    if (!a || !b || !!a.length != !!b.length) {
        getEditor().focus(false);
    }
     */
})

function onInput() {
    if (!props.disabled) {
        let text = getText();
        if (typeof text == "string") {
            emits("update:modelValue", getText());
            nextTick(() => {
                emitTextWithoutVariables();
            });
        }
    }
}


let hasOverflow = computed(() => {
    if (props.modelValue && props.modelValue.length) {
        if (editor.value) {
            let element = editor.value.$el;
            if (element) {
                return element.scrollHeight > element.clientHeight;
            }
        }
    }
    return false;
});

let showContentVariables = computed(() => {
    return variableGroups.value.length && !props.disabled;
});

setModel();
emitTextWithoutVariables();

function moveCursorToEnd() {
    try {
        let editor = getEditor();
        nextTick(() => {
            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
        });
        //editor.selection.collapse(false);
    } catch (e) {
    }
}

let instance = getCurrentInstance();

function focus() {
    try {
        if (!instance.proxy.$el.contains(document.activeElement)) {
            let editor = getEditor();
            if (editor) {
                editor.focus();
            }
        }
    } catch (e) {
        //needFocusAfterInit = true;
    }
}

function onTinyMcePaste(e: ClipboardEvent) {
    let textPlain: DataTransferItem;
    let hasHtml = false;
    for (let i = 0; i < e.clipboardData.items.length; i++) {
        let item = e.clipboardData.items[i];
        if (item.type == "text/plain") {
            textPlain = item;
        }
        if (item.type == "text/html") {
            hasHtml = true;
        }
    }
    if (textPlain && hasHtml) {
        pasteTextOverride = e.clipboardData.getData("Text");
    } else {
        pasteTextOverride = null;
    }
}

__expose<TextInputWithVariablesExposeInterface>({
    focus,
    moveCursorToEnd
})

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
        'text-input-with-variables-root': true,
        inline: __props.inline
    })
  }, [
    (_unref(isTextarea) && (_ctx.$slots.left || _ctx.$slots.right))
      ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          class: "mb-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: __props.leftSpan }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "left"),
                (!_ctx.$slots.left)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, " "))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["span"]),
            _createVNode(_component_el_col, {
              span: 24 - __props.leftSpan,
              class: "text-right"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "right")
              ]),
              _: 3
            }, 8, ["span"])
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(_unref(inputClassName)),
        style: {"min-width":"0"}
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_unref(inputWrapperClassName)),
          style: {"min-width":"0"}
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({
                            [_unref(inputInnerClassName)]: true,
                            textarea: _unref(isTextarea),
                            'show-content-variables': _unref(showContentVariables) && !__props.inline
                        }),
            style: {"min-width":"0"}
          }, [
            (_unref(active))
              ? _withDirectives((_openBlock(), _createBlock(MyTinyMceEditorWrapper, {
                  key: _unref(editorKey),
                  "model-value": _unref(model),
                  "onUpdate:modelValue": onInput,
                  init: _unref(getSettings)(),
                  disabled: _ctx.disabled,
                  ref_key: "editor",
                  ref: editor,
                  "model-events": "change input undo redo paste",
                  id: _unref(uid),
                  class: _normalizeClass({
                                 [_unref(inputInnerClassName)]: _unref(isTextarea),
                                'tinymce-for-input-with-variables': true,
                                'single-line': _unref(isOneLiner),
                                'disabled': _ctx.disabled,
                                [__props.editorClassList.join(' ')]: true
                            }),
                  style: _normalizeStyle(_unref(isTextarea) || (__props.inline && __props.inlineLimitedHeight) ? {
                            minHeight: __props.minHeight ? `${__props.minHeight}px` : null,
                            maxHeight: __props.maxHeight ? (typeof __props.maxHeight == 'number' ? `${__props.maxHeight}px` : __props.maxHeight) : null,
                        } : null),
                  spellcheck: "true"
                }, null, 8, ["model-value", "init", "disabled", "id", "class", "style"])), [
                  [
                    _directive_on_native,
                    onTinyMcePaste,
                    "paste",
                    { capture: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ], 2)
        ], 2)
      ], 2),
      (_unref(showContentVariables))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({
                    'content-variables-wrapper': true,
                    'has-overflow': _unref(hasOverflow)
                })
          }, [
            _createVNode(ContentVariables, {
              hidden: __props.inline,
              ref_key: "contentVariables",
              ref: contentVariables,
              "variable-groups": _unref(variableGroups),
              class: "paste-button",
              onPaste: _unref(paste)
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, [
                  _createVNode(_component_el_tooltip, { content: "Подставить значение" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, { link: "" }, {
                        default: _withCtx(() => [
                          _createVNode(FontAwesomeIcon, { icon: "paste" })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["hidden", "variable-groups", "onPaste"])
          ], 2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})