import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "text-center",
  style: {"position":"absolute","top":"50%","margin-top":"-.75em","font-size":"3em","left":"0","right":"0"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "link-dashed",
  tabindex: ""
}
const _hoisted_4 = {
  key: 0,
  class: "text-muted mt-2 mb-2"
}
const _hoisted_5 = { class: "table valign-top table-striped" }
const _hoisted_6 = { key: 1 }

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";

import {
    DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_ENTITY_ID_INJECTION_KEY,
    DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_INJECTION_KEY,
    DataProviderEntityFieldInterface, DataProviderEntityFieldModelInterface, DataProviderEntitySaveActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionParamInterface";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {computed, inject, ModelRef, nextTick, ref, watch} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import DataProviderEntityFieldRow
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntityFieldRow.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import MyFilter from "~/cabinet/vue/interface/filter/MyFilter.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import Dictionary from "~/ts/library/Dictionary";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {IFlowChartContentVariable} from "~/cabinet/vue/interface/flowChart/FlowChartInterfaces";
import {
    DataProviderEntitySaveActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/DataProviderEntitySaveActionDescription";
import DataProviderManualFormEntityFieldRow
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderManualFormEntityFieldRow.vue";
import AddFieldDropdownButton
    from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/AddFieldDropdownButton.vue";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import Delay from "~/ts/library/Delay";
import {
    getEntityActionRequest,
    getSearchModesData, SEARCH_MODE_LAST_SAVED_EVERYWHERE
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/EntityActionHelper";



import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderEntitySaveActionTabPane"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<DataProviderEntitySaveActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;


let model: ModelRef<DataProviderEntitySaveActionParamInterface> = _useModel<getActionDescriptionModelType<DataProviderEntitySaveActionDescription>>(__props, "modelValue");

let frontendParams = props.actionDescription.frontendParams;


let manualForm = inject(DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_INJECTION_KEY, false);
let entityId = inject(DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_ENTITY_ID_INJECTION_KEY, computed(() => null));
let isEditManualForm = manualForm && !!entityId.value;

function calculateDiscriminatorValues(): Dictionary<string | number> {
    let result: Dictionary<string | number> = {};
    let fields: DataProviderEntityFieldModelInterface[] = model.value.fields;
    for (let fieldModel of fields) {
        //let field = getFieldById(fieldModel.id);
        //if (field && field.isDiscriminator) {
        let value = fieldModel.values[0]?.discriminatorValue;
        if (typeof value == "string" || typeof value == "number") {
            result[fieldModel.id] = value;
        }
        //}
    }
    return result;
}

let nonReactiveDiscriminatorValues = calculateDiscriminatorValues();

let discriminatorValues = computed(() => {
    let result = calculateDiscriminatorValues();
    nonReactiveDiscriminatorValues = result;
    return result;
});

function createRequest(discriminatorValues: Dictionary<any>) {
    return getEntityActionRequest(props, discriminatorValues, {
        dataProviderEntityId: entityId.value,
        manualForm: manualForm
    });
}


let request = computed(() => {
    return createRequest(nonReactiveDiscriminatorValues);
});

let fieldsWithoutValues = computed(() => {
    return request.value.data?.fields.filter(field => !model.value.fields.find(item => item.id == field.id)) ?? [];
})

let requiredFieldsAvailableToAdd = computed(() => {
    return fieldsWithoutValues.value.filter(field => field.isRequired);
});

let fieldsWithDefaultValuesAvailableToAdd = computed(() => {
    return fieldsWithoutValues.value.filter(field => (field.form.sections[0]?.elements[0]?.defaultValue || field.isRequired));
});

function addFieldsFromArray(fields: DataProviderEntityFieldInterface[]) {
    for (let field of fields) {
        (model.value.fields as DataProviderEntityFieldModelInterface[]).push({
            updateAllowed: field.isUpdateAllowedByDefault,
            id: field.id,
            values: [undefined],
            updateOnlyEmpty: false,
            operatorCanEditField: true,
            hideFromOperator: false
        });
    }
}

function addRequiredFields() {
    addFieldsFromArray(requiredFieldsAvailableToAdd.value);
}


function addDefaultFields() {
    addFieldsFromArray(fieldsWithDefaultValuesAvailableToAdd.value);
}

let contentVariablesForField = computed(() => {
    let platform = props.actionDescription.platformDescr;
    let result: IFlowChartContentVariable[] = [
        {
            id: frontendParams.currentDataProviderEntityFieldValueContentVariable,
            descr: `Текущее значение поля в ${platform}`,
            groupDescr: `Значения из ${platform}`
        },
        ...props.contentVariables,
    ];
    return result;
})

request.value.setCallback(() => {
    if (!model.value.fields.length) {
        addDefaultFields();
    } else {
        addRequiredFields();
    }
});

let fields = computed(() => request.value.data?.fields ?? []);

let fieldsAvailableForAdd = computed<DataProviderEntityFieldInterface[]>(() => {
    return fields.value.filter(field => !model.value.fields.find(item => item.id == field.id));
});

let selectedFoundMode = computed(() => {
    return frontendParams.actionModes.found.find(item => item.id == model.value.foundActionMode);
});

function getFieldById(id: string) {
    return request.value.data?.fields.find(item => item.id === id);
}

function addField(field: DataProviderEntityFieldInterface) {
    (model.value.fields as DataProviderEntityFieldModelInterface[]).push({
        id: field.id,
        values: [],
        updateAllowed: field.isUpdateAllowedByDefault,
        updateOnlyEmpty: false,
        hideFromOperator: false,
        operatorCanEditField: true
    });
}

let {searchTypes, otherSearchTypes, searchByIdSearchTypes, searchByIdModes} = getSearchModesData(props);

let enabledSearchByIdMode = computed(() => {
    for (let id of ObjectHelper.getKeys(searchByIdModes)) {
        if (model.value.searchModes.includes(id)) {
            return id;
        }
    }
    return null;
});

let searchByIdLastEnabledMode = ref(enabledSearchByIdMode.value ?? SEARCH_MODE_LAST_SAVED_EVERYWHERE);


function setSearchByIdType(newValue?: string) {
    for (let id of ObjectHelper.getKeys(searchByIdModes)) {
        toggleArrayElement(model.value.searchModes, id, false);
    }
    if (newValue) {
        searchByIdLastEnabledMode.value = newValue;
        toggleArrayElement(model.value.searchModes, newValue, true)
    }
}

function onSearchByIdInput(value: boolean) {
    if (!value) {
        setSearchByIdType(null);
    } else {
        setSearchByIdType(searchByIdLastEnabledMode.value)
    }
}


let isSearchByIdAvailable = computed(() => {
    return searchByIdSearchTypes.value.length > 0;
});

let discriminatorFieldsRequest = ref<ReactiveRequest<any>>();

let discriminatorValuesJson = computed(() => JSON.stringify(discriminatorValues.value))

watch(discriminatorValuesJson, async (newValueString, oldValueString) => {
    nextTick(async () => {
        let newValue = JSON.parse(newValueString) as Dictionary<any>;
        let oldValue = JSON.parse(oldValueString) as Dictionary<any>;
        let changedKeys = [];
        for (let key in newValue) {
            if (newValue.hasOwnProperty(key)) {
                if (newValue[key] !== oldValue[key]) {
                    changedKeys.push(key);
                }
            }
        }
        for (let key of changedKeys) {
            let fieldIdToRemove = request.value.data.fields
                .filter(field => field.parentDiscriminator.includes(key))
                .map(field => field.id);
            model.value.fields = model.value.fields
                .filter(modelField => !fieldIdToRemove.includes(modelField.id));
        }
        try {
            await Delay.make(50, "reloadDiscriminatorFields", true);

            if (request.value.data) {

                discriminatorFieldsRequest.value = createRequest(discriminatorValues.value).setCallback(data => {
                    if (request.value.data) {
                        request.value.data.fields = data.fields;
                        addDefaultFields();
                    }
                });
            }
        } catch (e) {
        }
    });
});


return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(ReactiveRequestLoader, { request: _unref(request) }, {
    default: _withCtx(() => [
      (_unref(request).data)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: "el-row-root"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { class: "position-relative" }, {
                default: _withCtx(() => [
                  (_unref(discriminatorFieldsRequest) && _unref(discriminatorFieldsRequest).isLoading)
                    ? (_openBlock(), _createBlock(_component_el_card, {
                        key: 0,
                        class: "discriminator-loader absolute-full-size"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createVNode(MyLoader)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_unref(manualForm))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_unref(searchTypes).length)
                          ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(ElCardHeader, { title: "Режим сохранения" }),
                                _createVNode(ElCardSection, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_switch, {
                                      "model-value": !_unref(model).isAlwaysCreate,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(model).isAlwaysCreate = !$event)),
                                      class: "mr-2"
                                    }, null, 8, ["model-value"]),
                                    _cache[9] || (_cache[9] = _createTextVNode(" Защита от дублей "))
                                  ]),
                                  _: 1
                                }),
                                (!_unref(model).isAlwaysCreate)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(ElCardSection, null, {
                                        default: _withCtx(() => [
                                          _cache[12] || (_cache[12] = _createElementVNode("p", null, "Как искать ранее созданную сущность", -1)),
                                          (_unref(isSearchByIdAvailable))
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                                                _createVNode(_component_el_switch, {
                                                  "model-value": !!_unref(enabledSearchByIdMode),
                                                  "onUpdate:modelValue": onSearchByIdInput,
                                                  class: "mr-2"
                                                }, null, 8, ["model-value"]),
                                                _cache[10] || (_cache[10] = _createTextVNode(" Искать последнюю сохраненную ")),
                                                (!!_unref(enabledSearchByIdMode))
                                                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                                                      key: 0,
                                                      trigger: "click",
                                                      onCommand: _cache[1] || (_cache[1] = ($event: any) => (setSearchByIdType($event)))
                                                    }, {
                                                      dropdown: _withCtx(() => [
                                                        _createVNode(_component_el_dropdown_menu, null, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchByIdSearchTypes), (searchType) => {
                                                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                                key: searchType.id,
                                                                command: searchType.id
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_unref(searchByIdModes)[searchType.id]), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["command"]))
                                                            }), 128))
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      default: _withCtx(() => [
                                                        _createElementVNode("a", _hoisted_3, _toDisplayString(_unref(searchByIdModes)[_unref(searchByIdLastEnabledMode)]), 1)
                                                      ]),
                                                      _: 1
                                                    }))
                                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                      _createTextVNode(_toDisplayString(_unref(searchByIdModes)[_unref(searchByIdLastEnabledMode)]), 1)
                                                    ], 64)),
                                                _cache[11] || (_cache[11] = _createTextVNode(" сущность "))
                                              ]))
                                            : _createCommentVNode("", true),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(otherSearchTypes), (otherSearchType) => {
                                            return (_openBlock(), _createElementBlock("p", {
                                              key: otherSearchType.id
                                            }, [
                                              _createVNode(_component_el_switch, {
                                                "model-value": _unref(model).searchModes.includes(otherSearchType.id),
                                                "onUpdate:modelValue": ($event: any) => (_unref(toggleArrayElement)(_unref(model).searchModes, otherSearchType.id, $event)),
                                                class: "mr-2"
                                              }, null, 8, ["model-value", "onUpdate:modelValue"]),
                                              _createTextVNode(" " + _toDisplayString(otherSearchType.descr), 1)
                                            ]))
                                          }), 128))
                                        ]),
                                        _: 1
                                      }),
                                      (_unref(request).data.filters)
                                        ? (_openBlock(), _createBlock(ElCardSection, { key: 0 }, {
                                            default: _withCtx(() => [
                                              _createVNode(HelpBlock, null, {
                                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                  _createElementVNode("p", null, " Вы можете добавить фильтры, которым должна соответствовать найденная сущность. ", -1)
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(MyInputList, {
                                                modelValue: _unref(model).updateFilters,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).updateFilters) = $event)),
                                                "remove-last-available": "",
                                                sortable: false,
                                                "new-item-value-getter": () => ({}),
                                                "add-button-text": "Добавить фильтры"
                                              }, {
                                                before: _withCtx(({index}) => [
                                                  (index > 0)
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, "или"))
                                                    : _createCommentVNode("", true)
                                                ]),
                                                default: _withCtx(({index}) => [
                                                  _createVNode(MyFilter, {
                                                    modelValue: _unref(model).updateFilters[index],
                                                    "onUpdate:modelValue": ($event: any) => ((_unref(model).updateFilters[index]) = $event),
                                                    "filters-data": _unref(request).data.filters,
                                                    placeholder: "Укажите фильтры, которым должна соответствовать обновляемая сущность"
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "filters-data"])
                                                ]),
                                                _: 1
                                              }, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      _createVNode(ElCardSection, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { label: "Если нашли сущность" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_select, {
                                                modelValue: _unref(model).foundActionMode,
                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).foundActionMode) = $event)),
                                                class: "w-100"
                                              }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).actionModes.found, (mode) => {
                                                    return (_openBlock(), _createBlock(_component_el_option, {
                                                      key: mode.id,
                                                      value: mode.id,
                                                      label: mode.descr
                                                    }, null, 8, ["value", "label"]))
                                                  }), 128))
                                                ]),
                                                _: 1
                                              }, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_form_item, { label: "Если не нашли сущность" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_select, {
                                                modelValue: _unref(model).notFoundActionMode,
                                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).notFoundActionMode) = $event)),
                                                class: "w-100"
                                              }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).actionModes.notFound, (mode) => {
                                                    return (_openBlock(), _createBlock(_component_el_option, {
                                                      key: mode.id,
                                                      value: mode.id,
                                                      label: mode.descr
                                                    }, null, 8, ["value", "label"]))
                                                  }), 128))
                                                ]),
                                                _: 1
                                              }, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_unref(request).data.fields && _unref(request).data.fields.length > 0)
                          ? (_openBlock(), _createBlock(_component_el_card, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(ElCardSectionWithTable, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("table", _hoisted_5, [
                                      _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
                                        _createElementVNode("tr", null, [
                                          _createElementVNode("th", { style: {"width":"13em"} }, "Поле"),
                                          _createElementVNode("th", { style: {"min-width":"12em"} }, "Значение")
                                        ])
                                      ], -1)),
                                      _createElementVNode("tbody", null, [
                                        (_unref(model).fields.length)
                                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(model).fields, (item, key) => {
                                              return (_openBlock(), _createElementBlock(_Fragment, null, [
                                                (getFieldById(item.id))
                                                  ? (_openBlock(), _createBlock(DataProviderEntityFieldRow, {
                                                      key: item.id,
                                                      field: getFieldById(item.id),
                                                      modelValue: _unref(model).fields[key],
                                                      "onUpdate:modelValue": ($event: any) => ((_unref(model).fields[key]) = $event),
                                                      "content-variables": _unref(contentVariablesForField),
                                                      "show-update-allowed": _unref(selectedFoundMode) && _unref(selectedFoundMode).isUpdate,
                                                      "is-manual-export-to-data-provider": _unref(props).eventParamsByAuth.isManualExportToDataProvider,
                                                      onRemove: ($event: any) => (_unref(model).fields.splice(key, 1))
                                                    }, null, 8, ["field", "modelValue", "onUpdate:modelValue", "content-variables", "show-update-allowed", "is-manual-export-to-data-provider", "onRemove"]))
                                                  : _createCommentVNode("", true)
                                              ], 64))
                                            }), 256))
                                          : (_openBlock(), _createElementBlock("tr", _hoisted_6, _cache[14] || (_cache[14] = [
                                              _createElementVNode("td", {
                                                class: "text-center",
                                                colspan: "2"
                                              }, [
                                                _createTextVNode(" Пока что не добавлено ни одного поля. Нажмите на кнопку "),
                                                _createElementVNode("span", { class: "font-weight-bold" }, "Добавить поле")
                                              ], -1)
                                            ])))
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(ElCardSection, null, {
                                  default: _withCtx(() => [
                                    _createVNode(AddFieldDropdownButton, {
                                      "fields-available-for-add": _unref(fieldsAvailableForAdd),
                                      onAdd: _cache[5] || (_cache[5] = ($event: any) => (addField($event)))
                                    }, null, 8, ["fields-available-for-add"]),
                                    (_unref(fieldsWithDefaultValuesAvailableToAdd).length)
                                      ? (_openBlock(), _createBlock(_component_el_button, {
                                          key: 0,
                                          onClick: _cache[6] || (_cache[6] = ($event: any) => (addDefaultFields()))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Добавить предложенные нами значения (" + _toDisplayString(_unref(fieldsWithDefaultValuesAvailableToAdd).length) + ") ", 1)
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(HelpBlock, {
                              key: 2,
                              danger: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Не удалось получить список доступных полей из " + _toDisplayString(_unref(frontendParams).authDescr), 1)
                              ]),
                              _: 1
                            })),
                        (_unref(frontendParams).additionalConfigForm)
                          ? (_openBlock(), _createBlock(_component_el_card, { key: 3 }, {
                              default: _withCtx(() => [
                                _createVNode(ElCardHeader, { title: "Дополнительные настройки" }),
                                _createVNode(ElCardSection, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form, {
                                      "label-position": "left",
                                      "label-width": "180px"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(MyForm, {
                                          form: _unref(frontendParams).additionalConfigForm,
                                          modelValue: _unref(model).additionalConfigFormValues,
                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(model).additionalConfigFormValues) = $event)),
                                          "content-variables": _unref(frontendParams).contentVariableMapperForAdditionalForm
                                        }, null, 8, ["form", "modelValue", "content-variables"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        (_unref(request).data.fields && _unref(request).data.fields.length > 0)
                          ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                              default: _withCtx(() => [
                                (_unref(model).fields.length)
                                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(model).fields, (item, key) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                                        (getFieldById(item.id))
                                          ? (_openBlock(), _createBlock(DataProviderManualFormEntityFieldRow, {
                                              key: 0,
                                              field: getFieldById(item.id),
                                              model: _unref(model).fields[key],
                                              onRemove: ($event: any) => (_unref(model).fields.splice(key, 1))
                                            }, null, 8, ["field", "model", "onRemove"]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    }), 256))
                                  : _createCommentVNode("", true),
                                (_unref(isEditManualForm))
                                  ? (_openBlock(), _createBlock(ElCardSection, { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createVNode(AddFieldDropdownButton, {
                                          "fields-available-for-add": _unref(fieldsAvailableForAdd),
                                          onAdd: _cache[8] || (_cache[8] = ($event: any) => (addField($event)))
                                        }, null, 8, ["fields-available-for-add"])
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})