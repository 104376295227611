import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["textContent"]

import {computed} from "vue";


const __default__ = {
    name: "InvisibleCharactersHighlight"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    text: {}
  },
  setup(__props: any) {

let props = __props;

let splitText = computed(() => {
    let text: string = "";
    if (typeof props.text == "string") {
        text = props.text;
    } else if (typeof props.text == "object") {
        text = JSON.stringify(props.text, null, 2);
    } else if (typeof props.text != "undefined" && props.text !== null) {
        text = text.toString();
    } else {
        text = "";
    }
    return text.split(/[\u0000-\u0009\u000B-\u001F\u007F-\u009F\u200B-\u200F\u202A-\u202E\u2060-\u2069\uFEFF]/g);
})

//const modifiedText = text.replace(/[\u200B-\u200F\u2060-\u2064\uFEFF]/g, '·');
//console.log(modifiedText); // Пример·текста·с·нулевой·шириной

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(splitText), (part, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
      (index > 0)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            content: "Текст содержит невидимый символ"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", { class: "invisible-utf-character" }, "·", -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        textContent: _toDisplayString(part)
      }, null, 8, _hoisted_1)
    ], 64))
  }), 256))
}
}

})