import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import SenderForm from "~/cabinet/vue/client/sender/send/SenderForm.vue";
import {SenderActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {
    CascadeContentFormEventInject,
    ICascadeContentFormEvent
} from "~/cabinet/vue/client/events/action/types/Interface";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, provide} from "vue";
import {FlowChartSaveSchemeCallbackKey} from "~/cabinet/vue/interface/flowChart/FlowChartInterfaces";


const __default__ = {
    name: "SenderBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<SenderActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["reload-outputs"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit

let _modelValue = _useModel<getActionDescriptionModelType<SenderActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    senderFormParam: null
}));

provide(CascadeContentFormEventInject, computed(() => ({
    eventDescriptionId: props.eventParamsByAuth.eventId,
    authId: props.eventParamsByAuth.authId,
    platformId: props.eventParamsByAuth.platform.id
}) as ICascadeContentFormEvent));

provide(FlowChartSaveSchemeCallbackKey, () => {
    emits('reload-outputs');
})

let isOrgManagers = computed(() => {
    return props.actionDescription.frontendParams.senderRecipientType == "manager";
});

let frontendParams = computed(() => props.actionDescription.frontendParams);

const formId = "cabinetCascadeSend";

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(SenderForm, {
      modelValue: _unref(model).senderFormParam,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).senderFormParam) = $event)),
      "form-id": _unref(frontendParams).senderFormId,
      "standart-content-params": _unref(frontendParams).standartContentParams,
      "usluga-id": _unref(frontendParams).uslugaId,
      "additional-content-variables": _ctx.contentVariables,
      "is-for-your-org-managers": _unref(isOrgManagers)
    }, null, 8, ["modelValue", "form-id", "standart-content-params", "usluga-id", "additional-content-variables", "is-for-your-org-managers"])
  ]))
}
}

})