import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

import DateHelper from "~/ts/library/Date";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";
import {DelayActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {
    DELAY_ACTION_TYPE_DELAY,
    DELAY_ACTION_TYPE_TIME_RANGE
} from "~/cabinet/vue/client/events/action/types/Interface";


const __default__ = {
    name: "DelayBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<DelayActionDescription>()
    },
  setup(__props) {

let props = __props;

let delay = computed(() => {
    return DateHelper.secondsFormat(props.params.delay);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(props).params.type == _unref(DELAY_ACTION_TYPE_DELAY))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_unref(delay)), 1)
        ], 64))
      : (_unref(props).params.type == _unref(DELAY_ACTION_TYPE_TIME_RANGE))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _cache[0] || (_cache[0] = _createTextVNode(" Ожидание времени ")),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(" с " + _toDisplayString(_unref(props).params.timeRange.start) + " по " + _toDisplayString(_unref(props).params.timeRange.stop), 1)
          ], 64))
        : _createCommentVNode("", true)
  ]))
}
}

})