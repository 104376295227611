import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {PropType} from "vue";
import ActionOutput from "~/cabinet/ts/data/events/ActionOutput";
import Dictionary from "~/ts/library/Dictionary";
import AbstractActionDescription from "~/cabinet/ts/data/events/AbstractActionDescription";

export function useActionBlockOutputProps<ActionDescriptionType extends AbstractActionDescription<any>>() {
    return {
        params: {
            type: Object as PropType<Dictionary<any>>,
            required: true
        },
        output: {
            type: Object as PropType<ActionOutput>,
            required: true
        },
        actionDescription: {
            type: Object as PropType<ActionDescriptionType>,
            required: true
        },
        contentVariables: {
            type: Array as PropType<ContentVariableInterface[]>
        }
    }
}