import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
    DataProviderEntityFieldInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionParamInterface";
import Dictionary from "~/ts/library/Dictionary";
import {computed} from "vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "AddFieldDropdownButton"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    fieldsAvailableForAdd: {}
  },
  emits: ["add"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let fieldAvailableForAddByGroupDescr = computed<Dictionary<DataProviderEntityFieldInterface[]>>(() => {
    let result: Dictionary<DataProviderEntityFieldInterface[]> = {};
    for (let field of props.fieldsAvailableForAdd) {
        let key = field.groupDescr ?? "";
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(field);
    }
    return result;
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    onCommand: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('add', $event))),
    trigger: "click",
    placement: "top"
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fieldAvailableForAddByGroupDescr), (fields, groupDescr) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              groupDescr
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(groupDescr), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields, (field) => {
                return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                  key: field.id,
                  command: field,
                  onClick: _cache[0] || (_cache[0] = () => {})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(field.descr), 1)
                  ]),
                  _: 2
                }, 1032, ["command"]))
              }), 128))
            ], 64))
          }), 256))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        type: "primary",
        plain: "",
        disabled: !_ctx.fieldsAvailableForAdd.length
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Добавить поле ")
        ])),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}
}

})