import {AbstractApiResult} from "~/core-ui/ts/request/AbstractApiResult";

interface ResponseInterface {
    success: boolean;
    error?: {
        code: number;
        descr: string;
    };
    result: any
}

export default class OpenApiResult<T> extends AbstractApiResult<T, ResponseInterface> {
    get code(): number {
        return this.response.error ? this.response.error.code : 1;
    }

    get descr(): string {
        return this.response.error ? this.response.error.descr : "Операция завершена успешно";
    }

    protected getDataFromResponse(response: ResponseInterface): any {
        return this.response.result;
    }

    get isSuccess(): boolean {
        return this.response.success;
    }

}