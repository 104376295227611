import UslugaRouteBuilder from "~/cabinet/ts/routes/UslugaRouteBuilder";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {RouteRecordRaw} from "vue-router";
import DashboardRouteBuilder from "~/cabinet/ts/routes/DashboardRouteBuilder";

const ROOT_ROUTE_NAME = "CALL_GATE_ROOT";
const REPORTS_ROUTE_NAME = "CALL_GATE_REPORTS";
const REPORTS_TASKS_ROUTE_NAME = `${REPORTS_ROUTE_NAME}_TASKS`;
const REPORTS_DASHBOARD_ROUTE_NAME = `${REPORTS_ROUTE_NAME}_DASHBOARD`;
const ABOUT_ROUTE_NAME = "CALL_GATE_ABOUT";
const API_ROUTE_NAME = "CALL_GATE_API";


export default class CallGateRouteBuilder extends UslugaRouteBuilder {
    protected makeRoute(): RouteRecordRaw {
        let dashboardRoute = DashboardRouteBuilder.getRouteConfig(REPORTS_DASHBOARD_ROUTE_NAME, () => 'callGate');

        return {
            name: ROOT_ROUTE_NAME,
            path: "callGate",
            children: [
                {
                    name: REPORTS_ROUTE_NAME,
                    path: "reports",
                    children: [
                        dashboardRoute,
                        {
                            name: REPORTS_TASKS_ROUTE_NAME,
                            path: "tasks",
                            component: () => import(/* webpackChunkName: "client-callgate" */ '~/cabinet/vue/client/callGate/reports/CallGateList.vue')
                        },
                    ]
                },
                {
                    name: ABOUT_ROUTE_NAME,
                    path: "about",
                    component: () => import(/* webpackChunkName: "client-callgate" */ '~/cabinet/vue/client/callGate/CallGateAbout.vue')
                },
                {
                    name: API_ROUTE_NAME,
                    path: "api",
                    component: () => import(/* webpackChunkName: "client-callgate" */ '~/cabinet/vue/client/callGate/CallGateApiTokens.vue')
                }
            ],
            meta: {
                menu: [
                    (new MenuItem(REPORTS_ROUTE_NAME, "Отчёты", "chart-bar"))
                        .addChildrenItem(new MenuItem(REPORTS_DASHBOARD_ROUTE_NAME, "Сводка"))
                        .addChildrenItem(new MenuItem(REPORTS_TASKS_ROUTE_NAME, "Задачи")),
                    new MenuItem(API_ROUTE_NAME, "API", "code"),
                    new MenuItem(ABOUT_ROUTE_NAME, "Об услуге", "question-circle")
                ]
            } as IRouteMeta
        };
    }

}