import {PropType} from "vue";
import {IFlowChartContentVariable} from "~/cabinet/vue/interface/flowChart/FlowChartInterfaces";
import {EventParamsByAuth} from "~/cabinet/vue/client/events/EventParamsByAuth";
import AbstractActionDescription from "~/cabinet/ts/data/events/AbstractActionDescription";

export type getActionDescriptionModelType<T> = T extends AbstractActionDescription<infer U> ? U : never;

export function useActionBlockFormProps<ActionDescriptionType extends AbstractActionDescription<any>>() {
    return {
        actionDescription: {
            type: Object as PropType<ActionDescriptionType>,
            required: true
        },
        eventParamsByAuth: {
            type: Object as PropType<EventParamsByAuth>,
            required: true
        },
        contentVariables: {
            type: Array as PropType<IFlowChartContentVariable[]>
        },
        isInScheme: {
            type: Boolean,
            default: false
        }
    }
}