import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import {
    DataProviderEntityFieldInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entity/save/DataProviderEntitySaveActionParamInterface";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderEntityFieldForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    field: {},
    contentVariables: {},
    readonly: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<any[]>(__props, "modelValue");




return (_ctx: any,_cache: any) => {
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(MyInputList, {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    "max-items": _ctx.field.isMultiple ? undefined : 1,
    "auto-append-new-input": false,
    "remove-last-available": false,
    "show-sortable-handler": false,
    sortable: _ctx.field.isMultiple && _unref(model) && _unref(model).length > 1,
    class: "input-list"
  }, {
    default: _withCtx(({index}) => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_form, { "label-position": "top" }, {
          default: _withCtx(() => [
            _createVNode(MyForm, {
              form: _ctx.field.form,
              "show-labels": false,
              modelValue: _unref(model)[index],
              "onUpdate:modelValue": ($event: any) => ((_unref(model)[index]) = $event),
              preview: _ctx.readonly,
              "content-variables": _unref(props).contentVariables
            }, null, 8, ["form", "modelValue", "onUpdate:modelValue", "preview", "content-variables"])
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "max-items", "sortable"]))
}
}

})