import {
    AbstractDataProviderActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/AbstractDataProviderActionParamInterface";
import {Component} from "vue";
import AbtractDataProviderActionBlockPreview
    from "~/cabinet/vue/client/events/action/types/dataProvider/AbtractDataProviderActionBlockPreview.vue";
import AbtractDataProviderActionBlockForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/AbtractDataProviderActionBlockForm.vue";
import AbstractActionDescription from "~/cabinet/ts/data/events/AbstractActionDescription";
import {
    AbstractDataProviderActionDescriptionFrontendParamsInterface
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/Interface";

export abstract class AbstractDataProviderActionDescription<Model extends AbstractDataProviderActionParamInterface, FrontendParams extends AbstractDataProviderActionDescriptionFrontendParamsInterface = AbstractDataProviderActionDescriptionFrontendParamsInterface> extends AbstractActionDescription<Model, FrontendParams> {
    get previewComponent(): Component {
        return AbtractDataProviderActionBlockPreview;
    }

    get formComponent(): Component {
        return AbtractDataProviderActionBlockForm;
    }

    get dataProviderActionFormComponent(): Component {
        return null;
    }

    /*
    get dataProviderActionPreviewComponent(): Component {
        return null;
    }
    */

    protected getAbstractDefaultModelValues(): AbstractDataProviderActionParamInterface {
        return {}
    }

    public abstract getDefaultModelValues(): Model;
}