import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import {ISwitchField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";


const __default__ = {
    name: "SwitchElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<ISwitchField>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let formElement = useFormElement(model, props);

__expose<FormElementExposeInterface>(formElement.expose);

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_ctx.readonly && _ctx.textReadonly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(model) ? (_unref(props).field.activeText ? _unref(props).field.activeText : "Да") : (_unref(props).field.inactiveText ? _unref(props).field.inactiveText : "Нет")), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_el_switch, {
          modelValue: _unref(model),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
          "active-text": _unref(props).field.activeText,
          "inactive-text": _unref(props).field.inactiveText,
          "active-value": _unref(props).field.activeValue,
          "inactive-value": _unref(props).field.inactiveValue,
          disabled: _ctx.readonly,
          class: "mr-2"
        }, null, 8, ["modelValue", "active-text", "inactive-text", "active-value", "inactive-value", "disabled"]),
        (_unref(props).field.descrAfterSwitch)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_unref(props).field.descrAfterSwitch), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]))
}
}

})