import NotFound from "~/cabinet/vue/interface/NotFound.vue";
import AuthLayout from "~/cabinet/vue/layout/auth/AuthLayout.vue";
import LcabLayout from "~/cabinet/vue/layout/lcab/Layout.vue";

import {AUTH_FORM_ROUTE, NOT_FOUND_ROUTE_NAME} from "~/cabinet/ts/Constant";
import Section from "~/cabinet/vue/layout/lcab/Aside/Section";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import Dictionary from "~/ts/library/Dictionary";
import qs from "qs";
import DealerRoutesBuilder from "~/cabinet/ts/routes/DealerRoutesBuilder";
import RouteHelper from "~/core-ui/ts/router/RouteHelper";
import RouteAccessChecker from "~/cabinet/ts/routes/RouteAccessChecker";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import {__} from "~/ts/library/Translate";
import ClientRoutesBuilder from "~/cabinet/ts/routes/ClientRoutesBuilder";
import PublicRoutesBuilder from "~/cabinet/ts/routes/PublicRoutesBuilder";
import {computed, watch} from "vue";
import PublicLayout from "~/cabinet/vue/layout/public/Layout.vue";
import MarketologLayout from "~/cabinet/vue/layout/marketolog/Layout.vue";

import DashboardRouteBuilder from "~/cabinet/ts/routes/DashboardRouteBuilder";
import {MARKETOLOG_DASHBOARD_ROUTE, MARKETOLOG_ROUTE} from "./Constants";
import {createRouter, createWebHashHistory, createWebHistory, RouteLocationRaw, RouteRecordRaw} from "vue-router";
import {getRealRouteMeta} from "~/core-ui/ts/router/getRealRouteMeta";
import {useMyRouter} from "~/core-ui/ts/router/useMyRouter";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";


const MODERATOR_ROUTE = "MODERATOR_ROUTE";
const MODERATOR_WORKSPACE_ROUTE = `${MODERATOR_ROUTE}_WORKSPACE`;


export default () => {
    let rootRoutes: RouteRecordRaw[] = [
        {
            path: "/online/:siteId/config/design/edit",
            component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/design/WidgetIframe.vue')
        },
        ...(new PublicRoutesBuilder()).make(),
        {
            path: '/',
            component: LcabLayout,
            children: [
                ...(new DealerRoutesBuilder()).make(),
                ...(new ClientRoutesBuilder()).make(),
                {
                    path: '/404',
                    name: NOT_FOUND_ROUTE_NAME,
                    component: NotFound,
                    meta: {
                        section: new Section("404", "404", "campground")
                    }
                },
                {
                    path: "/",
                    redirect: () => ({path: "/" + LcabLayoutStore.defaultRouteName.value}),
                },
                {
                    path: '*',
                    redirect: '/404',
                }
            ] as RouteRecordRaw[]
        },
        {
            path: '/',
            component: AuthLayout,
            meta: {
                guest: true
            } as IRouteMeta,
            children: [
                {
                    name: AUTH_FORM_ROUTE,
                    path: "/login",
                    component: {
                        template: "<div>форма авторизации</div>"
                    }
                }
            ]
        },
        {
            name: MODERATOR_ROUTE,
            path: "/moderator",
            component: PublicLayout,
            children: [
                {
                    name: MODERATOR_WORKSPACE_ROUTE,
                    path: "workspace",
                    component: () => import(/* webpackChunkName: "moderator" */ '~/cabinet/vue/dealer/tasks/sender/moderation/SenderModeration.vue'),
                }
            ],
            meta: {
                dealer: true,
                access: () => AccountStore.user.value?.isModerator
            } as IRouteMeta
        },
        {
            name: MARKETOLOG_ROUTE,
            path: "/marketolog",
            component: MarketologLayout,
            children: [
                DashboardRouteBuilder.getRouteConfig(MARKETOLOG_DASHBOARD_ROUTE, () => 'dealer', false),
                DealerRoutesBuilder.getBlogArticlesRouteConfig(true),
                DealerRoutesBuilder.getBlogCategoriesRouteConfig(true)
            ],
            meta: {
                dealer: true,
                access: () => AccountStore.user.value?.isMarketolog
            } as IRouteMeta
        }
    ];

    let base = LcabApiRequest.getCabinetUrlPrefix() + '/';
    let router = createRouter({
        routes: rootRoutes,
        history: window.history && window.history.pushState ? createWebHistory(base) : createWebHashHistory(base),
        linkExactActiveClass: "exact-selected",
        linkActiveClass: "selected",
        parseQuery: function (q: string) {
            return qs.parse(q/*, {encode: false}*/) as Dictionary<string>;
        },
        stringifyQuery: function (q: Dictionary<any>) {
            return qs.stringify(q, {encode: false});
        },
    });

    router.beforeEach(async (to, from) => {
        if (AccountStore.user.value?.isModerator && to.name != MODERATOR_WORKSPACE_ROUTE) {
            return {
                name: MODERATOR_WORKSPACE_ROUTE
            };
        } else if (AccountStore.user.value?.isMarketolog && !to.matched.find(item => item.name == MARKETOLOG_ROUTE)) {
            return {
                name: MARKETOLOG_DASHBOARD_ROUTE
            };
        } else {
            if (RouteHelper.isRouteForGuest(to)) {
                if (AccountStore.isAuthorized.value) {
                    return {
                        path: "/"
                    };
                } else {
                    return true;
                }
            } else {
                if (!AccountStore.isAuthorized.value && !RouteHelper.isRouteForGuestOrUser(to)) {
                    return {
                        name: AUTH_FORM_ROUTE
                    };
                } else {
                    if (!RouteAccessChecker.hasAccess(to)) {
                        return {
                            path: "/"
                        };
                    }
                    let nextLocation: RouteLocationRaw | void | false;
                    for (let item of from.matched) {
                        if (!item.meta) {
                            item.meta = {};
                        }
                        let meta: IRouteMeta = item.meta;
                        meta.lastPath = from.fullPath;
                        meta.lastName = from.name as string;

                    }

                    let meta: IRouteMeta = getRealRouteMeta(to);
                    if (meta) {
                        if (meta.lastPath != null) {
                            if (meta.lastName != to.name) {
                                let lastMatchedTo = to.matched[to.matched.length - 1];
                                let lastMatchedFrom = from.matched[from.matched.length - 1];
                                if (from.matched.indexOf(lastMatchedTo) === -1 && to.matched.indexOf(lastMatchedFrom) === -1) {
                                    if (lastMatchedTo.path.indexOf(":") === -1 || meta.lastPath.indexOf(to.fullPath) === 0) {
                                        nextLocation = {
                                            path: meta.lastPath
                                        };
                                        delete meta.lastPath;
                                    }
                                }
                            }
                        }
                        //console.log("last path", meta.lastPath, to, from);
                    }

                    if (!nextLocation && meta.redirectToChildren) {
                        nextLocation = {path: "/"};
                        let childrenRouteName = RouteAccessChecker.getRouteNameForRedirect(meta.redirectToChildren);
                        if (childrenRouteName != null) {
                            nextLocation = {name: childrenRouteName, params: to.params};
                        }
                    }

                    if (nextLocation == null) {
                        for (let item of to.matched) {
                            let meta: IRouteMeta = item.meta;
                            if (meta && typeof meta.beforeEach == "function") {
                                let newNextLocation = await meta.beforeEach(to, from);
                                if (newNextLocation != null) {
                                    nextLocation = newNextLocation;
                                }
                            }

                        }
                    }

                    try {
                        return nextLocation;
                    } catch (e) {

                    }
                }
            }
        }
    });


    router.afterEach((to) => {
        let routeWithMeta = to.matched.find(record => record.meta.section);
        if (routeWithMeta) {
            let meta = routeWithMeta.meta as IRouteMeta;
            document.title = meta.section.descr + " - " + __("Личный кабинет");
        }
    });


    watch(computed(() => AccountStore.isAuthorized.value), function (/*isAuthenticated: boolean*/) {
        let isCurrentRouteForGuest = RouteHelper.isRouteForGuest(router.currentRoute.value);
        let isGuest = !AccountStore.isAuthorized.value;
        if (isGuest != isCurrentRouteForGuest) {
            useMyRouter().push("/");
        }
    });

    return router;
}
