import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "mt-1 my-border-radio-list" }

import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import ContactGroup from "~/cabinet/vue/client/phonebook/groups/ContactGroup";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {computed} from "vue";
import {ContactBookDataSourceInterface} from "~/cabinet/ts/store/AccountStore";
import {access} from "~/ts/vuePlugins/CabinetUiHelpersMixin";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";
import {EditGroupDialogSaveEventInterface} from "~/cabinet/vue/client/phonebook/groups/Interface";

interface EditGroupResponseInterface {
    colors: { id: string }[];
    model: ContactGroup;
}


const __default__ = {
    name: "EditGroupDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    id: {},
    dataSource: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["save", "open", "reload"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let visible = _useModel<boolean>(__props, "visible");

let request = computed(() => {
    return (new ReactiveRequest<EditGroupResponseInterface>(new LcabApiRequest({
        url: `/api/cabinet/contacts/groups/${props.dataSource.id}/getEditGroupDialog`,
        p: {
            id: props.id
        }
    }))).setErrorCallback(() => {
        visible.value = false;
    });
});

async function onApply() {
    let result = await LcabApiRequest.save<{ id: string | number }>({
        url: `/api/cabinet/contacts/groups/${props.dataSource.id}/saveGroup`,
        p: request.value.data.model
    });
    if (result.isSuccess) {
        let id = result.data.id;
        emits("save", {
            id,
            descr: request.value.data.model.descr
        } as EditGroupDialogSaveEventInterface);
        if (!props.id) {
            emits("open", result.getData("id"));
        } else {
            emits("reload");
        }
        visible.value = false;
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createBlock(MyDialog, {
    "apply-disabled": !_unref(request).data,
    visible: _unref(visible),
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
    title: _ctx.id ? 'Редактирование группы' : 'Добавление группы',
    dialog: {
            width: `800px`
        },
    form: {
            model: _unref(request).data ? _unref(request).data.model : null,
            labelPosition: 'left',
            labelWidth: '170px'
        },
    onApply: onApply
  }, {
    default: _withCtx(() => [
      _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
        default: _withCtx(() => [
          (_unref(request).data)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_el_form_item, {
                  label: "Название группы",
                  rules: _unref(requiredRule),
                  prop: "descr"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(request).data.model.descr,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(request).data.model.descr) = $event)),
                      placeholder: "Введите название группы"
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([{id: null}, ..._unref(request).data.colors], (color) => {
                        return (_openBlock(), _createBlock(_component_el_radio, {
                          key: color.id,
                          modelValue: _unref(request).data.model.color,
                          "onUpdate:modelValue": ($event: any) => ((_unref(request).data.model.color) = $event),
                          value: color.id,
                          border: "",
                          class: "mr-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(FontAwesomeIcon, {
                              icon: color.id ? 'folder' : 'times',
                              "icon-style": color.id && color.id === _unref(request).data.model.color ? 'solid' : 'regular',
                              class: _normalizeClass(`text-${color.id}`)
                            }, null, 8, ["icon", "icon-style", "class"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "value"]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 8, ["rules"]),
                _createVNode(_component_el_form_item, { label: "Скрытая группа" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_switch, {
                      modelValue: _unref(request).data.model.isHidden,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(request).data.model.isHidden) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(HelpBlock, null, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Скрытые группы по умолчанию не отображаются в списке ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "Является стоп-листом" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_switch, {
                      modelValue: _unref(request).data.model.isStop,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(request).data.model.isStop) = $event)),
                      disabled: !_unref(access).checkAccessForContactbookEditStopList()
                    }, null, 8, ["modelValue", "disabled"]),
                    _createVNode(HelpBlock, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Абонентам из стоп-листа не будут отправляться сообщения ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["request"])
    ]),
    _: 1
  }, 8, ["apply-disabled", "visible", "title", "form"]))
}
}

})