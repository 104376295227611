import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "m-0 p-0" }
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: "m-0 p-0"
}
const _hoisted_6 = ["href"]

import {IFileShareField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import ArrayHelper from "~/ts/library/ArrayHelper";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";
import {computed, ref} from "vue";
import {UploadFile, UploadInstance} from "element-plus";
import UploadHelperFactory from "~/ts/library/UploadHelperFactory";


const __default__ = {
    name: "FileShareElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<IFileShareField>(),
    useUrlInValue: {type: Boolean},
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let loading = ref(false);

let formElement = useFormElement(model, props);

__expose<FormElementExposeInterface>(formElement.expose);

let uploadRef = ref<UploadInstance>();

let action = computed(() => {
    let helper = UploadHelperFactory.create();
    if (props.field.longStore) {
        helper.setLongStore();
    }
    if (props.field.tag) {
        helper.setTag(props.field.tag);
    }
    return helper.getUrl();
});

let accept = computed(() => {
    let result: string[] = [];
    if (props.field.accept) {
        result = props.field.accept.split(",")
            .map(item => item.trim())
            .filter(item => item.length > 0)
            .map(item => `.${item}`);
    }
    return result.length > 0 ? result.join(",") : undefined;
});


function onChange(file: any, fileList: (UploadFile & { response: any })[]) {
    let success = true;
    if (file.response) {
        loading.value = false;
        if (typeof file.response.code == "number" && file.response.code != 1) {
            LcabApiResult.showError(file.response.descr);
            success = false;
        }
    } else {
        loading.value = true;
    }
    if (success) {
        let result = (UploadHelperFactory.create()).getIdFromFileList(fileList);
        if (props.useUrlInValue) {
            result = result.map(id => {
                return UploadHelperFactory.getClass().getUrlById(id);
            });
        }
        if (result.length) {
            let value: string | string[] = model.value && Array.isArray(model.value) ? model.value : [];
            if (props.field.multiple) {
                value.push(...result);
                value = ArrayHelper.unique(value);
            } else {
                value = result[result.length - 1];
            }
            model.value = value;
            if (!fileList.find(item => !['success', 'fail'].includes(item.status))) {
                uploadRef.value.clearFiles();
            }
        }
    }

}

function remove(key: number) {
    if (Array.isArray(model.value)) {
        model.value.splice(key, 1)
    } else {
        model.value = null;
    }
}

function hrefById(id: string | string[]) {
    if (props.useUrlInValue) {
        return id;
    }
    return UploadHelperFactory.getClass().getUrlById(id);
}

let idList = computed<string[]>(() => {
    let result: any = model.value;
    if (!Array.isArray(result)) {
        if (typeof result == "string") {
            result = [result];
        } else {
            result = [];
        }
    }
    return result;
});


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_upload, {
      class: "d-inline-block text-left",
      ref_key: "uploadRef",
      ref: uploadRef,
      "show-file-list": false,
      action: _unref(action),
      "on-change": onChange,
      multiple: _ctx.field.multiple,
      accept: _unref(accept)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          plain: "",
          disabled: _unref(loading) || _ctx.readonly
        }, _createSlots({
          default: _withCtx(() => [
            (!_unref(loading))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (!_unref(model) || !_unref(model).length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.field.multiple ? 'Загрузить файлы' : 'Загрузить файл'), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.field.multiple ? 'Загрузить файлы' : 'Загрузить другой файл'), 1)
                      ], 64))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (_unref(loading))
            ? {
                name: "icon",
                fn: _withCtx(() => [
                  _createVNode(MyLoader)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["disabled"])
      ]),
      _: 1
    }, 8, ["action", "multiple", "accept"]),
    _createElementVNode("div", _hoisted_1, [
      (_unref(idList).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(idList), (fileId, key) => {
              return (_openBlock(), _createElementBlock("p", _hoisted_3, [
                _createVNode(MyIconButton, {
                  delete: "",
                  disabled: _ctx.readonly,
                  onClick: ($event: any) => (remove(key))
                }, null, 8, ["disabled", "onClick"]),
                _createElementVNode("a", {
                  href: hrefById(fileId),
                  target: "_blank"
                }, " Файл #" + _toDisplayString(key + 1), 9, _hoisted_4)
              ]))
            }), 256)),
            (_unref(idList).length > 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                  _createElementVNode("a", {
                    href: hrefById(_unref(idList)),
                    target: "_blank"
                  }, " Скачать архивом ", 8, _hoisted_6)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})