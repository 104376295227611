import {
    AbstractDataProviderActionDescription
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/AbstractDataProviderActionDescription";
import {
    AbstractDataProviderActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/AbstractDataProviderActionParamInterface";
import {
    DataProviderEntityActionDescriptionFrontendParamsInterface
} from "~/cabinet/ts/data/events/actionDescriptions/DataProvider/Interface";

export default abstract class AbstractDataProviderEntityActionDescription<Model extends AbstractDataProviderActionParamInterface, FrontendParams extends DataProviderEntityActionDescriptionFrontendParamsInterface = DataProviderEntityActionDescriptionFrontendParamsInterface> extends AbstractDataProviderActionDescription<Model, FrontendParams> {

}