import Section from "~/cabinet/vue/layout/lcab/Aside/Section";
import {__} from "~/ts/library/Translate";
import RouteHelper from "~/core-ui/ts/router/RouteHelper";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import UslugaMapper from "~/cabinet/ts/data/usluga/Uslugi";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import {
    ACCESS_CALL_PASSWORD_CONFIG,
    ACCESS_DEALER,
    ACCESS_SUPERDEALER
} from "~/cabinet/ts/data/organization/IUserAccess";
import SenderRouteBuilder from "~/cabinet/ts/routes/SenderRouteBuilder";
import {
    LICENSER_ID_SMS_SOURCE_REPLACE,
    LICENSER_ID_VIBER_SOURCE,
    SMS_USLUGI_PLATFORM_ID,
    USLUGA_ID_AGENT,
    USLUGA_ID_CALL_GATE,
    USLUGA_ID_CALL_PASSWORD,
    USLUGA_ID_EMAIL,
    USLUGA_ID_MESSENGER,
    USLUGA_ID_ONLINE,
    USLUGA_ID_PAGER,
    USLUGA_ID_VIBER,
    USLUGA_ID_VOICE
} from "~/cabinet/ts/Constant";
import SenderUsluga from "~/cabinet/ts/data/usluga/SenderUsluga";
import MenuItemDelimiter from "~/cabinet/vue/interface/menu/MenuItemDelimiter";
import {IUsluga} from "~/cabinet/ts/data/usluga/IUsluga";
import md5 from "~/ts/library/md5";
import Dictionary from "~/ts/library/Dictionary";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import EventsRouteBuilder from "~/cabinet/ts/routes/EventsRouteBuilder";
import {AlfaBankInterface} from "~/cabinet/vue/dealer/pay/alfabank/AlfaBankInterface";
import DashboardRouteBuilder from "~/cabinet/ts/routes/DashboardRouteBuilder";
import {RouteLocationNamedRaw, RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";
import {ConfigWithFormComponent} from "~/cabinet/vue/client/config/useConfigWithFormAdditionalComponents";

export const DEALER_ROOT_NAME = "DEALER_ROOT";
const DEALER_ORG_CARD_NAME = "DEALER_ORGS_CARD_ROOT";
const DEALER_ORG_CARD_MAIN_NAME = DEALER_ORG_CARD_NAME + "_MAIN";
export const DEALER_PAY = "DEALER_PAY";
export const DEALER_PAY_ORG_REAL = "DEALER_PAY_ORG_REAL";
export const DEALER_PAY_ORG = "DEALER_PAY_ORG";
export const SUPPORT_ROUTE_NAME = "DEALER_SUPPORT";
const SUPPORT_TICKET_ROUTE_NAME = `${SUPPORT_ROUTE_NAME}_TICKET`;


const TARIF_ROUTE_TARIF_ID_PARAM_NAME = "id";



export default class DealerRoutesBuilder {
    public make(): RouteRecordRaw[] {
        let route = {
            path: "dealer",
            name: DEALER_ROOT_NAME,
            meta: {
                access: [
                    ACCESS_DEALER
                ],
                dealer: true
            } as IRouteMeta,
            children: [
                this.getStart(),
                this.getOrgs(),
                this.getPay(),
                this.getTasks(),
                this.getSupport(),
                this.getReports(),
                this.getDocuments(),
                this.getBlog(),
                this.getConfig(),
                this.getEvents(),
                this.getDev(),
                this.getBackToCabinet(),
            ]
        };

        RouteHelper.setDefaultRouteComponents(route);
        return [
            route
        ] as RouteRecordRaw[];
    }

    private getBackToCabinet(): RouteRecordRaw {
        let result = {
            name: "DEALER_BACK_TO_CABINET",
            path: "backToCabinet",
            beforeEnter: function (to, from, next) {
                next({
                    path: "/"
                });
            }
        } as RouteRecordRaw;
        Section.createForRoute(result, __("В кабинет"), "reply");
        return result;
    }

    private getSenderReports(usluga: IUsluga, routeName: string) {
        let allMessagesRouteName = routeName + "_allMessages";
        let chartRouteName = routeName + "_chart";
        let sentRouteName = routeName + "_sent";
        let waitingRouteName = routeName + "_wait";

        let unsubscribeRouteName = routeName + "_unsubscribe";

        let getOrgIdByRoute = (route: RouteLocationNormalized) => route.params.ocode ? md5(route.params.ocode as string) : null;


        let sentRouteMaker = (waiting: boolean): RouteRecordRaw => {
            return {
                path: waiting ? "waiting" : "sent",
                name: waiting ? waitingRouteName : sentRouteName,
                component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/tasks/SenderReportTasks.vue'),
                props: (route) => ({
                    uslugaId: usluga.id,
                    dealer: true,
                    waiting,
                    orgId: getOrgIdByRoute(route),
                })
            }
        };


        let uslugaRoute: RouteRecordRaw = {
            path: usluga.id,
            name: routeName,
            children: [
                sentRouteMaker(false),
                sentRouteMaker(true),
                {
                    path: "allMessages",
                    name: allMessagesRouteName,
                    component: () => import(/* webpackChunkName: "dealer-reports" */ '~/cabinet/vue/dealer/report/sender/AllMessagesWrapper.vue'),
                    props: route => ({
                        uslugaId: usluga.id,
                        routeName: allMessagesRouteName,
                        dealer: true,
                        orgId: getOrgIdByRoute(route),
                    })
                },
                {
                    path: "chart",
                    name: chartRouteName,
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/chart/SenderChartReport.vue'),
                    props: route => ({
                        uslugaId: usluga.id,
                        forDealer: true,
                        orgId: getOrgIdByRoute(route),
                    })
                },
                {
                    path: "unsubscribe",
                    name: unsubscribeRouteName,
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/unsubscribe/Unsubscribe.vue'),
                    props: route => ({
                        uslugaId: usluga.id,
                        dealer: true,
                        orgId: getOrgIdByRoute(route),
                    })
                }
            ],
            beforeEnter: function (to, from, next) {
                SenderRouteBuilder.initData(usluga.id);
                next();
            }
        };
        let menu = (new MenuItem(routeName, usluga.descr))
            .setIcon(usluga.icon)
            .addChildrenItem(
                new MenuItem(sentRouteName, __("Рассылки"))
            )
            .addChildrenItem(
                new MenuItem(waitingRouteName, "Отложенные рассылки")
            )
            .addChildrenItem(
                new MenuItem(allMessagesRouteName, __("Все сообщения"))
            )
            .addChildrenItem(
                new MenuItem(chartRouteName, "Графики")
            )
            .addChildrenItem(
                new MenuItem(unsubscribeRouteName, __("Отписавшиеся от рассылок"))
            );
        return {
            routeConfig: uslugaRoute,
            menu: menu
        }
    }

    public static getBlogArticlesRouteConfig(forMarketolog: boolean = false): RouteRecordRaw {
        return {
            path: "articles",
            name: `DEALER_BLOG_ARTICLES_${forMarketolog}`,
            component: () => import(/* webpackChunkName: "dealer-blog" */ '~/cabinet/vue/dealer/blog/ArticleList.vue'),
        };
    }

    public static getBlogCategoriesRouteConfig(forMarketolog: boolean = false): RouteRecordRaw {
        return {
            path: "categories",
            name: `DEALER_BLOG_CATEGORIES_${forMarketolog}`,
            component: () => import(/* webpackChunkName: "dealer-blog" */ '~/cabinet/vue/dealer/blog/CategoryList.vue'),
        };
    }

    private getBlog(): RouteRecordRaw {
        let articles = DealerRoutesBuilder.getBlogArticlesRouteConfig();
        let categories = DealerRoutesBuilder.getBlogCategoriesRouteConfig();
        let result: RouteRecordRaw = {
            path: "blog",
            name: "DEALER_BLOG",
            children: [
                articles,
                categories
            ],
            meta: {
                access: () => AccountStore.access.value.checkAccessForDealerModifyContent(),
                menu: [
                    new MenuItem(articles.name, 'Статьи'),
                    new MenuItem(categories.name, 'Категории')
                ]
            } as IRouteMeta
        };
        Section.createForRoute(result, "Блог", "newspaper");
        return result;
    }

    private getDocuments(): RouteRecordRaw {
        let docRouteName = "DEALER_DOCUMENTS";
        let contractsRouteName = docRouteName + "_contracts";
        let actRouteName = docRouteName + "_act";
        let agentActRouteName = docRouteName + "_agentAct";

        let billRouteName = docRouteName + "_bill";
        let envelopeRouteName = docRouteName + "_envelope";

        let budgetRouteName = `${docRouteName}_budget`;
        let budgetRashodRouteName = `${budgetRouteName}_rashod`;
        let budgetContragentsRouteName = `${budgetRouteName}_contragents`;
        let budgetStructure = `${budgetRouteName}_structure`;


        let menu: MenuItem[] = [
            new MenuItem(contractsRouteName, "Договоры", "file-signature"),
            new MenuItem(actRouteName, "Акты", "file-signature"),
            new MenuItem(billRouteName, "Счета", "file-signature"),
            new MenuItem(envelopeRouteName, "Конверты", "envelope"),
            new MenuItem(agentActRouteName, "Агентские акты", "file-signature"),
            (new MenuItem(budgetRouteName, "Бюджет", "coins"))
                .addChildrenItem(new MenuItem(budgetRashodRouteName, "Расходы"))
                .addChildrenItem(new MenuItem(budgetContragentsRouteName, "Контрагенты"))
                .addChildrenItem(new MenuItem(budgetStructure, "Структура"))
        ];
        let result: RouteRecordRaw = {
            path: "documents",
            name: docRouteName,
            children: [
                {
                    name: contractsRouteName,
                    path: "contracts",
                    component: () => import(/* webpackChunkName: "dealer-documents" */ '~/cabinet/vue/dealer/documents/contract/ContractList.vue')
                },
                {
                    name: actRouteName,
                    path: "act",
                    component: () => import(/* webpackChunkName: "dealer-documents" */ '~/cabinet/vue/dealer/documents/act/ActList.vue'),
                    meta: {
                        access: () => AccountStore.isNewDoc.value,
                    } as IRouteMeta
                },
                {
                    name: agentActRouteName,
                    path: "agentAct",
                    component: () => import(/* webpackChunkName: "dealer-documents" */ '~/cabinet/vue/dealer/documents/agentAct/AgentActList.vue')
                },

                {
                    name: billRouteName,
                    path: "bill",
                    component: () => import(/* webpackChunkName: "dealer-documents" */ '~/cabinet/vue/dealer/documents/bill/BillList.vue'),
                    meta: {
                        access: () => AccountStore.isNewDoc.value,
                    } as IRouteMeta
                },
                {
                    name: envelopeRouteName,
                    path: "envelope",
                    component: () => import(/* webpackChunkName: "dealer-documents" */ '~/cabinet/vue/dealer/documents/envelope/EnvelopeList.vue'),
                    meta: {
                        access: () => AccountStore.isNewDoc.value && AccountStore.isSuperDealer.value && AccountStore.access.value.isAdmin,
                        keepAlive: false
                    } as IRouteMeta
                },
                {
                    name: budgetRouteName,
                    path: "budget",
                    children: [
                        {
                            name: budgetRashodRouteName,
                            path: "rashod",
                            component: () => import(/* webpackChunkName: "dealer-budget" */ '~/cabinet/vue/dealer/documents/budget/expense/BudgetExpense.vue'),
                        },
                        {
                            name: budgetContragentsRouteName,
                            path: "contragents",
                            component: () => import(/* webpackChunkName: "dealer-budget" */ '~/cabinet/vue/dealer/documents/budget/partner/BudgetPartner.vue'),
                            meta: {
                                keepAlive: false
                            } as IRouteMeta
                        },
                        {
                            name: budgetStructure,
                            path: "structure",
                            component: () => import(/* webpackChunkName: "dealer-budget" */ '~/cabinet/vue/dealer/documents/budget/structure/BudgetStructure.vue'),
                        }
                    ],
                    meta: {
                        access: () => AccountStore.isSuperDealer.value,
                    } as IRouteMeta
                }
            ],
            meta: {
                access: () => AccountStore.access.value.checkAccessForDealerDoc(),
                menu
            } as IRouteMeta
        };
        Section.createForRoute(result, "Документы", "archive");
        return result;
    }

    public static getSupportTicketLocation(ticketId: string): RouteLocationNamedRaw {
        return {
            name: SUPPORT_TICKET_ROUTE_NAME,
            params: {
                ticketId
            }
        }
    }

    public static getSupportLocation(ocode?: string): RouteLocationNamedRaw {
        return {
            name: SUPPORT_ROUTE_NAME,
            query: ocode ? {
                q: JSON.stringify({"filters": {"organization": {"=": [ocode]}}})
            } : null
        }
    }

    private getSupport(): RouteRecordRaw {

        let result: RouteRecordRaw = {
            path: "support",
            name: SUPPORT_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "dealer-support" */ '~/cabinet/vue/dealer/support/SupportTicketList.vue'),
            children: [
                {
                    name: SUPPORT_TICKET_ROUTE_NAME,
                    path: "ticket/:ticketId",
                    props: route => route.params,
                    component: () => import(/* webpackChunkName: "dealer-support" */ '~/cabinet/vue/dealer/support/SupportTicket.vue'),

                }
            ]
        };
        Section.createForRoute(result, "Вопросы клиентов", "question-circle")
            .setBadge(new MenuBadge("dealerSupport"));
        return result;
    }

    private getDev(): RouteRecordRaw {
        const DEV_ROUTE_NAME = "DEALER_DEV";
        const SVN_ROUTE_NAME = `${DEV_ROUTE_NAME}_SVN`;
        const CMD_LOG_ROUTE_NAME = `${DEV_ROUTE_NAME}_CMD_LOG`;
        const DROCH_ROUTE_NAME = `${DEV_ROUTE_NAME}_DROCH`;
        const FIRESTARTER_ROUTE_NAME = `${DEV_ROUTE_NAME}_FIRESTARTER`;
        const PHPSTAN_ROUTE_NAME = `${DEV_ROUTE_NAME}_PHPSTAN`;


        const PROCESSLIST_ROUTE_NAME = `${DEV_ROUTE_NAME}_PROCESSLIST`;
        const PROCESSLIST_HOST_ROUTE_NAME = `${PROCESSLIST_ROUTE_NAME}_HOST`;
        const RUNNING_PHP_ROUTE_NAME = `${DEV_ROUTE_NAME}_RUNNING_PHP`;
        const RUNNING_PHP_HOST_ROUTE_NAME = `${RUNNING_PHP_ROUTE_NAME}_HOST`;

        const JSON_ERROR_LOG_ROUTE_NAME = `${DEV_ROUTE_NAME}_JSON_ERROR_LOG`;

        let result: RouteRecordRaw = {
            path: "dev",
            name: DEV_ROUTE_NAME,
            children: [
                {
                    path: "svn",
                    name: SVN_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/svn/SvnRepositories.vue'),
                },
                {
                    path: "cmdLog",
                    name: CMD_LOG_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/cmdLog/CmdLog.vue'),
                },
                {
                    path: "droch",
                    name: DROCH_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/droch/Droch.vue'),
                },
                {
                    path: "firestarter",
                    name: FIRESTARTER_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/firestarter/Firestarter.vue'),
                },
                {
                    path: "phpstan",
                    name: PHPSTAN_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/phpstan/PhpStan.vue'),
                },
                {
                    path: "processlist",
                    name: PROCESSLIST_ROUTE_NAME,
                    children: [
                        {
                            name: PROCESSLIST_HOST_ROUTE_NAME,
                            path: ":groupId",
                            component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/processList/ProcessList.vue'),
                            props: to => to.params
                        }
                    ],
                    redirect: () => ({
                        name: PROCESSLIST_HOST_ROUTE_NAME,
                        params: {
                            groupId: AccountStore.dealerParams.value.mysqlHosts[0].id
                        }
                    })
                },
                {
                    path: "php",
                    name: RUNNING_PHP_ROUTE_NAME,
                    children: [
                        {
                            name: RUNNING_PHP_HOST_ROUTE_NAME,
                            path: ":hostId",
                            component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/php/PhpList.vue'),
                            props: to => to.params
                        }
                    ],
                    redirect: () => ({
                        name: RUNNING_PHP_HOST_ROUTE_NAME,
                        params: {
                            hostId: AccountStore.dealerParams.value.phpHosts[0].id
                        }
                    })
                },
                {
                    path: "jsonErrors",
                    name: JSON_ERROR_LOG_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "dealer-dev" */ '~/cabinet/vue/dealer/dev/jsonErrors/JsonErrors.vue'),
                }
            ],
            meta: {
                access: () => AccountStore.isSuperDealer.value && AccountStore.access.value.isAdmin,
                menu: [
                    new MenuItem(SVN_ROUTE_NAME, 'SVN', 'code'),
                    new MenuItem(CMD_LOG_ROUTE_NAME, 'Логи', 'stream'),
                    (new MenuItem(PROCESSLIST_ROUTE_NAME, 'ProcessList', 'database'))
                        .addChildrenItem(() => {
                            return AccountStore.data.value.dealer.mysqlHosts.map(
                                group => (new MenuItem(PROCESSLIST_HOST_ROUTE_NAME, group.id))
                                    .addRouteParam('groupId', group.id)
                            )
                        }),
                    (new MenuItem(RUNNING_PHP_ROUTE_NAME, 'Запущенные PHP', 'php'))
                        .addChildrenItem(() => {
                            return AccountStore.data.value.dealer.phpHosts.map(
                                host => (new MenuItem(RUNNING_PHP_HOST_ROUTE_NAME, host.descr))
                                    .addRouteParam('hostId', host.id)
                            )
                        }),
                    new MenuItem(JSON_ERROR_LOG_ROUTE_NAME, 'JSON ошибки', 'bug'),
                    new MenuItem(DROCH_ROUTE_NAME, 'Дрочь', 'rabbit'),
                    new MenuItem(FIRESTARTER_ROUTE_NAME, 'Event Firestarter', 'satellite-dish'),
                    new MenuItem(PHPSTAN_ROUTE_NAME, 'PHPStan', 'bug'),

                ]
            } as IRouteMeta
        };
        Section.createForRoute(result, "Разработчикам", "terminal");
        return result;
    }

    private getReports(): RouteRecordRaw {
        const reportsRouteName = "DEALER_REPORTS";
        const financeRouteName = `${reportsRouteName}FINANCE`;
        const financeSalaryRouteName = `${financeRouteName}_SALARY`;
        const financeProfitRouteName = `${financeRouteName}_PROFIT`;
        const financeCreditRouteName = `${financeRouteName}_CREDIT`;

        const budgetRouteName = `${reportsRouteName}_BUDGET`;
        const budgetExpenseRouteName = `${budgetRouteName}_EXPENSE`;
        const budgetSummaryRouteName = `${budgetRouteName}_SUMMARY`;

        const financePaymentsRouteName = `${financeRouteName}_PAYMENTS`;

        const onlineRouteName = `${reportsRouteName}_ONLINE`;
        const onlineLostClientsRouteName = `${onlineRouteName}_LOST_CLIENTS`;
        const onlineSitesRouteName = `${onlineRouteName}_SITES`;
        const onlineSummaryRouteName = `${onlineRouteName}_SUMMARY`;

        const callpasswordRouteName = `${reportsRouteName}_CALLPASSWORD`;
        const callpasswordSummaryRouteName = `${callpasswordRouteName}_SUMMARY`;

        const organizationRouteName = `${reportsRouteName}_ORGANIZATIONS`;
        const organizationRegRouteName = `${organizationRouteName}_REG`;
        const organizationUnfinishedReg = `${organizationRouteName}_UNFINISHED_REG`
        const organizationActivity = `${organizationRouteName}_ACTIVITY`;


        const agentRouteName = `${reportsRouteName}_AGENT`;
        const agentClientsRouteName = `${agentRouteName}_CLIENTS`;
        const agentListRouteName = `${agentRouteName}_AGENTS`;

        const marketingRouteName = `${reportsRouteName}_MARKETING`;
        const marketingDiscountCouponRedeemRouteName = `${marketingRouteName}_DISCOUNT_COUPON_REDEEM`;

        let result: RouteRecordRaw = {
            path: "reports",
            name: reportsRouteName,
            children: [
                {
                    path: "finance",
                    name: financeRouteName,
                    meta: {
                        access: [
                            () => AccountStore.access.value.checkAccessForDealerFinReports(),
                        ]
                    } as IRouteMeta,
                    children: [
                        {
                            path: "payments",
                            name: financePaymentsRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report-fin" */ '~/cabinet/vue/dealer/report/fin/payments/PaymentsReport.vue'),
                            meta: {
                                access: [
                                    () => AccountStore.isNewDoc.value
                                ]
                            } as IRouteMeta
                        },
                        {
                            path: "profit",
                            name: financeProfitRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report-fin" */ '~/cabinet/vue/dealer/report/fin/profit/ProfitReport.vue'),
                            meta: {
                                access: [
                                    () => AccountStore.isNewDoc.value
                                ]
                            } as IRouteMeta
                        },
                        {
                            path: "credits",
                            name: financeCreditRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report-fin" */ '~/cabinet/vue/dealer/report/fin/credit/CreditReport.vue'),
                            meta: {
                                access: [
                                    () => AccountStore.isNewDoc.value
                                ]
                            } as IRouteMeta
                        },
                        {
                            path: "salary",
                            name: financeSalaryRouteName,
                            meta: {
                                access: [ACCESS_SUPERDEALER]
                            },
                            component: () => import(/* webpackChunkName: "dealer-report-fin" */ '~/cabinet/vue/dealer/report/fin/SalaryReport.vue'),
                        }
                    ]
                },
                {
                    path: "budget",
                    name: budgetRouteName,
                    meta: {
                        access: [
                            () => AccountStore.isSuperDealer.value && AccountStore.access.value.isAdmin
                        ]
                    } as IRouteMeta,
                    children: [
                        {
                            path: "expense",
                            name: budgetExpenseRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/interface/CommonSummaryReport.vue'),
                            props: {
                                reportId: "budgetExpense"
                            }
                        },
                        {
                            path: "summary",
                            name: budgetSummaryRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/interface/CommonSummaryReport.vue'),
                            props: {
                                reportId: "budgetSummary",
                                pieCharts: false
                            }
                        },
                    ]
                },
                {
                    path: "organization",
                    name: organizationRouteName,
                    children: [
                        {
                            name: organizationRegRouteName,
                            path: "registation",
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/interface/CommonSummaryReport.vue'),
                            props: {
                                reportId: "organizationReg"
                            }
                        },
                        {
                            name: organizationUnfinishedReg,
                            path: "unfinishedRegistration",
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/dealer/report/organization/UnfinishedRegistration.vue'),

                        },
                        {
                            path: "activity",
                            name: organizationActivity,
                            component: () => import(/* webpackChunkName: "dealer-start" */ '~/cabinet/vue/dealer/start/DealerStart.vue'),
                            meta: {
                                access: () => AccountStore.access.value.checkAccessForDealerModifyContent()
                            } as IRouteMeta
                        }
                    ]
                },
                {
                    path: "agent",
                    name: agentRouteName,
                    children: [
                        {
                            name: agentListRouteName,
                            path: "agentList",
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/dealer/report/agent/AgentList.vue')
                        },
                        {
                            name: agentClientsRouteName,
                            path: "clients",
                            component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/client/agent/AgentClients.vue'),
                            props: {
                                forDealer: true
                            }
                        }
                    ]
                },
                {
                    path: "marketing",
                    name: marketingRouteName,
                    children: [
                        {
                            name: marketingDiscountCouponRedeemRouteName,
                            path: "discountCouponRedeem",
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/dealer/report/marketing/discountCouponRedeem/DiscountCouponRedeem.vue')
                        }
                    ]
                },
                {
                    path: "online",
                    name: onlineRouteName,
                    children: [
                        {
                            path: "summary",
                            name: onlineSummaryRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/interface/CommonSummaryReport.vue'),
                            props: {
                                reportId: "onlineChatSummary"
                            }
                        },
                        {
                            path: "lostClients",
                            name: onlineLostClientsRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report-online" */ '~/cabinet/vue/dealer/report/online/LostClientsReport.vue'),
                        },
                        {
                            path: "sites",
                            name: onlineSitesRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report-online" */ '~/cabinet/vue/dealer/report/online/SitesReport.vue'),
                        },
                    ]
                },
                {
                    path: "callpassword",
                    name: callpasswordRouteName,
                    children: [
                        {
                            path: "summary",
                            name: callpasswordSummaryRouteName,
                            component: () => import(/* webpackChunkName: "dealer-report" */ '~/cabinet/vue/interface/CommonSummaryReport.vue'),
                            props: {
                                reportId: "callpasswordSummary"
                            },
                            meta: {
                                access: () => AccountStore.isSuperDealer.value
                            } as IRouteMeta
                        }
                    ]
                }
            ],
            meta: {
                menu: [
                    (new MenuItem(financeRouteName, "Финансы", "ruble-sign"))
                        .addChildrenItem(new MenuItem(financePaymentsRouteName, "Платежи"))
                        .addChildrenItem(new MenuItem(financeProfitRouteName, "Прибыль"))
                        .addChildrenItem(new MenuItem(financeCreditRouteName, "Обещанные платежи"))
                        .addChildrenItem(new MenuItem(financeSalaryRouteName, "Зарплата")),
                    (new MenuItem(budgetRouteName, "Бюджет", "coins"))
                        .addChildrenItem(new MenuItem(budgetExpenseRouteName, "Расходы"))
                        .addChildrenItem(new MenuItem(budgetSummaryRouteName, "Сводный отчёт")),
                    (new MenuItem(organizationRouteName, "Организации", "users"))
                        .addChildrenItem(new MenuItem(organizationRegRouteName, "Регистрации"))
                        .addChildrenItem(new MenuItem(organizationUnfinishedReg, "Незавершенные регистрации"))
                        .addChildrenItem(new MenuItem(organizationActivity, "Активность")),
                    (new MenuItem(agentRouteName, "Партнерство", "handshake"))
                        .addChildrenItem(new MenuItem(agentListRouteName, "Партнёры"))
                        .addChildrenItem(new MenuItem(agentClientsRouteName, "Клиенты")),
                    (new MenuItem(onlineRouteName, "Online-чат", "comments"))
                        .addChildrenItem(new MenuItem(onlineSummaryRouteName, "Сводный отчёт"))
                        .addChildrenItem(new MenuItem(onlineLostClientsRouteName, "Непродленные лицензии"))
                        .addChildrenItem(new MenuItem(onlineSitesRouteName, "Сайты")),
                    (new MenuItem(marketingRouteName, "Маркетинг", "funnel-dollar"))
                        .addChildrenItem(new MenuItem(marketingDiscountCouponRedeemRouteName, "Скидочные купоны"))
                ]
            } as IRouteMeta
        };
        let meta: IRouteMeta = result.meta;
        for (let usluga of AccountStore.availableUslugiList.value) {
            if (usluga.isSender) {
                let uslugaRouteName = reportsRouteName + "_" + usluga.id;

                let senderRoutesConfig = this.getSenderReports(usluga, uslugaRouteName);
                let providerRouteName = uslugaRouteName + "_provider";

                let routeChildrens = senderRoutesConfig.routeConfig.children;

                routeChildrens.push({
                    path: "provider",
                    name: providerRouteName,
                    component: () => import(/* webpackChunkName: "dealer-reports" */ '~/cabinet/vue/dealer/report/sender/provider/SenderProviderReportForm.vue'),
                    props: {
                        uslugaId: usluga.id
                    },
                    meta: {
                        access: [ACCESS_SUPERDEALER]
                    } as IRouteMeta
                });
                let menu = senderRoutesConfig.menu;
                menu.addChildrenItem(
                    new MenuItem(providerRouteName, "Провайдеры")
                );

                result.children.push(senderRoutesConfig.routeConfig);

                if (usluga.id == USLUGA_ID_PAGER) {
                    this.addSmsReports(routeChildrens, menu);
                } else if (usluga.id == USLUGA_ID_VIBER) {
                    this.addViberReports(routeChildrens, menu);
                }

                meta.menu.push(menu);
            }
        }

        meta.menu.push(
            (new MenuItem(callpasswordRouteName, "CallPassword", "phone-laptop"))
                .addChildrenItem(new MenuItem(callpasswordSummaryRouteName, "Сводный отчёт"))
        );

        let moderationRouteName = reportsRouteName + "_moderation";
        let moderationTasksRouteName = moderationRouteName + "_tasks";
        let moderationWorkDayRouteName = moderationRouteName + "_workday";

        result.children.push({
            name: moderationRouteName,
            path: "moderation",
            meta: {
                access: [ACCESS_SUPERDEALER]
            } as IRouteMeta,
            children: [
                {
                    name: moderationTasksRouteName,
                    path: "tasks",
                    component: () => import(/* webpackChunkName: "dealer-reports" */ '~/cabinet/vue/dealer/report/sender/moderate/ModerationTaskReport.vue'),
                },
                {
                    name: moderationWorkDayRouteName,
                    path: "workday",
                    component: () => import(/* webpackChunkName: "dealer-reports" */ '~/cabinet/vue/dealer/report/sender/moderate/ModerationWorkdayReport.vue')
                }
            ]
        });
        meta.menu.push(
            (new MenuItem(moderationRouteName, "Модерации", "comment-check"))
                .addChildrenItem(new MenuItem(moderationTasksRouteName, "Список модераций"))
                .addChildrenItem(new MenuItem(moderationWorkDayRouteName, "Рабочий график"))
        );

        let eventsRouteName = reportsRouteName + "_events";
        let eventsConversionRouteName = reportsRouteName + "_conversion";

        result.children.push({
            name: eventsRouteName,
            path: "events",
            children: [
                {
                    name: eventsConversionRouteName,
                    path: "conversion",
                    component: () => import(/* webpackChunkName: "dealer-reports" */ '~/cabinet/vue/dealer/report/events/conversion/EventsConversion.vue'),
                }
            ]
        });

        meta.menu.push(
            (new MenuItem(eventsRouteName, "События", "bullhorn"))
                .addChildrenItem(new MenuItem(eventsConversionRouteName, "Конверсии"))
        );

        Section.createForRoute(result, __("Отчеты"), "chart-area");
        return result;
    }

    private addSmsReports(routes: RouteRecordRaw[], menu: MenuItem) {
        let sourceLicenseRouteName = "dealer_reports_sms_source_license";
        routes.push(this.makeLicenseReport({
            path: "paidSources",
            name: sourceLicenseRouteName
        } as RouteRecordRaw, LICENSER_ID_SMS_SOURCE_REPLACE, AccountStore.access.value.checkDealerOwnsUsluga(USLUGA_ID_PAGER)));
        menu.addChildrenItem(new MenuItem(sourceLicenseRouteName, "Платные имена отправителя"));
    }

    private addViberReports(routes: RouteRecordRaw[], menu: MenuItem) {
        let sourceLicenseRouteName = "dealer_reports_viber_source_license";
        routes.push(this.makeLicenseReport({
            path: "sources",
            name: sourceLicenseRouteName
        } as RouteRecordRaw, LICENSER_ID_VIBER_SOURCE, AccountStore.isSuperDealer.value));
        menu.addChildrenItem(new MenuItem(sourceLicenseRouteName, "Имена отправителя"));
    }

    private makeLicenseReport(route: RouteRecordRaw, licenserClassId: string, showLicenseListButton: boolean): RouteRecordRaw {
        route.props = {
            classId: licenserClassId,
            showLicenseListButton
        };
        route.component = () => import(/* webpackChunkName: "dealer-reports" */ '~/cabinet/vue/dealer/report/license/LicenseReport.vue');

        return route;
    }

    public static getOrgCardRoute(ocode: string, routeName: string = DEALER_ORG_CARD_MAIN_NAME, additionalParams?: Dictionary<string>): RouteLocationNamedRaw {
        let params: Dictionary<string> = {ocode};
        if (additionalParams) {
            params = {...params, ...additionalParams};
        }
        return {name: routeName, params};
    }

    public static getOrgPaymentRoute(ocode: string, alfabank?: AlfaBankInterface): RouteLocationNamedRaw {
        return {
            name: DEALER_PAY_ORG_REAL,
            params: {
                ocode,
                alfabankId: alfabank ? alfabank.id : undefined
            }
        };
    }

    private getPay(): RouteRecordRaw {
        const CORRECT = DEALER_PAY_ORG + "_correct";
        const BACK = DEALER_PAY_ORG + "_back";


        let result: RouteRecordRaw = {
            path: "pay",
            name: DEALER_PAY,
            component: () => import(/* webpackChunkName: "dealer-pay" */ '~/cabinet/vue/dealer/pay/PaySearchForm.vue'),
            children: [
                {
                    path: ":ocode",
                    name: DEALER_PAY_ORG,
                    component: () => import(/* webpackChunkName: "dealer-pay" */ '~/cabinet/vue/dealer/pay/PayForm.vue'),
                    props: p => ({
                        ocode: p.params.ocode
                    }),
                    meta: {
                        menu: [
                            (new MenuItem(BACK, "Назад")).setIcon("reply"),
                            (new MenuItem(DEALER_PAY_ORG_REAL, "Провести платёж")),
                            (new MenuItem(CORRECT, "Корректировка баланса"))
                        ]
                    } as IRouteMeta,
                    children: [
                        {
                            name: DEALER_PAY_ORG_REAL,
                            path: "real/:alfabankId?",
                            component: () => import(/* webpackChunkName: "dealer-pay" */ '~/cabinet/vue/dealer/pay/RealPayForm.vue'),
                            props: (p) => ({
                                alfabankId: p.params.alfabankId
                            })
                        },
                        {
                            name: CORRECT,
                            path: "correct",
                            component: () => import(/* webpackChunkName: "dealer-pay" */ '~/cabinet/vue/dealer/pay/CorrectForm.vue'),
                        }
                    ]
                },
                {
                    name: BACK,
                    path: "back",
                    beforeEnter:  (to, from, next) => {
                        next({
                            name: DEALER_PAY
                        });
                    }
                } as RouteRecordRaw
            ],
            meta: {
                access: () => AccountStore.isNewDoc.value,
                menu: [
                    new MenuItem(DEALER_PAY, "Поиск для оплаты", "search")
                ]
            } as IRouteMeta
        };

        Section.createForRoute(result, "Оплата", "ruble-sign");
        return result;
    }

    private getStart(): RouteRecordRaw {
        let routeName = "DEALER_START";

        let result = DashboardRouteBuilder.getRouteConfig(routeName, () => 'dealer');

        Section.createForRoute(result, "Сводка", "chart-pie");
        return result;
    }

    private getOrgs(): RouteRecordRaw {
        const orgsRouteName = "DEALER_ORGS";
        const cardRouteName = orgsRouteName + "_CARD";
        const cardRootRouteName = DEALER_ORG_CARD_NAME;
        const cardBackRouteName = cardRouteName + "_BACK";
        const cardMainRouteName = DEALER_ORG_CARD_MAIN_NAME;
        const cardPayRouteName = cardRouteName + "_PAY";

        const cardContactsRouteName = cardRouteName + "_CONTACTS";

        const cardDocumentsRouteName = cardRouteName + "_DOC";
        const cardDetailsRouteName = cardDocumentsRouteName + "_DETAILS";
        const cardContractsRouteName = cardDocumentsRouteName + "_CONTRACTS";
        const cardActRouteName = cardDocumentsRouteName + "_ACT"
        const cardAgentActRouteName = cardDocumentsRouteName + "_AGENT_ACT"
        const cardRecActRouteName = cardDocumentsRouteName + "_REC_ACT";

        const cardBillRouteName = cardDocumentsRouteName + "_BILL";

        const cardFinReport = `${cardRouteName}_FIN_REPORT`;
        const cardFinPaymentReport = `${cardFinReport}_PAYMENT`;
        const cardFinProfitReport = `${cardFinReport}_PROFIT`;
        const cardFinUslugiTasksReport = `${cardFinReport}_USLUGI_TASKS`;
        const cardFinPaymentMoveReport = `${cardFinReport}_PAYMENT_MOVE`;


        const cardTimeLineRouteName = cardRouteName + "_TIMELINE";
        const cardCommentsRouteName = cardRouteName + "_COMMENTS";
        const cardNotificationsRouteName = cardRouteName + "_NOTIFICATIONS";
        const cardConversationsRouteName = cardRouteName + "_CONVERSATIONS";
        const cardOnlineConversationsRouteName = cardConversationsRouteName + "_ONLINE";
        const cardOfflineConversationsRouteName = cardConversationsRouteName + "_OFFLINE";
        const cardOfflineMakeTicketRouteName = cardOfflineConversationsRouteName + "_MAKE_TICKET";

        const cardCallsConversationsRouteName = cardConversationsRouteName + "_CALLS";

        const cardAgentRouteName = cardRouteName + "_AGENT";
        const cardAgentClients = cardAgentRouteName + "_CLIENTS";
        const cardAgentRewards = cardAgentRouteName + "_REWARDS";
        const cardAgentHandlerType = cardAgentRouteName + "_HANDLER_TYPE";

        const cardDiscountCouponRouteName = cardRouteName + "_DISCOUNT_COUPON";


        let lastOcode: string;

        const agentClientsRouteConfig: RouteRecordRaw = {
            path: "clients",
            name: cardAgentClients,
            component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/client/agent/AgentClients.vue'),
            props: route => ({
                forDealer: true,
                ocode: route.params.ocode
            })
        };

        let cardRoutes: RouteRecordRaw[] = [
            {
                path: "main",
                name: cardMainRouteName,
                component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/MainSection.vue')
            },
            {
                path: "contacts",
                name: cardContactsRouteName,
                component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/contacts/Contacts.vue')
            },
            {
                path: "pay",
                name: cardPayRouteName,
                redirect: route => {
                    return DealerRoutesBuilder.getOrgPaymentRoute(route.params.ocode as string);
                }
            },
            {
                path: "timeline",
                name: cardTimeLineRouteName,
                component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/timeLine/TimeLineSection.vue')
            },
            {
                path: "comments",
                name: cardCommentsRouteName,
                component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/comments/CommentsSection.vue')
            },
            {
                path: "notifications",
                name: cardNotificationsRouteName,
                component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/notifications/NotificationsSection.vue')
            },
            {
                path: "conversations",
                name: cardConversationsRouteName,
                children: [
                    {
                        path: "chat",
                        name: cardOnlineConversationsRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/conversations/OnlineSection.vue')
                    },
                    {
                        path: "offline",
                        name: cardOfflineConversationsRouteName,
                        redirect: (to) => {
                            return DealerRoutesBuilder.getSupportLocation(to.params.ocode as string);
                        }
                    },
                    {
                        path: "makeSupportTicket",
                        name: cardOfflineMakeTicketRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/conversations/MakeSupportTicket.vue')
                    },
                    {
                        path: "calls",
                        name: cardCallsConversationsRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/conversations/CallsSection.vue')
                    }
                ]
            },
            {
                path: "agent",
                name: cardAgentRouteName,
                component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/agent/DealerOrgCardAgentWrapper.vue'),
                children: [
                    agentClientsRouteConfig,
                    {
                        path: "rewards",
                        name: cardAgentRewards,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/client/agent/AgentRewardsReport.vue'),
                        props: route => ({
                            forDealer: true,
                            ocode: route.params.ocode
                        })
                    },
                    {
                        name: cardAgentHandlerType,
                        path: "tasks/:typeId",
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/client/agent/AgentHandlerEntities.vue'),
                        props: route => ({
                            type: AccountStore.data.value.allAgentHandlerTypes.find(item => item.id == route.params.typeId),
                            forDealer: true,
                            ocode: route.params.ocode
                        })
                    }
                ],
                meta: {
                    redirectToChildren: [
                        agentClientsRouteConfig
                    ]
                } as IRouteMeta
            },
            {
                path: "discountCoupon",
                name: cardDiscountCouponRouteName,
                component: () => import(/* webpackChunkName: "dealer-marketing" */ '~/cabinet/vue/dealer/config/marketing/discountCoupon/DiscountCoupon.vue'),
                props: route => ({
                    ocode: route.params.ocode
                })
            },
            {
                path: "documents",
                name: cardDocumentsRouteName,
                children: [
                    {
                        path: "acts",
                        name: cardActRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/documents/act/ActSection.vue'),
                        meta: {
                            access: () => AccountStore.isNewDoc.value
                        } as IRouteMeta
                    },
                    {
                        path: "bill",
                        name: cardBillRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/documents/bill/BillSection.vue'),
                        meta: {
                            access: () => AccountStore.isNewDoc.value
                        } as IRouteMeta
                    },
                    {
                        path: "contracts",
                        name: cardContractsRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/documents/contracts/ContractsSection.vue'),
                    },
                    {
                        path: "details",
                        name: cardDetailsRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/documents/details/DetailsSection.vue')
                    },
                    {
                        path: "recActs",
                        name: cardRecActRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/documents/recAct/RecActSection.vue'),
                        meta: {
                            access: () => AccountStore.isNewDoc.value
                        } as IRouteMeta
                    },
                    {
                        path: "agentActs",
                        name: cardAgentActRouteName,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/documents/agentAct/AgentActSection.vue')
                    }
                ]
            },
            {
                path: "financeReports",
                name: cardFinReport,
                meta: {
                    access: () => AccountStore.isNewDoc.value
                } as IRouteMeta,
                children: [
                    {
                        path: "payment",
                        name: cardFinPaymentReport,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/finReports/payment/OrgPaymentReportSection.vue'),
                    },
                    {
                        path: "uslugi",
                        name: cardFinUslugiTasksReport,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/finReports/uslugi/OrgUslugiTasksSection.vue'),
                    },
                    {
                        path: "paymentMove",
                        name: cardFinPaymentMoveReport,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/finReports/balanceMove/OrgBalanceMoveReportSection.vue'),
                    },
                    {
                        path: "profit",
                        name: cardFinProfitReport,
                        component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/finReports/profit/OrgProfitReportSection.vue'),
                    },
                ]
            }
        ];

        let cardMenu: MenuItem[] = [
            (new MenuItem(cardBackRouteName, __("Назад"))).setIcon("reply"),
            (new MenuItem(cardMainRouteName, __("Основная информация"))).setIcon("address-card"),
            (new MenuItem(cardPayRouteName, "Зачислить платёж")).setIcon("ruble-sign"),
            (new MenuItem(cardContactsRouteName, "Контакты")).setIcon("users"),
            (new MenuItem(cardFinReport, "Финансовые отчёты", "chart-line"))
                .addChildrenItem(new MenuItem(cardFinPaymentReport, "Платежи"))
                .addChildrenItem(new MenuItem(cardFinUslugiTasksReport, "Оказанные услуги"))
                .addChildrenItem(new MenuItem(cardFinPaymentMoveReport, "Движение денежных средств"))
                .addChildrenItem(new MenuItem(cardFinProfitReport, "Прибыль")),
            (new MenuItem(cardDocumentsRouteName, "Документы").setIcon("file-alt"))
                .addChildrenItem(new MenuItem(cardActRouteName, "Акты"))
                .addChildrenItem(new MenuItem(cardBillRouteName, "Счета"))
                .addChildrenItem(new MenuItem(cardContractsRouteName, "Договоры"))
                .addChildrenItem(new MenuItem(cardDetailsRouteName, "Реквизиты"))
                .addChildrenItem(new MenuItem(cardRecActRouteName, "Акты сверки"))
                .addChildrenItem(new MenuItem(cardAgentActRouteName, "Агентские акты")),
            (new MenuItem(cardTimeLineRouteName, "События").setIcon("clock")),
            (new MenuItem(cardCommentsRouteName, "Комментарии").setIcon("comment-lines")),
            (new MenuItem(cardNotificationsRouteName, "Уведомления").setIcon("bullhorn")),

            (new MenuItem(cardConversationsRouteName, "Общение"))
                .addChildrenItem(new MenuItem(cardOnlineConversationsRouteName, "Онлайн-чат"))
                .addChildrenItem(
                    (new MenuItem(cardOfflineConversationsRouteName, "Техподдержка"))
                        .setBadge(new MenuBadge("organizationCardOfflineQuestions", () => ({
                            ocode: lastOcode
                        })))
                )
                .addChildrenItem(new MenuItem(cardOfflineMakeTicketRouteName, "Написать в техподдержку"))
                .addChildrenItem(new MenuItem(cardCallsConversationsRouteName, "Звонки"))
                .setIcon("comments"),
            (new MenuItem(cardAgentRouteName, "Партнёрство", "handshake"))
                .addChildrenItem(new MenuItem(cardAgentClients, "Клиенты"))
                .addChildrenItem(new MenuItem(cardAgentRewards, "Вознаграждения"))
                .addChildrenItem(() => {
                    let result: MenuItem[] = [];
                    for (let type of AccountStore.data.value.allAgentHandlerTypes) {
                        result.push(
                            (new MenuItem(cardAgentHandlerType, type.descr))
                                .addRouteParam('typeId', type.id)
                        );
                    }
                    return result;
                }),
            new MenuItem(cardDiscountCouponRouteName, "Скидочные купоны", "percent"),
            new MenuItemDelimiter()

        ];

        for (let usluga of AccountStore.availableUslugiList.value) {
            let uslugaRouteName = cardRouteName + "_" + usluga.id;
            if (usluga.id == USLUGA_ID_ONLINE) {
                let result = this.getOrgCardOnlineRoutes(usluga);
                cardRoutes.push(result.route);
                cardMenu.push(result.menu);
            } else if (usluga.isSender) {
                let senderRoutesConfig = this.getSenderReports(usluga, uslugaRouteName);

                cardRoutes.push(senderRoutesConfig.routeConfig);
                cardMenu.push(senderRoutesConfig.menu);
            }
        }


        let result: RouteRecordRaw = {
            path: "orgs",
            name: orgsRouteName,
            children: [
                {
                    path: "card",
                    name: cardRouteName,
                    component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/SearchOrgCard.vue'),
                    props: {
                        cardRootRouteName: cardMainRouteName,
                        routeNameForCheckDisplayed: cardRootRouteName
                    },
                    children: [
                        {
                            path: ":ocode",
                            name: cardRootRouteName,

                            props: (route => ({
                                ocode: route.params.ocode
                            })),
                            component: () => import(/* webpackChunkName: "dealer-orgs" */ '~/cabinet/vue/dealer/orgs/card/OrgCardRoot.vue'),
                            children: cardRoutes,
                            meta: {
                                menu: cardMenu
                            } as IRouteMeta,
                            beforeEnter: (to, from, next) => {
                                lastOcode = to.params.ocode as string;
                                next();
                            }
                        }

                    ]
                },
                {
                    name: cardBackRouteName,
                    path: "back",
                    beforeEnter: (to, from, next) => {
                        next({
                            name: cardRouteName
                        });
                    }
                } as RouteRecordRaw
            ],
            meta: {
                menu: [
                    (new MenuItem(cardRouteName, __("Карточки организаций"))).setIcon("th")
                ]
            } as IRouteMeta
        };

        Section.createForRoute(result, __("Организации"), "users");
        return result;
    }

    private getOrgCardOnlineRoutes(usluga: IUsluga): { menu: MenuItem, route: RouteRecordRaw } {
        let routeName = "ORG_CARD_ONLINE";
        let createPayUrlRoute = `${routeName}_CREATE_PAY_URL`;
        let licenseRoute = `${routeName}_LICENSE_LIST`;
        let route: RouteRecordRaw = {
            name: routeName,
            path: USLUGA_ID_ONLINE,
            children: [
                {
                    name: licenseRoute,
                    path: "licenseList",
                    component: () => import(/* webpackChunkName: "dealer-org-card-online" */ '~/cabinet/vue/dealer/orgs/card/online/LicenseList.vue'),
                    meta: {
                        access: [ACCESS_SUPERDEALER]
                    } as IRouteMeta
                },
                {
                    name: createPayUrlRoute,
                    path: "createPayUrl",
                    component: () => import(/* webpackChunkName: "dealer-org-card-online" */ '~/cabinet/vue/dealer/orgs/card/online/CreatePayUrl.vue'),
                    meta: {
                        access: [ACCESS_SUPERDEALER]
                    } as IRouteMeta
                }
            ]
        };
        let menu = (new MenuItem(routeName, usluga.descr, usluga.icon))
            .addChildrenItem(new MenuItem(licenseRoute, "Лицензии"))
            .addChildrenItem(new MenuItem(createPayUrlRoute, "Создание ссылки на оплату"));

        return {menu, route};
    }

    private getTasks(): RouteRecordRaw {
        let tasksRouteName = "DEALER_TASKS";


        const callsRouteName = `${tasksRouteName}_CALLS`;
        const documentsRouteName = `${tasksRouteName}_DOCUMENTS`;
        const detailsRouteName = `${documentsRouteName}_DETAILS`;
        const contractRequestRouteName = `${documentsRouteName}_CONTRACT_REQUEST`;
        const documentScanRouteName = `${documentsRouteName}_DOCUMENT_SCAN`;
        const agentRouteName = `${tasksRouteName}_AGENT`;
        const agentRewardsRouteName = `${agentRouteName}_REWARDS`;
        const senderModerationRouteName = `${tasksRouteName}_SENDER_MODERATION`;
        const dealerRequestRouteName = "DEALER_REQUEST_LIST";
        const knowledgeBaseDomainRequestRouteName = "KNOWLEDGE_BASE_DOMAIN_REQUEST_LIST";

        let menu: MenuItem[] = [
            (new MenuItem(callsRouteName, "Звонки", "phone-volume"))
                .setBadge(new MenuBadge("dealerTaskCalls")),
            (new MenuItem(documentsRouteName, "Документы", "file-alt"))
                .addChildrenItem(
                    (new MenuItem(detailsRouteName, "Реквизиты на проверку"))
                        .setBadge(new MenuBadge("dealerTaskOrgDetails"))
                )
                .addChildrenItem(
                    (new MenuItem(contractRequestRouteName, "Заявки на договор"))
                        .setBadge(new MenuBadge("dealerTaskContractRequest"))
                )
                .addChildrenItem(
                    (new MenuItem(documentScanRouteName, "Сканы документов"))
                        .setBadge(new MenuBadge("dealerTaskDocumentScan"))
                ),
            (new MenuItem(agentRouteName, "Партнёрство", "handshake"))
                .addChildrenItem(
                    (new MenuItem(agentRewardsRouteName, "Заявки на вывод вознаграждений"))
                        .setBadge(new MenuBadge("dealerTaskAgentRewards"))
                ),
            (new MenuItem(senderModerationRouteName, "Модерация", "check"))
                .setBadge((new MenuBadge("dealerTaskModeration")).setIsUsedInParent(false)),
            (new MenuItem(dealerRequestRouteName, "Заявки на дилерство", "sitemap"))
                .setBadge(new MenuBadge("dealerRequest")),
            (new MenuItem(knowledgeBaseDomainRequestRouteName, "Домен для базы знаний", "books"))
                .setBadge(new MenuBadge("knowledgeBaseDomainRequest"))
        ];

        let result: RouteRecordRaw = {
            path: "tasks",
            name: tasksRouteName,
            meta: {
                menu
            } as IRouteMeta,
            children: [
                {
                    name: callsRouteName,
                    path: "calls",
                    component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/calls/CallsList.vue'),
                },
                {
                    name: documentsRouteName,
                    path: "documents",
                    children: [
                        {
                            name: detailsRouteName,
                            path: "details",
                            component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/documents/ClientDetailsList.vue'),
                            meta: {
                                access: () => AccountStore.access.value.checkAccessForDealerDoc()
                            } as IRouteMeta
                        },
                        {
                            name: contractRequestRouteName,
                            path: "contractRequest",
                            component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/documents/ContractRequestList.vue'),
                            meta: {
                                access: () => AccountStore.access.value.checkAccessForDealerDoc()
                            } as IRouteMeta
                        },
                        {
                            name: documentScanRouteName,
                            path: "scan",
                            component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/documents/ScanList.vue'),
                            meta: {
                                access: () => AccountStore.access.value.checkAccessForDealerDoc()
                            } as IRouteMeta
                        }
                    ]
                },
                {
                    name: agentRouteName,
                    path: "agent",
                    children: [
                        {
                            name: agentRewardsRouteName,
                            path: "rewards",
                            component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/client/agent/AgentRewardsReport.vue'),
                            props: {
                                forDealer: true,
                                isWaiting: true
                            }
                        }
                    ]
                },
                {
                    name: senderModerationRouteName,
                    path: "senderModeration",
                    component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/sender/moderation/SenderModeration.vue'),
                    meta: {
                        access: () => AccountStore.isSuperDealer.value
                    } as IRouteMeta
                },
                {
                    name: dealerRequestRouteName,
                    path: "dealerRequest",
                    component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/dealerRequest/DealerRequestList.vue'),
                    meta: {
                        access: () => AccountStore.isSuperDealer.value
                    } as IRouteMeta
                },
                {
                    name: knowledgeBaseDomainRequestRouteName,
                    path: "knowledgeBaseDomain",
                    component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/knowledgeBaseDomainRequest/KnowledgeBaseDomainRequestList.vue'),
                    meta: {
                        access: () => AccountStore.isSuperDealer.value
                    } as IRouteMeta
                }
            ]
        };


        for (let usluga of AccountStore.senderList.value) {
            let uslugaId = usluga.id;
            let uslugaRouteName = `${tasksRouteName}_${uslugaId}`;
            let serviceTemplatesName = uslugaRouteName + "_SERVICE_TEMPLATES";
            let moderationTemplatesName = uslugaRouteName + "_MODERATION_TEMPLATES";


            let uslugaRoute: RouteRecordRaw = {
                path: uslugaId,
                name: uslugaRouteName,
                children: []
            };
            result.children.push(uslugaRoute);

            let uslugaMenu = new MenuItem(uslugaRouteName, usluga.descr, usluga.icon);
            menu.push(uslugaMenu);

            let source = this.tasksSenderSource(uslugaId);
            if (source) {
                uslugaMenu.addChildrenItem(source.menu);
                uslugaRoute.children.push(source.route);
            }


            uslugaRoute.children.push({
                path: "serviceTemplates",
                name: serviceTemplatesName,
                component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/sender/ServiceTemplateApprove.vue'),
                props: {
                    uslugaId: uslugaId
                },
                meta: {
                    access: [
                        "usluga_isAvailableMessageTemplateService_" + uslugaId,
                        () => AccountStore.access.value.checkDealerConfigOwn(uslugaId)
                    ]
                } as IRouteMeta
            });
            uslugaMenu.addChildrenItem(
                (new MenuItem(serviceTemplatesName, "Сервисные шаблоны"))
                    .setBadge(new MenuBadge(`dealerTaskServiceTemplates_${uslugaId}`))
            );

            uslugaRoute.children.push({
                path: "moderationTemplates",
                name: moderationTemplatesName,
                component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/sender/ModerationTemplateApprove.vue'),
                meta: {
                    access: [ACCESS_SUPERDEALER, `usluga_isAvailableMessageModerateTemplate_${uslugaId}`]
                } as IRouteMeta,
                props: {
                    uslugaId: uslugaId
                }
            });

            uslugaMenu.addChildrenItem((new MenuItem(moderationTemplatesName, "Шаблоны модерации"))
                .setBadge(new MenuBadge(`dealerTaskModerationTemplates_${uslugaId}`)));


            if (uslugaId == USLUGA_ID_VOICE) {
                let voiceContentRouteName = uslugaRouteName + "_CONTENT";

                uslugaMenu.addChildrenItem(
                    (new MenuItem(voiceContentRouteName, __("Заказ озвучки")))
                        .setBadge(new MenuBadge("dealerTaskVoiceContent"))
                );

                uslugaRoute.children.push({
                    path: "content",
                    name: voiceContentRouteName,
                    component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/voice/ContentOrder.vue'),
                    meta: {
                        access: [ACCESS_SUPERDEALER]
                    } as IRouteMeta
                });
            } else if (uslugaId == USLUGA_ID_PAGER) {
                let pagerRouteSourceReplaceName = uslugaRouteName + "_REPLACE_SOURCE";

                uslugaMenu.addChildrenItem(
                    (new MenuItem(pagerRouteSourceReplaceName, "Платные имена отправителей"))
                        .setBadge(new MenuBadge("dealerTaskPagerReplaceSource"))
                );

                uslugaRoute.children.push({
                    path: "replaceSource",
                    name: pagerRouteSourceReplaceName,
                    component: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/pager/PagerDealerOpsosSources.vue'),
                    meta: {
                        access: () => AccountStore.access.value.checkDealerOwnsUsluga(USLUGA_ID_PAGER)
                    } as IRouteMeta
                });
            }


        }


        Section.createForRoute(result, __("Задачи"), "tasks");

        return result;
    }

    private tasksSenderSource(uslugaId: string): { route: RouteRecordRaw, menu: MenuItem } {
        let source = SenderUslugi[uslugaId].source;
        let component = source ? source.dealersApproveComponent : null;
        if (component) {
            let route = "DEALER_TASKS_SENDER_" + uslugaId + "_SOURCES";
            let usluga = UslugaMapper.find(uslugaId) as SenderUsluga;
            return {
                route: {
                    path: "sources",
                    name: route,
                    component: component as any,
                    props: {
                        uslugaId
                    },
                    meta: usluga.source.dealersApprove ? null : {
                        access: [ACCESS_SUPERDEALER]
                    } as IRouteMeta
                },
                menu: (new MenuItem(route, __("Имена отправителя")))
                    .setBadge(new MenuBadge("dealerTaskSenderSource", {uslugaId: uslugaId}))
            };
        }
        return null;
    }

    private getMarketing(): { route: RouteRecordRaw, menu: MenuItem } {
        let routeName = "DEALER_CONFIG_MARKETING";
        let actionRouteName = routeName + "_ACTION";
        let promocodeRouteName = routeName + "_PROMOCODE";
        let discountCouponRouteName = routeName + "_DISCOUNT_COUPON";

        let route: RouteRecordRaw = {
            path: "marketing",
            name: routeName,
            children: [
                {
                    path: "discountCoupon",
                    name: discountCouponRouteName,
                    component: () => import(/* webpackChunkName: "dealer-marketing" */ '~/cabinet/vue/dealer/config/marketing/discountCoupon/DiscountCoupon.vue'),
                },
                {
                    path: "promocode",
                    name: promocodeRouteName,
                    component: () => import(/* webpackChunkName: "dealer-marketing" */ '~/cabinet/vue/dealer/config/marketing/PromoCodeList.vue'),

                },
                {
                    path: ":action?",
                    name: actionRouteName,
                    component: () => import(/* webpackChunkName: "dealer-marketing" */ '~/cabinet/vue/dealer/config/marketing/actions/MarketingActionList.vue'),
                }
            ]
        };

        let menu = (new MenuItem(routeName, "Маркетинг", "funnel-dollar"))
            .addChildrenItem(new MenuItem(discountCouponRouteName, "Скидочные купоны"))
            .addChildrenItem(new MenuItem(promocodeRouteName, "Промокоды"))
            .addChildrenItem(() => {
                let result: MenuItem[] = [];
                let dealerParams = AccountStore.dealerParams.value;
                if (dealerParams) {
                    for (let action of dealerParams.marketing.actions) {
                        result.push(
                            (new MenuItem(actionRouteName, action.descr))
                                .addRouteParam("action", action.id)
                        );
                    }
                }
                return result;
            });
        return {route, menu};
    }

    private static routeNamesForNewSingleTarif: Dictionary<string> = {};

    private static routeNamesForSingleTarif: Dictionary<string> = {};

    public static getRouteSingleTarifEdit(uslugaId: string, tarifId: string, isNew?: boolean): RouteLocationNamedRaw {
        return {
            name: (isNew ? this.routeNamesForNewSingleTarif : this.routeNamesForSingleTarif)[uslugaId],
            params: {
                [TARIF_ROUTE_TARIF_ID_PARAM_NAME]: tarifId
            }
        }
    }


    public static getTarifsRouteName(uslugaId: string, isNew: boolean = false): string | null {
        if (UslugaMapper.find(uslugaId)?.dealer?.config?.tarifComponent) {
            return `DEALER_CONFIG_USLUGA_TARIFS_${uslugaId}` + (isNew ? "_new" : '');
        }
        return null;
    }

    public static getCommonUslugaSettingsRouteName(uslugaId: string) {
        return `DEALER_CONFIG_USLUGA_COMMON_${uslugaId}`;
    }

    private getEvents(): RouteRecordRaw {
        let routeName = "DEALER_EVENTS";

        let result = (new EventsRouteBuilder(routeName))
            .setShowDropdownMenu(false)
            .addIncludedPlatformId(SMS_USLUGI_PLATFORM_ID)
            .makeRoute();
        Section.createForRoute(result, "События", "bullhorn");

        return result;
    }


    private get tarifListWrapper() {
        return () => import(/* webpackChunkName: "dealer-tarif" */ '~/cabinet/vue/dealer/config/uslugi/tarif/common/TarifListWrapper.vue');
    }

    private getConfig(): RouteRecordRaw {
        const configRouteName = "DEALER_CONFIG";
        let marketing = this.getMarketing();

        const mainConfigRouteName = `${configRouteName}_MAIN`;
        const tarifLineRouteName = `${mainConfigRouteName}_TARIF_LINE`;
        const newClientsRouteName = `${mainConfigRouteName}_NEW_CLIENTS`;
        const hearRouteName = `${mainConfigRouteName}_HEAR`;

        const cabinetConfigRouteName = `${configRouteName}_CABINET`;
        const cabinetConfigSectionsRouteName = `${cabinetConfigRouteName}_sections`;
        const cabinetNewsRouteName = `${cabinetConfigRouteName}_news`;
        const cabinetLeftMenuRouteName = `${cabinetConfigRouteName}_leftMenu`
        const cabinetPagesRouteName = `${cabinetConfigRouteName}_pages`;
        const cabinetRegFormRouteName = `${cabinetConfigRouteName}_regForm`;
        const cabinetDesignRouteName = `${cabinetConfigRouteName}_design`;

        const currencyConfigRouteName = `${configRouteName}_CURRENCY`;
        const paymentSystemRouteName = configRouteName + "_paymentSystem";
        const paymentSystemItemRouteName = paymentSystemRouteName + "_item";
        const kassaSystemRouteName = configRouteName + "_kassaSystem";
        const kassaSystemItemRouteName = kassaSystemRouteName + "_item";


        let configRoutes: RouteRecordRaw[] = [
            {
                name: mainConfigRouteName,
                path: "main",
                children: [
                    {
                        name: newClientsRouteName,
                        path: "registration",
                        component: () => import(/* webpackChunkName: "dealer-main-config" */ '~/cabinet/vue/dealer/config/main/registration/DefaultRegConfig.vue'),
                    },
                    {
                        name: tarifLineRouteName,
                        path: "tarifLine",
                        component: () => import(/* webpackChunkName: "dealer-main-config" */ '~/cabinet/vue/dealer/config/main/tarifLine/TarifLineConfig.vue'),
                    },
                    {
                        name: hearRouteName,
                        path: "hear",
                        component: () => import(/* webpackChunkName: "dealer-main-config" */ '~/cabinet/vue/dealer/config/main/hear/HearConfig.vue'),
                    }
                ]
            },
            {
                name: cabinetConfigRouteName,
                path: "cabinet",
                children: [
                    {
                        name: cabinetConfigSectionsRouteName,
                        path: "sections",
                        component: () => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/cabinet/menu/DealerMenuConfig.vue'),
                    },
                    {
                        name: cabinetNewsRouteName,
                        path: "news",
                        component: () => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/cabinet/NewsConfig.vue'),
                    },
                    {
                        name: cabinetLeftMenuRouteName,
                        path: "leftMenu",
                        component: () => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/cabinet/menu/LeftMenuConfig.vue'),
                        meta: {
                            keepAlive: false
                        } as IRouteMeta
                    },
                    {
                        name: cabinetPagesRouteName,
                        path: "pages",
                        component: () => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/cabinet/PagesConfig.vue'),
                        meta: {
                            keepAlive: false
                        } as IRouteMeta
                    },
                    {
                        name: cabinetRegFormRouteName,
                        path: "registrationForm",
                        component: ConfigWithFormComponent,
                        props: (() => ({
                            typeId: `registrationForm`,
                            additionalFormComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/cabinet/regForm/RegistrationFormAdditionalFormComponent.vue'))
                        })),
                        meta: {
                            keepAlive: false
                        } as IRouteMeta
                    },
                    {
                        name: cabinetDesignRouteName,
                        path: "design",
                        component: ConfigWithFormComponent,
                        props: (() => ({
                            typeId: `cabinetDesign`
                        })),
                        meta: {
                            keepAlive: false
                        } as IRouteMeta
                    }
                ]
            },
            marketing.route,
            {
                name: currencyConfigRouteName,
                path: "currency",
                component: () => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/currency/DealerCurrencyConfig.vue'),

                meta: {
                    access: [
                        ACCESS_SUPERDEALER
                    ]
                } as IRouteMeta
            },
            {
                name: paymentSystemRouteName,
                path: "paymentSystem",
                redirect: () => ({
                    name: paymentSystemItemRouteName,
                    params: {
                        typeId: AccountStore.paymentSystemsForConfig.value.length ? `${AccountStore.paymentSystemsForConfig.value[0].id}` : "undefined",
                    }
                }),
                children: [
                    {
                        path: ":typeId",
                        name: paymentSystemItemRouteName,
                        component: ConfigWithFormComponent,
                        props: (route => ({
                            typeId: `paymentSystem`,
                            mapperParams: {
                                paymentSystemId: route.params.typeId
                            },
                            additionalButtonsComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/payment/PaymentSystemAdditionalButtonComponent.vue')),
                            additionalFormComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/payment/PaymentSystemAdditionalFormComponent.vue'))
                        })),
                        meta: {
                            keepAlive: false
                        } as IRouteMeta
                    }
                ]
            },
            {
                name: kassaSystemRouteName,
                path: "kassaSystem",
                redirect: () => ({
                    name: kassaSystemItemRouteName,
                    params: {
                        typeId: AccountStore.kassaSystemsForConfig.value.length ? `${AccountStore.kassaSystemsForConfig.value[0].id}` : "undefined",
                    }
                }),
                children: [
                    {
                        path: ":typeId",
                        name: kassaSystemItemRouteName,
                        component: ConfigWithFormComponent,
                        props: (route => ({
                            typeId: `kassaSystem`,
                            mapperParams: {
                                kassaSystemId: route.params.typeId
                            },
                        })),
                        meta: {
                            keepAlive: false
                        } as IRouteMeta
                    }
                ]
            }
        ];


        let configMenu: MenuItem[] = [
            (new MenuItem(mainConfigRouteName, "Основные настройки", "cogs"))
                .addChildrenItem(new MenuItem(newClientsRouteName, "Новые клиенты"))
                .addChildrenItem(new MenuItem(tarifLineRouteName, "Тарифные линии"))
                .addChildrenItem(new MenuItem(hearRouteName, "Источники регистраций")),
            (new MenuItem(cabinetConfigRouteName, "Кабинет", "sitemap"))
                .addChildrenItem(new MenuItem(cabinetConfigSectionsRouteName, "Разделы кабинета"))
                .addChildrenItem(new MenuItem(cabinetNewsRouteName, "Блоги"))
                .addChildrenItem(new MenuItem(cabinetLeftMenuRouteName, "Левое меню"))
                .addChildrenItem(new MenuItem(cabinetPagesRouteName, "Информационные страницы"))
                .addChildrenItem(new MenuItem(cabinetRegFormRouteName, "Форма регистрации"))
                .addChildrenItem(new MenuItem(cabinetDesignRouteName, "Другие настройки кабинета")),
            marketing.menu,
            new MenuItem(currencyConfigRouteName, "Курсы валют", "dollar-sign"),
            (new MenuItem(paymentSystemRouteName, 'Платёжные системы', 'hand-holding-usd'))
                .addChildrenItem(() => {
                    return AccountStore.paymentSystemsForConfig.value.map(paymentSystem => (new MenuItem(
                        paymentSystemItemRouteName, paymentSystem.descr
                    )).addRouteParam('typeId', paymentSystem.id));
                }),
            (new MenuItem(kassaSystemRouteName, 'Кассы', 'ticket'))
                .addChildrenItem(() => {
                    return AccountStore.kassaSystemsForConfig.value.map(kassaSystem => (new MenuItem(
                        kassaSystemItemRouteName, kassaSystem.descr
                    )).addRouteParam('typeId', kassaSystem.id));
                }),
            new MenuItemDelimiter(),
        ];

        let result: RouteRecordRaw = {
            path: "config",
            name: configRouteName,
            meta: {
                access: [
                    () => AccountStore.access.value.checkAccessForDealerConfig()
                ],
                menu: configMenu
            } as IRouteMeta,
            children: configRoutes
        };

        {
            //documents
            let detailsRouteName = configRouteName + "_details";

            configRoutes.push({
                name: detailsRouteName,
                path: "details",
                component: ConfigWithFormComponent,
                props: {
                    typeId: "details"
                }
            });
            configMenu.push(new MenuItem(detailsRouteName, "Реквизиты", "file-alt"));

            const billRouteName = configRouteName + "_bill";
            const billNumberConfigRouteName = billRouteName + "_number_config";
            const billStatusConfigRouteName = billRouteName + "_status_config";
            const billTemplateConfigRouteName = billRouteName + "_template";
            const billUslugiDescrConfigRouteName = billRouteName + "_uslugi_descr";


            const actRouteName = configRouteName + "_act";
            const actNumberConfigRouteName = actRouteName + "_number_config";
            const actStatusConfigRouteName = actRouteName + "_status_config";
            const actUslugiDescrConfigRouteName = actRouteName + "_uslugi_descr";
            const actTemplateConfigRouteName = actRouteName + "_template";

            const recActRouteName = configRouteName + "_recAct";
            const recActNumberConfigRouteName = recActRouteName + "_number_config";
            const recActStatusConfigRouteName = recActRouteName + "_status_config";
            const recActTemplateConfigRouteName = recActRouteName + "_template";

            const agentActRouteName = configRouteName + "_agentAct";
            const agentActNumberConfigRouteName = agentActRouteName + "_number_config";
            const agentActStatusConfigRouteName = agentActRouteName + "_status_config";
            const agentActTemplateConfigRouteName = agentActRouteName + "_template";

            const contractsRouteName = configRouteName + "_contracts";
            const contractsConfigRouteName = contractsRouteName + "_config";
            const contractsNumberConfigRouteName = contractsRouteName + "_number_config";
            const contractsStatusConfigRouteName = contractsRouteName + "_status_config";

            const contractsTemplateConfigRouteName = contractsRouteName + "_template_config";
            const managerSignConfigRouteName = contractsRouteName + "_manager_sign";

            const additionalTemplateFormComponent = useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/document/financeTemplate/FinanceTemplateAdditionalFormComponent.vue'));

            configRoutes.push(...[
                {
                    name: billRouteName,
                    path: "bill",
                    meta: {
                        access: () => AccountStore.isNewDoc.value
                    } as IRouteMeta,
                    children: [
                        {
                            name: billNumberConfigRouteName,
                            path: "numberConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "billNumber"
                            }
                        },
                        {
                            name: billStatusConfigRouteName,
                            path: "statusConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "billStatus"
                            }
                        },
                        {
                            name: billUslugiDescrConfigRouteName,
                            path: "uslugiDescr",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "billUslugiDescr"
                            }
                        },
                        {
                            name: billTemplateConfigRouteName,
                            path: "template",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "billTemplate",
                                additionalFormComponent: additionalTemplateFormComponent,
                            }
                        }
                    ]
                },
                {
                    name: actRouteName,
                    path: "act",
                    meta: {
                        access: () => AccountStore.isNewDoc.value
                    } as IRouteMeta,
                    children: [
                        {
                            name: actNumberConfigRouteName,
                            path: "numberConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "actNumber"
                            }
                        },
                        {
                            name: actStatusConfigRouteName,
                            path: "statusConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "actStatus"
                            }
                        },
                        {
                            name: actUslugiDescrConfigRouteName,
                            path: "uslugiDescr",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "actUslugiDescr"
                            }
                        },
                        {
                            name: actTemplateConfigRouteName,
                            path: "template",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "actTemplate",
                                additionalFormComponent: additionalTemplateFormComponent
                            }
                        },
                    ]
                },
                {
                    name: recActRouteName,
                    path: "recAct",
                    meta: {
                        access: () => AccountStore.isNewDoc.value
                    } as IRouteMeta,
                    children: [
                        {
                            name: recActNumberConfigRouteName,
                            path: "numberConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "recActNumber"
                            }
                        },
                        {
                            name: recActStatusConfigRouteName,
                            path: "statusConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "recActStatus"
                            }
                        },
                        {
                            name: recActTemplateConfigRouteName,
                            path: "template",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "recActTemplate",
                                additionalFormComponent: additionalTemplateFormComponent
                            }
                        },
                    ]
                },
                {
                    name: contractsRouteName,
                    path: "contracts",
                    children: [
                        {
                            name: contractsConfigRouteName,
                            path: "config",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "contracts"
                            }
                        },
                        {
                            name: contractsNumberConfigRouteName,
                            path: "numberConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "contractsNumber"
                            }
                        },
                        {
                            name: contractsStatusConfigRouteName,
                            path: "statusConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "contractStatus"
                            }
                        },
                        {
                            name: managerSignConfigRouteName,
                            path: "manager",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "managerSign"
                            }
                        },
                        {
                            name: contractsTemplateConfigRouteName,
                            path: "template",
                            component: () => import(/* webpackChunkName: "dealer-cabinet-config" */ '~/cabinet/vue/dealer/config/document/contract/ContractTemplatesConfig.vue')
                        }
                    ]
                },
                {
                    name: agentActRouteName,
                    path: "agentAct",
                    meta: {
                        access: () => AccountStore.isNewDoc.value
                    } as IRouteMeta,
                    children: [
                        {
                            name: agentActNumberConfigRouteName,
                            path: "numberConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "agentActNumber"
                            }
                        },
                        {
                            name: agentActStatusConfigRouteName,
                            path: "statusConfig",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "agentActStatus"
                            }
                        },
                        {
                            name: agentActTemplateConfigRouteName,
                            path: "template",
                            component: ConfigWithFormComponent,
                            props: {
                                typeId: "agentActTemplate",
                                additionalFormComponent: additionalTemplateFormComponent
                            }
                        },
                    ]
                },
            ]);
            configMenu.push(
                ...[
                    (new MenuItem(billRouteName, "Счета", "file-signature"))
                        .addChildrenItem(new MenuItem(billNumberConfigRouteName, "Нумерация"))
                        .addChildrenItem(new MenuItem(billStatusConfigRouteName, "Статусы"))
                        .addChildrenItem(new MenuItem(billTemplateConfigRouteName, "Шаблоны"))
                        .addChildrenItem(new MenuItem(billUslugiDescrConfigRouteName, "Наименования услуг")),
                    (new MenuItem(actRouteName, "Акты", "file-signature"))
                        .addChildrenItem(new MenuItem(actNumberConfigRouteName, "Нумерация"))
                        .addChildrenItem(new MenuItem(actStatusConfigRouteName, "Статусы"))
                        .addChildrenItem(new MenuItem(actTemplateConfigRouteName, "Шаблоны"))
                        .addChildrenItem(new MenuItem(actUslugiDescrConfigRouteName, "Наименования услуг")),
                    (new MenuItem(recActRouteName, "Акты сверок", "file-signature"))
                        .addChildrenItem(new MenuItem(recActNumberConfigRouteName, "Нумерация"))
                        .addChildrenItem(new MenuItem(recActStatusConfigRouteName, "Статусы"))
                        .addChildrenItem(new MenuItem(recActTemplateConfigRouteName, "Шаблоны")),
                    (new MenuItem(contractsRouteName, "Договоры", "file-signature"))
                        .addChildrenItem(new MenuItem(contractsConfigRouteName, "Настройки"))
                        .addChildrenItem(new MenuItem(contractsNumberConfigRouteName, "Нумерация"))
                        .addChildrenItem(new MenuItem(contractsTemplateConfigRouteName, "Шаблоны"))
                        .addChildrenItem(new MenuItem(contractsStatusConfigRouteName, "Статусы"))
                        .addChildrenItem(new MenuItem(managerSignConfigRouteName, "Менеджеры с правом подписи")),
                    (new MenuItem(agentActRouteName, "Агентские акты", "file-signature"))
                        .addChildrenItem(new MenuItem(agentActNumberConfigRouteName, "Нумерация"))
                        .addChildrenItem(new MenuItem(agentActStatusConfigRouteName, "Статусы"))
                        .addChildrenItem(new MenuItem(agentActTemplateConfigRouteName, "Шаблоны")),
                ]
            );
            configMenu.push(new MenuItemDelimiter());

        }

        for (let usluga of AccountStore.availableUslugiList.value) {
            let name = configRouteName + "_" + usluga.id;
            let uslugaConfig = UslugaMapper.find(usluga.id);
            if (uslugaConfig) {

                let uslugaRoute: RouteRecordRaw = {
                    name: name,
                    path: usluga.id,
                    children: [],
                    meta: {
                        access: ["usluga_isAvailableForClientsAndDealers_" + usluga.id]
                    } as IRouteMeta
                };

                let commonName = DealerRoutesBuilder.getCommonUslugaSettingsRouteName(usluga.id);

                uslugaRoute.children.push({
                    path: "common",
                    name: commonName,
                    component: () => import(/* webpackChunkName: "dealer-usluga-config" */ '~/cabinet/vue/dealer/config/uslugi/common/CommonUslugaSettingsWrapper.vue'),
                    props: {
                        uslugaId: usluga.id,
                        commonUslugaSettingsComponent: uslugaConfig.dealer.config.commonUslugaSettingsComponent
                    },
                    meta: {
                        keepAlive: false
                    } as IRouteMeta
                });
                let uslugaMenu = (new MenuItem(name, usluga.descr))
                    .setIcon(usluga.icon)
                    .addChildrenItem(
                        new MenuItem(commonName, __("Основные настройки")),
                    );
                if (uslugaConfig.id == USLUGA_ID_AGENT) {
                    let paymentRouteName = commonName + "_agent_config";
                    let rewardConfigRouteName = commonName + "_agent_reward";

                    uslugaRoute.children.push(...[{
                        name: paymentRouteName,
                        path: "payment",
                        component: ConfigWithFormComponent,
                        props: {
                            typeId: `agent`
                        }
                    }, {
                        name: rewardConfigRouteName,
                        path: "reward",
                        component: ConfigWithFormComponent,
                        props: {
                            typeId: `agentReward`
                        }
                    }]);
                    uslugaMenu
                        .addChildrenItem(new MenuItem(paymentRouteName, 'Вывод вознаграждений'))
                        .addChildrenItem(new MenuItem(rewardConfigRouteName, 'Описание вывода'));
                } else if (uslugaConfig.id == USLUGA_ID_EMAIL) {
                    let dkimRouteName = `${commonName}_email_dkim`;
                    uslugaRoute.children.push(...[{
                        name: dkimRouteName,
                        path: "dkim",
                        component: () => import(/* webpackChunkName: "dealer-usluga-config" */ '~/cabinet/vue/dealer/config/uslugi/sender/email/dkim/DkimConfig.vue'),
                        meta: {
                            access: () => AccountStore.isSuperDealer.value
                        } as IRouteMeta
                    }]);
                    uslugaMenu
                        .addChildrenItem(new MenuItem(dkimRouteName, "DKIM"));
                }

                if (uslugaConfig.dealer.config.tarifComponent) {
                    let tarifsName = DealerRoutesBuilder.getTarifsRouteName(usluga.id)


                    let tarifSingleName = name + "_tarif_single";


                    let tarifsScaleName = name + "_tarifs_scale";
                    DealerRoutesBuilder.routeNamesForSingleTarif[usluga.id] = tarifSingleName;

                    uslugaRoute.children.push(...[
                        {
                            path: "tarif",
                            name: tarifsName,
                            component: this.tarifListWrapper,
                            props: route => ({
                                tarifId: route.params[TARIF_ROUTE_TARIF_ID_PARAM_NAME],
                                uslugaId: usluga.id,
                                tarifListComponent: uslugaConfig.dealer.config.tarifComponent
                            }),
                            children: [
                                {
                                    path: ":" + TARIF_ROUTE_TARIF_ID_PARAM_NAME,
                                    name: tarifSingleName,
                                }
                            ],
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForDealerTarifConfig()
                                ]
                            } as IRouteMeta
                        } as RouteRecordRaw,
                        {
                            path: "tarifScale",
                            name: tarifsScaleName,
                            component: () => import(/* webpackChunkName: "dealer-tarif" */ '~/cabinet/vue/dealer/config/uslugi/tarif/TarifScale.vue'),
                            props: {
                                uslugaId: usluga.id
                            },
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForDealerTarifConfig()
                                ]
                            } as IRouteMeta
                        } as RouteRecordRaw
                    ]);
                    uslugaMenu
                        .addChildrenItem(
                            (new MenuItem(tarifsName, __("Тарифы")))
                                .setBadge(new MenuBadge(`dealerTarifs_${usluga.id}`))
                        )
                        .addChildrenItem(new MenuItem(tarifsScaleName, __("Тарифные сетки"))
                        );
                }

                if (usluga.id == USLUGA_ID_PAGER) {
                    this.addPagerConfigRoutes(uslugaRoute, uslugaMenu);
                } else if (usluga.id == USLUGA_ID_MESSENGER) {
                    this.addMessengerConfigRoutes(uslugaRoute, uslugaMenu);
                }

                if (usluga.sender) {
                    let moderateTemplatesRouteName = name + "_moderateTemplate";

                    uslugaRoute.children.push({
                        name: moderateTemplatesRouteName,
                        path: "moderationTemplates",
                        component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/moderation/ModerationTemplates.vue'),
                        props: {
                            uslugaId: usluga.id,
                            forDealerClients: true
                        },
                        meta: {
                            access: ["usluga_isAvailableMessageModerateTemplate_" + usluga.id]
                        } as IRouteMeta
                    });

                    uslugaMenu.addChildrenItem(
                        new MenuItem(moderateTemplatesRouteName, "Шаблоны модерации")
                    );

                    if (usluga.sender.isDestUsed) {
                        let schemeName = name + "_dest_scheme";
                        let destName = name + "_dest";
                        let blackListName = name + "_black_list";

                        uslugaRoute.children.push(...[
                            {
                                path: "scheme",
                                name: schemeName,
                                component: () => import(/* webpackChunkName: "dealer-sender-routing" */ '~/cabinet/vue/dealer/config/uslugi/sender/RoutingScheme.vue'),
                                props: {
                                    uslugaId: usluga.id
                                },
                                meta: {
                                    access: () => AccountStore.access.value.checkDealerConfigOwn(usluga.id) && AccountStore.access.value.isAdmin
                                }
                            },
                            {
                                path: "dest",
                                name: destName,
                                component: () => import(/* webpackChunkName: "dealer-sender-routing" */ '~/cabinet/vue/dealer/config/uslugi/sender/RoutingDests.vue'),
                                props: {
                                    uslugaId: usluga.id
                                },
                                meta: {
                                    access: () => AccountStore.access.value.checkDealerConfigOwn(usluga.id) && AccountStore.access.value.isAdmin
                                }
                            },
                            {
                                path: "blackList",
                                name: blackListName,
                                component: () => import(/* webpackChunkName: "dealer-sender" */ '~/cabinet/vue/dealer/config/uslugi/sender/BlackList.vue'),
                                props: {
                                    uslugaId: usluga.id
                                },
                                meta: {
                                    access: [() => AccountStore.access.value.isAdmin]
                                }
                            }
                        ]);
                        uslugaMenu
                            .addChildrenItem(new MenuItem(schemeName, "Роутинг"))
                            .addChildrenItem(new MenuItem(destName, "Провайдеры"))
                            .addChildrenItem(new MenuItem(blackListName, "Глобальный стоп-лист"));
                    }
                } else if (usluga.id == USLUGA_ID_CALL_PASSWORD) {
                    const destsRouteName = "callPasswordDestsConfig";
                    const routingRouteName = "callPasswordRoutingConfig";

                    uslugaRoute.children.push(...[{
                        name: destsRouteName,
                        path: "dests",
                        component: () => import(/* webpackChunkName: "dealer-sender-routing" */ '~/cabinet/vue/dealer/config/uslugi/callpassword/CallPasswordDests.vue'),
                        meta: {
                            access: () => AccountStore.hasAccess(ACCESS_CALL_PASSWORD_CONFIG)
                        } as IRouteMeta
                    }, {
                        name: routingRouteName,
                        path: "routing",
                        component: () => import(/* webpackChunkName: "dealer-sender-routing" */ '~/cabinet/vue/dealer/config/uslugi/callpassword/CallPasswordRoutingScheme.vue'),
                        meta: {
                            access: () => AccountStore.hasAccess(ACCESS_CALL_PASSWORD_CONFIG)
                        } as IRouteMeta
                    }]);

                    uslugaMenu
                        .addChildrenItem(new MenuItem(routingRouteName, "Роутинг"))
                        .addChildrenItem(new MenuItem(destsRouteName, "Провайдеры"));
                }
                if (usluga.id == USLUGA_ID_CALL_GATE) {
                    const destsRouteName = `${usluga.id}DestsConfig`;
                    const routingRouteName = `${usluga.id}RoutingConfig`;
                    let access = () => usluga.id == USLUGA_ID_CALL_GATE ? AccountStore.hasAccess(ACCESS_CALL_PASSWORD_CONFIG) : AccountStore.isSuperDealer.value;
                    uslugaRoute.children.push(...[{
                        name: destsRouteName,
                        path: "dests",
                        component: () => import(/* webpackChunkName: "dealer-sender-routing" */ '~/cabinet/vue/dealer/config/uslugi/provider/ProviderDests.vue'),
                        props: {
                            uslugaId: usluga.id
                        },
                        meta: {
                            access
                        } as IRouteMeta
                    }, {
                        name: routingRouteName,
                        path: "routing",
                        component: () => import(/* webpackChunkName: "dealer-sender-routing" */ '~/cabinet/vue/dealer/config/uslugi/provider/ProviderRoutingScheme.vue'),
                        props: {
                            uslugaId: usluga.id
                        },
                        meta: {
                            access
                        } as IRouteMeta
                    }]);

                    uslugaMenu
                        .addChildrenItem(new MenuItem(routingRouteName, "Роутинг"))
                        .addChildrenItem(new MenuItem(destsRouteName, "Провайдеры"));
                }

                (result.meta as IRouteMeta).menu.push(uslugaMenu);

                result.children.push(uslugaRoute);
            }
        }


        Section.createForRoute(result, __("Настройки"), "cog");
        return result;
    }

    private static mobileCountryConfigLocationName = "superDealerCountryListConfig";
    private static mobileOperatorConfigLocationName = "superDealerMobileOperatorConfig";
    private static mobileNetConfigLocationName = "superDealerMobileNetConfig";


    public static getMobileCountryConfigLocation(): RouteLocationNamedRaw {
        return {name: this.mobileCountryConfigLocationName}
    }

    public static getMobileOpsosConfigLocation(countryId: string): RouteLocationNamedRaw {
        return {
            name: this.mobileOperatorConfigLocationName,
            params: {
                countryId
            }
        };
    }

    public static getMobileNetConfigLocation(opsosId: string): RouteLocationNamedRaw {
        return {
            name: this.mobileNetConfigLocationName,
            params: {
                opsosId
            }
        };
    }

    private addPagerConfigRoutes(uslugaRoute: RouteRecordRaw, menu: MenuItem) {
        let routeName = uslugaRoute.name as string;
        let sourceName = `${routeName}_tarifs_source`;
        let countryList = DealerRoutesBuilder.mobileCountryConfigLocationName;
        let opsosList = DealerRoutesBuilder.mobileOperatorConfigLocationName;
        let net = DealerRoutesBuilder.mobileNetConfigLocationName;
        let channelsRouteName = `${routeName}_channels`;

        let serviceTarifsName = `${routeName}_tarifs_service`;



        uslugaRoute.children.push(...[
            {
                path: "tarifSource",
                name: sourceName,
                component: this.tarifListWrapper,
                props: {
                    uslugaId: USLUGA_ID_PAGER,
                    hideCreate: true,
                    tarifListComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-pager" */ '~/cabinet/vue/dealer/config/uslugi/tarif/pager/PagerSourceTarifList.vue')),
                }
            },
            {
                path: "serviceTarif",
                name: serviceTarifsName,
                component: this.tarifListWrapper,
                props: {
                    uslugaId: USLUGA_ID_PAGER,
                    hideCreate: true,
                    tarifListComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-pager" */ '~/cabinet/vue/dealer/config/uslugi/tarif/pager/ServiceTarifList.vue')),
                },
                meta: {
                    access: [
                        () => AccountStore.isSuperDealer.value
                    ]
                } as IRouteMeta
            },
            {
                path: "operators",
                name: countryList,
                component: () => import(/* webpackChunkName: "dealer-mobile" */ '~/cabinet/vue/dealer/config/uslugi/sender/operators/CountryListConfig.vue'),
                children: [
                    {
                        name: opsosList,
                        path: ":countryId",
                        component: () => import(/* webpackChunkName: "dealer-mobile" */ '~/cabinet/vue/dealer/config/uslugi/sender/operators/OpsosListConfig.vue'),
                        props: route => route.params,
                        children: [
                            {
                                name: net,
                                path: ":opsosId",
                                component: () => import(/* webpackChunkName: "dealer-mobile" */ '~/cabinet/vue/dealer/config/uslugi/sender/operators/NetListConfig.vue'),
                                props: route => route.params,
                            }
                        ]
                    } as RouteRecordRaw
                ],
                meta: {
                    access: () => AccountStore.isSuperDealer.value
                } as IRouteMeta
            },
            {
                path: "channels",
                name: channelsRouteName,
                component: () => import(/* webpackChunkName: "dealer-pager" */ '~/cabinet/vue/dealer/config/uslugi/sender/pager/dealerChannel/DealerChannels.vue'),
                meta: {
                    keepAlive: false,
                    access: () => AccountStore.access.value.checkDealerConfigOwn(USLUGA_ID_PAGER)
                } as IRouteMeta
            }
        ]);
        menu
            .addChildrenItem(new MenuItem(sourceName, "Имена отправителя"))
            .addChildrenItem(new MenuItem(serviceTarifsName, "Сервисные сборы"))
            .addChildrenItem(new MenuItem(countryList, "Операторы"))
            .addChildrenItem(new MenuItem(channelsRouteName, "Каналы"));
    }

    private addMessengerConfigRoutes(uslugaRoute: RouteRecordRaw, menu: MenuItem) {
        let notificationsName = "dealerConfigMessengerNotifications";
        uslugaRoute.children.push({
            path: "notifications",
            name: notificationsName,
            component: () => import(/* webpackChunkName: "dealer-messenger" */ '~/cabinet/vue/dealer/config/uslugi/sender/messenger/NotificationsConfig.vue'),
        });
        menu.addChildrenItem(new MenuItem(notificationsName, "Уведомления"));
    }
}