import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import {computed, ref} from "vue";
import {ContactbookGroupActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {IFieldWithOptions} from "~/cabinet/vue/interface/form/elements/Interfaces";
import EditGroupDialog from "~/cabinet/vue/client/phonebook/groups/EditGroupDialog.vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {EditGroupDialogSaveEventInterface} from "~/cabinet/vue/client/phonebook/groups/Interface";


const __default__ = {
    name: "ContactbookGroupBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<ContactbookGroupActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let _modelValue = _useModel<getActionDescriptionModelType<ContactbookGroupActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let editGroupDialogVisible = ref(false);

let hasAccess = computed(() => {
    return AccountStore.access.value.checkAccessForContactbookAddGroup();
});

function addGroup(p: EditGroupDialogSaveEventInterface) {
    for (let section of props.actionDescription.form.sections) {
        let element: IFieldWithOptions = section.elements.find(element => element.name == "groupIds") as any;
        if (element && element.options) {
            element.options.push({
                descr: p.descr,
                value: p.id
            });
            if (!model.value.formValues) {
                model.value.formValues = [];
            }
            if (!model.value.formValues.groupIds) {
                model.value.formValues.groupIds = [];
            }
            if (Array.isArray(model.value.formValues.groupIds)) {
                model.value.formValues.groupIds.push(p.id);
            }
        }
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_unref(hasAccess))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(editGroupDialogVisible) //@ts-ignore
 ? editGroupDialogVisible.value = true : editGroupDialogVisible = true))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Добавить группу")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(EditGroupDialog, {
          visible: _unref(editGroupDialogVisible),
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(editGroupDialogVisible) ? (editGroupDialogVisible).value = $event : editGroupDialogVisible = $event)),
          "data-source": _unref(AccountStore).contactbookDataSourceList.value.find(item => item.isCabinetContactbook),
          onSave: addGroup
        }, null, 8, ["visible", "data-source"])
      ]))
    : _createCommentVNode("", true)
}
}

})