import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "d-flex mb-1 input-list-item align-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "pr-2 sortable-handler"
}
const _hoisted_3 = { class: "flex-fill min-width-0" }
const _hoisted_4 = { key: "addItem" }

import {computed, ModelRef, watch} from 'vue';
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";

interface SortableItemInterface {
    index: number;
    value: any;
}

import Draggable from "~/node_modules/vuedraggable/src/vuedraggable.js";

const __default__ = {
    name: "MyInputList",
    components: {
        Draggable
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    removeLastAvailable: { type: Boolean, default: true },
    newItemValueGetter: { type: Function, default: () => null },
    sortable: { type: Boolean },
    autoAppendNewInput: { type: Boolean },
    maxItems: {},
    addButtonText: { default: "Добавить" },
    showSortableHandler: { type: Boolean, default: true },
    primaryPlainButton: { type: Boolean },
    sortableHandler: {}
  }, {
    "modelValue": {
    required: true
},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model: ModelRef<any[]> = _useModel<any[]>(__props, "modelValue");

let sortableModel = computed<SortableItemInterface[]>({
    get: () => {
        return model.value.map((item, index) => {
            return {
                value: item,
                index
            }
        });
    },
    set: (newModelValue: SortableItemInterface[]) => {
        model.value.splice(0, model.value.length, ...newModelValue.map(item => item.value));
    }
});

function r(row: any): SortableItemInterface {
    return row;
}

function deleteItem(index: number) {
    let value = [...model.value];
    value.splice(index, 1);
    model.value = value;
}

let lastValue = computed(() => {
    return model.value[model.value.length - 1];
});

watch(lastValue, () => {
    if (props.autoAppendNewInput) {
        if (lastValue.value != null) {
            addItem();
        }
    }
});

let isAddAvailable = computed(() => {
    return !props.maxItems || model.value.length < props.maxItems;
})

function addItem(after?: number) {
    if (isAddAvailable) {
        let newItem = props.newItemValueGetter();
        if (typeof after == "number") {
            model.value.splice(after + 1, 0, newItem)
        } else {
            model.value.push(newItem);
        }
    }
}

if (!props.removeLastAvailable && !model.value.length) {
    model.value = [props.newItemValueGetter()];
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Draggable), {
      disabled: !_ctx.sortable,
      modelValue: _unref(sortableModel),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(sortableModel) ? (sortableModel).value = $event : sortableModel = $event)),
      handle: _unref(props).sortableHandler ? _ctx.sortableHandler : (_ctx.showSortableHandler ? '.sortable-handler' : undefined),
      name: "list",
      "item-key": "index"
    }, {
      item: _withCtx(({element}) => [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "before", _normalizeProps(_guardReactiveProps({index: r(element).index}))),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.sortable && _unref(model).length > 1 && _ctx.showSortableHandler)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(FontAwesomeIcon, {
                    icon: "grip-vertical",
                    class: "cursor-move"
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({index: r(element).index})))
            ]),
            (_unref(isAddAvailable) && _ctx.sortable)
              ? (_openBlock(), _createBlock(MyIconButton, {
                  key: 1,
                  add: "",
                  class: "ml-2",
                  onClick: ($event: any) => (addItem(r(element).index))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.removeLastAvailable || _unref(model).length > 1)
              ? (_openBlock(), _createBlock(MyIconButton, {
                  key: 2,
                  delete: "",
                  class: "ml-2",
                  onClick: ($event: any) => (deleteItem(r(element).index))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 3
    }, 8, ["disabled", "modelValue", "handle"]),
    (_unref(isAddAvailable))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_el_button, {
            type: _ctx.primaryPlainButton ? 'primary' : undefined,
            link: !_ctx.primaryPlainButton,
            plain: _ctx.primaryPlainButton,
            onClick: addItem
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.addButtonText), 1)
            ]),
            _: 1
          }, 8, ["type", "link", "plain"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})