import {
    FIELD_CABINET_CAPTCHA,
    FIELD_FILEMANAGER,
    FIELD_IMAGE,
    FIELD_SWITCH,
    FIELD_TYPE_CHAT_BOT,
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_COLOR_PICKER,
    FIELD_TYPE_DATE,
    FIELD_TYPE_DATETIME,
    FIELD_TYPE_EMAIL,
    FIELD_TYPE_FILE_SHARE,
    FIELD_TYPE_HIDDEN,
    FIELD_TYPE_INFO,
    FIELD_TYPE_INTEGER,
    FIELD_TYPE_LABEL,
    FIELD_TYPE_MOBILE,
    FIELD_TYPE_NUMBER,
    FIELD_TYPE_RADIO,
    FIELD_TYPE_SELECT,
    FIELD_TYPE_SEX,
    FIELD_TYPE_SLIDER,
    FIELD_TYPE_STARS,
    FIELD_TYPE_TEXT,
    FIELD_TYPE_TEXTAREA,
    FIELD_TYPE_TIME
} from "~/cabinet/vue/interface/form/elements/Interfaces";
import TextElement from "~/cabinet/vue/interface/form/elements/TextElement.vue";
import SelectElement from "~/cabinet/vue/interface/form/elements/SelectElement.vue";
import InfoElement from "~/cabinet/vue/interface/form/elements/InfoElement.vue";
import SliderElement from "~/cabinet/vue/interface/form/elements/SliderElement.vue";
import ColorPickerElement from "~/cabinet/vue/interface/form/elements/ColorPickerElement.vue";
import FilemanagerElement from "~/cabinet/vue/interface/form/elements/FilemanagerElement.vue";
import SwitchElement from "~/cabinet/vue/interface/form/elements/SwitchElement.vue";
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";
import ElementWithOptionsConstructor
    from "~/cabinet/vue/interface/form/elements/constructor/ElementWithOptionsConstructor.vue";
import InfoElementConstructor from "~/cabinet/vue/interface/form/elements/constructor/InfoElementConstructor.vue";
import TextElementConstructor from "~/cabinet/vue/interface/form/elements/constructor/TextElementConstructor.vue";
import TextareaElementConstructor
    from "~/cabinet/vue/interface/form/elements/constructor/TextareaElementConstructor.vue";
import HiddenElement from "~/cabinet/vue/interface/form/elements/HiddenElement.vue";
import ImageElement from "~/cabinet/vue/interface/form/elements/ImageElement.vue";
import DateElement from "~/cabinet/vue/interface/form/elements/DateElement.vue";
import FileShareElement from "~/cabinet/vue/interface/form/elements/FileShareElement.vue";
import {Component} from "vue";
import CabinetCaptchaElement from "~/cabinet/vue/interface/form/elements/CabinetCaptchaElement.vue";
import FileShareElementConstructor
    from "~/cabinet/vue/interface/form/elements/constructor/FileShareElementConstructor.vue";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";
import SwitchElementConstructor from "~/cabinet/vue/interface/form/elements/constructor/SwitchElementConstructor.vue";

export interface IFormElementType {
    id: string,
    descr: string,
    displayComponent: Component;
    displayComponentProps?: Dictionary<any>;
    constructorComponent?: Component,
    constructorComponentProps?: Dictionary<any>;
    withOptions?: boolean,
    isHidden?: boolean;
}

export const FORM_ELEMENT_TYPE_LIST: IFormElementType[] = [
    {
        id: FIELD_TYPE_TEXT,
        descr: "Текст",
        displayComponent: TextElement,
        constructorComponent: TextElementConstructor,
        constructorComponentProps: {
            withDimension: true
        },
        displayComponentProps: {
            withDimension: true
        }
    },
    {
        id: FIELD_TYPE_TEXTAREA,
        descr: "Многострочный текст",
        displayComponent: TextElement,
        displayComponentProps: {type: "textarea"},
        constructorComponent: TextareaElementConstructor
    },
    {
        id: FIELD_TYPE_SELECT,
        descr: "Выпадающий список",
        displayComponent: SelectElement,
        constructorComponent: ElementWithOptionsConstructor
    },
    {
        id: FIELD_TYPE_CHECKBOX,
        descr: "Галочки",
        displayComponent: SelectElement,
        displayComponentProps: {multiple: true},
        constructorComponent: ElementWithOptionsConstructor,
        constructorComponentProps: {multiple: true}
    },
    {
        id: FIELD_TYPE_RADIO,
        descr: "Radio buttons",
        displayComponent: SelectElement,
        constructorComponent: ElementWithOptionsConstructor
    },
    {
        id: FIELD_TYPE_INFO,
        descr: "Текстовая информация",
        displayComponent: InfoElement,
        constructorComponent: InfoElementConstructor
    },
    {
        id: FIELD_TYPE_SLIDER,
        descr: "Слайдер",
        displayComponent: SliderElement
    },
    {
        id: FIELD_TYPE_COLOR_PICKER,
        descr: "Выбор цвета",
        displayComponent: ColorPickerElement
    },
    {
        id: FIELD_FILEMANAGER,
        descr: "Загрузка изображения",
        displayComponent: FilemanagerElement
    },
    {
        id: FIELD_SWITCH,
        descr: "Переключатель",
        displayComponent: SwitchElement,
        constructorComponent: SwitchElementConstructor
    },
    {
        id: FIELD_IMAGE,
        descr: "Изображение",
        displayComponent: ImageElement
    },
    {
        id: FIELD_TYPE_DATE,
        descr: "Дата",
        displayComponent: DateElement
    },
    {
        id: FIELD_TYPE_DATETIME,
        descr: "Дата/время",
        displayComponent: DateElement
    },
    {
        id: FIELD_TYPE_TIME,
        descr: "Время",
        displayComponent: DateElement
    },
    {
        id: FIELD_TYPE_SEX,
        descr: "Пол",
        displayComponent: SelectElement
    },
    {
        id: FIELD_TYPE_FILE_SHARE,
        descr: "Файл",
        displayComponent: FileShareElement,
        displayComponentProps: {
            useUrlInValue: typeof OPERATOR_APPLICATION_VERSION === "string"
        },
        constructorComponent: FileShareElementConstructor
    },
    {
        id: FIELD_TYPE_HIDDEN,
        descr: "Скрытое поле",
        displayComponent: HiddenElement,
        isHidden: true
    },
    {
        id: FIELD_TYPE_LABEL,
        descr: "Надпись",
        displayComponent: HiddenElement
    },
    {
        id: FIELD_TYPE_MOBILE,
        descr: "Мобильный телефон",
        displayComponent: TextElement,
        constructorComponent: TextElementConstructor
    },
    {
        id: FIELD_TYPE_EMAIL,
        descr: "Email",
        displayComponent: TextElement,
        constructorComponent: TextElementConstructor
    },
    {
        id: FIELD_TYPE_INTEGER,
        descr: "Целое число",
        displayComponent: TextElement,
        constructorComponent: TextElementConstructor,
        constructorComponentProps: {
            withDimension: true
        },
        displayComponentProps: {
            withDimension: true
        }
    },
    {
        id: FIELD_TYPE_NUMBER,
        descr: "Дробное число",
        displayComponent: TextElement,
        constructorComponent: TextElementConstructor,
        constructorComponentProps: {
            withDimension: true
        },
        displayComponentProps: {
            withDimension: true
        }
    },
    {
        id: FIELD_TYPE_STARS,
        descr: "Оценка",
        displayComponent: SelectElement
    },
    ...(IS_CABINET ? [
        {
            id: FIELD_TYPE_CHAT_BOT,
            descr: "Чат-бот",
            displayComponent: useRawAsyncComponent(() => import('~/cabinet/vue/interface/form/elements/ChatBotElement.vue'))
        },
        {
            id: FIELD_CABINET_CAPTCHA,
            descr: "Капча личного кабинета",
            displayComponent: CabinetCaptchaElement,
        },
    ] : [])
];


export const FORM_ELEMENT_TYPE_DICTIONARY = ObjectHelper.dictionaryFromArray(FORM_ELEMENT_TYPE_LIST, "id");
