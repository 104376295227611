import {
    AbstractDataProviderActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/AbstractDataProviderActionParamInterface";
import Dictionary from "~/ts/library/Dictionary";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {ComputedRef, InjectionKey} from "vue";

export interface DataProviderEntitySaveActionParamInterface extends AbstractDataProviderActionParamInterface {
    fields: DataProviderEntityFieldModelInterface[];
    updateFilters: any[];
    additionalConfigFormValues: any;
    links: DataProviderEntitySaveActionLinkInterface[];
    isAlwaysCreate: boolean;
    searchModes: string[];
    foundActionMode: string
    notFoundActionMode: string;
}

export interface DataProviderEntitySaveActionLinkInterface {
    id: string,
    entityTypeId: string,
    value: DataProviderEntitySaveActionParamInterface,
    additionalConfigFormValues: any
}

export interface DataProviderEntityFieldModelInterface {
    id: string;
    values: Dictionary<any>[];
    updateAllowed: boolean;
    updateOnlyEmpty: boolean;
    operatorCanEditField: boolean;
    hideFromOperator: boolean;
}

export interface DataProviderEntityFieldInterface {
    id: string;
    descr: string;
    groupDescr: string;
    form: IForm;
    isRequired: boolean;
    isImmutable: boolean;
    isFilterable: boolean;
    isMultiple: boolean;
    isUpdateAllowedByDefault: boolean;
    maxLength?: number;
    isDiscriminator: boolean;
    parentDiscriminator: string[];
}

export const DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_INJECTION_KEY = Symbol() as InjectionKey<boolean>;
export const DATA_PROVIDER_ENTITY_SAVE_MANUAL_FORM_ENTITY_ID_INJECTION_KEY = Symbol() as InjectionKey<ComputedRef<string | number | null>>;