import ReactiveObject, {IReactiveObject} from "~/ts/library/ReactiveObject";
//import animatedScrollTo from 'animated-scroll-to';
import EventManager from "~/ts/library/EventManager";
import {Timeouts} from "~/ts/library/delay/Timeouts";
import Events from "~/ts/library/Events";
import Delay from "~/ts/library/Delay";

let eventManager = new EventManager();

export interface IVisibleViewport {
    scale: number;
    bodyZoom: number;
    offsetLeft: number;
    offsetTop: number;
    pageLeft: number;
    pageTop: number;
    height: number;
    width: number;
}

export interface IWindowSize extends IReactiveObject {
    eventRunNow: boolean;
    width: number;
    height: number;
    viewport: IVisibleViewport
}

const EVENT_RESIZE = "resize";

let data = ReactiveObject.make({
    width: 0,
    height: 0,
    eventRunNow: false,
    viewport: {
        bodyZoom: 1,
        scale: 1,
        offsetLeft: 0,
        offsetTop: 0,
        pageLeft: 0,
        pageTop: 0,
        height: 0,
        width: 0
    }
}) as IWindowSize;

let timeout: number;

function getZoomByElement(element: HTMLElement): number {
    let computedStyle: any = window.getComputedStyle(element);
    if (computedStyle.zoom) {
        return parseFloat(computedStyle.zoom);
    } else {
        return 1;
    }
}

function getWindowSize() {
    let myWidth = 0, myHeight = 0;
    if (typeof (window.innerWidth) === 'number') {
        //Non-IE
        myWidth = window.innerWidth;
        myHeight = window.innerHeight;
    } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        //IE 6+ in 'standards compliant mode'
        myWidth = document.documentElement.clientWidth;
        myHeight = document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        //IE 4 compatible
        myWidth = document.body.clientWidth;
        myHeight = document.body.clientHeight;
    }

    data.height = myHeight;
    data.width = myWidth;

    let viewport = (window as any).visualViewport;
    if (viewport) {
        let windowSizeMismatch = 1;
        if (window.outerWidth > window.innerWidth) {
            if (viewport.scale > 1) {
                windowSizeMismatch = window.outerWidth / window.innerWidth;
            }
        }
        data.viewport.scale = viewport.scale / windowSizeMismatch;
        data.viewport.offsetLeft = viewport.offsetLeft;
        data.viewport.offsetTop = viewport.offsetTop;
        data.viewport.pageLeft = viewport.pageLeft;
        data.viewport.pageTop = viewport.pageTop;
        data.viewport.height = viewport.height;
        data.viewport.width = viewport.width;
        if (document.body) {
            let zoom = getZoomByElement(document.body);
            if (["www.notik.ru", "notik.ru"].includes(window.location.hostname)) {
                //Это сломало сайты другим клиентам... Пока хз что с этим делать
                if (zoom == 1) {
                    zoom = getZoomByElement(document.body.parentElement);
                }
            }
            data.viewport.bodyZoom = zoom ?? 1;
        } else {
            data.viewport.bodyZoom = 1;
        }
    }

    data.eventRunNow = false;

    eventManager.emit(EVENT_RESIZE, data);

}

getWindowSize();

let windowEventHandler = () => {
    data.eventRunNow = true;
    Timeouts.clear(timeout);
    timeout = Timeouts.set(getWindowSize, 100);
}

Events.addEventListener("resize", window, windowEventHandler);

let visualViewport = (window as any).visualViewport;
if (visualViewport) {
    Events.addEventListener('scroll', visualViewport, windowEventHandler);
    Events.addEventListener('resize', visualViewport, windowEventHandler);
}

export default class WindowHelper {
    public static getSize(): IWindowSize {
        return data;
    }

    public static getVisibleViewport(): IVisibleViewport {
        return data.viewport;
    }

    public static get isWindowOrViewportEventRun() {
        return data.eventRunNow;
    }

    public static get scaleZoomFix() {
        return 1 / data.viewport.scale;
    }

    public static get scaleLeftFix() {
        let viewport = data.viewport;
        return `${viewport.offsetLeft * viewport.scale / viewport.bodyZoom}px`;
    }

    public static get scaleRightFix() {
        let viewport = data.viewport;
        return `${(-window.innerWidth + viewport.width + viewport.offsetLeft) * viewport.scale / viewport.bodyZoom}px`;
    }

    public static get scaleBottomFix() {
        let viewport = data.viewport;
        return `${(-window.innerHeight + viewport.height + viewport.offsetTop) * viewport.scale / viewport.bodyZoom}px`;
    }

    public static get scaleTopFix() {
        let viewport = data.viewport;
        return `${viewport.offsetTop * viewport.scale / viewport.bodyZoom}px`;
    }

    public static onResize(callback: (data: IWindowSize) => void) {
        return eventManager.addEventListener(EVENT_RESIZE, callback);
    }

    public static scrollToTop(element?: HTMLElement, duration?: number) {
        return WindowHelper.scrollTo(0, element, duration);
    }

    public static scrollToBottom(element?: HTMLElement | Element, duration?: number) {
        let y = element ? element.scrollHeight : window.outerHeight;
        if (!y) {
            y = 1000000000;
        }
        return WindowHelper.scrollTo(y, element, duration);
    }

    public static scrollToElement(element: HTMLElement, container: HTMLElement, duration?: number, offsetTop: number = 0) {
        //offsetTop += element.offsetTop;
        let parents = [];
        let $el = element;
        while ($el) {
            parents.push($el);
            if ($el.parentElement != container) {
                $el = $el.parentElement;
            } else {
                break;
            }
        }
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent as HTMLElement;
            if (parents.indexOf(element) == -1) {
                break;
            }
        }

        this.scrollTo(offsetTop > 0 ? offsetTop : 0, container, duration);
    }

    public static async scrollToPosition(x: number, y: number, element?: HTMLElement, duration?: number) {
        this.scrollTo(x, element, duration, true);
        await this.scrollTo(y, element, duration);
    }


    public static async scrollTo(offset: number, element?: HTMLElement | Element, duration?: number, horizontal: boolean = false) {
        let scrollY = element ? element.scrollTop : window.scrollY;
        if (duration === null) {
            duration = scrollY / 2;
            duration = duration > 500 ? 500 : duration;
        }

        let module = await (() => import(/* webpackChunkName: "widget" */ 'animated-scroll-to'))() as any;

        let animatedScrollTo = module.default;
        if (typeof animatedScrollTo == "function") {
            animatedScrollTo(offset, {
                maxDuration: duration,
                minDuration: duration,
                element: element ? element : window,
                horizontal
            });
        }
        await Delay.make(duration * .7);
    }

    public static scrollIfNeeded(y: number) {
        var scrollTop = window.scrollY;
        let size = this.getSize();
        let height = size.height;
        if ((y > scrollTop + height) || y < scrollTop) {
            scrollTop = y - (height / 2);
            if (scrollTop < 0) {
                scrollTop = 0;
            }
            this.scrollTo(scrollTop, null, 200);
        }
    }
}
