import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import {PropType} from "vue";

export function useAdditionalButtonComponentProps<RequestData>() {
    return {
        request: {
            type: Object as PropType<ReactiveRequest<RequestData>>
        }
    };
}

export function useAdditionalFormComponentProps<FrontendParams = any>() {
    return {
        typeId: {
            type: String
        },
        preview: {
            type: Boolean
        },
        frontendParams: {
            type: Object as PropType<FrontendParams>
        }
    }
}

export const ConfigWithFormComponent = () => import(/* webpackChunkName: "client-config" */ '~/cabinet/vue/client/config/ConfigWithForm.vue');
